export default {
  CATEGORIES: {
    CURATION: "InternalCuration",
    SHOWREEL: "Showreel",
    SELECT_VIDEOS: "SelectVideos",
    TASK_VIEW: "TaskView",
    UPLOADER: "Uploader",
  },
  EVENTS: {
    ACTION_UPDATED: "ActionUpdated",
    ADD_VIDEO_CANCELLED: "AddVideoCancelled",
    ADD_VIDEO_ERROR: "AddVideoError",
    ADD_VIDEO_SUCCESS: "AddVideoSuccess",
    ASSIGN_CREATOR_ALREADY_ASSIGNED: "AssignCreatorAlreadyAssigned",
    ASSIGN_CREATOR_CANCEL: "AssignCreatorCancel",
    ASSIGN_CREATOR_ERROR: "AssignCreatorError",
    ASSIGN_CREATOR_NO_ACTIVE_CREATORS: "AssignCreatorNoActiveCreators",
    ASSIGN_CREATOR_NOT_INTERNAL: "AssignCreatorNotInternal",
    ASSIGN_CREATOR_SUCCESS: "AssignCreatorSuccess",
    BATCH_DOWNLOAD_MAX_VIDEOS_REACHED: "BatchDownloadMaxVideosReached",
    BATCH_DOWNLOAD_STARTED: "BatchDownloadStarted",
    CONCEPT_UPDATED: "ConceptUpdated",
    CONTENT_GROUP_UPDATED: "ContentGroupUpdated",
    GENERATE_QUALITY_REPORT: "GenerateQualityReport",
    NAVIGATED_TO_VARIANT_CONTROLS_TAB: "NavigatedToVariantControlsTab",
    NOTES_SAVED: "NotesSaved",
    RATING_UPDATED: "RatingUpdated",
    STATUS_UPDATED: "StatusUpdated",
    UPLOAD_VIDEO_DELETED: "UploadVideoDeleted",
    UPLOAD_VIDEO_ERROR: "UploadVideoError",
    UPLOAD_VIDEO_SUCCESS: "UploadVideoSuccess",
    VARIANT_VERSION_PAUSED: "VariantVersionPaused",
    VARIANT_VERSION_PLAYED: "VariantVersionPlayed",
    VARIANT_VISIBILITY_HIDE_ALL_ERROR: "VariantVisibilityHideAllError",
    VARIANT_VISIBILITY_UPDATE_ERROR: "VariantVisibilityUpdateError",
    VARIANT_VISIBILITY_UPDATE_SUCCESS: "VariantVisibilityUpdateSuccess",
    VIEW_OPTIONS: {
      FILTER: "InternalCurationOptionsFilterApplied",
      SORT: "InternalCurationOptionsSortChanged",
    },
  },
};
