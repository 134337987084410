import briefStatus from "./briefStatus.yml";
import briefSubNav from "./briefSubNav.yml";
import briefTypes from "./briefTypes.yml";
import date from "./date.yml";
import downloads from "./downloads.yml";
import error from "./error.yml";
import general from "./general.yml";
import ratings from "./ratings.yml";
import showreelNameTypes from "./showreelNameTypes.yml";
import showreelTypes from "./showreelTypes.yml";
import upload from "./upload.yml";
import videos from "./videos.yml";

export const copy = {
  briefStatus,
  briefSubNav,
  briefTypes,
  date,
  downloads,
  error,
  general,
  ratings,
  showreelNameTypes,
  showreelTypes,
  upload,
  videos,
};

export default copy;
