import ACTIONS from "../actions";
import CONCEPTS from "../concepts";
import STATUSES from "../statuses";

export default {
  ALL: "all",
  ...ACTIONS,
  ...CONCEPTS,
  ...STATUSES,
};
