import {
  InfeedContainerLight,
  Scrollable,
} from "shared/components/MobilePreviewPartners/shared";
import { IProps } from "../types";
import {
  BottomBar,
  BottomSection,
  BrandAndComment,
  Cta,
  TopBar,
} from "./elements";

const LinkedInInfeed = ({ brand, caption, children, logo }: IProps) => (
  <InfeedContainerLight>
    <TopBar />
    <Scrollable tabIndex={0}>
      <BrandAndComment brand={brand} caption={caption} logo={logo} />
      {children}
      <Cta />
      <BottomSection />
    </Scrollable>
    <BottomBar />
  </InfeedContainerLight>
);

export { LinkedInInfeed };
