import { gql } from "@apollo/client";

export const Rating = gql`
  fragment Rating on Video {
    rating
  }
`;

export default {
  Rating,
};
