import { gql } from "@apollo/client";
import { VariantFields } from "shared/fragments/variant";

export const UnauthedVideoFieldsWithoutNotes = gql`
  fragment UnauthedVideoFieldsWithoutNotes on Video {
    contentGroup {
      id
      name
    }
    gifURI
    id
    shortHash
    thumbnailURI
  }
`;

export const UnauthedVideoNotesFields = gql`
  fragment UnauthedVideoNotesFields on VideoNotes {
    showreel
  }
`;

export const UnauthedVideoFields = gql`
  fragment UnauthedVideoFields on Video {
    contentGroup {
      id
      name
    }
    notes {
      ...UnauthedVideoNotesFields
    }
    ...UnauthedVideoFieldsWithoutNotes
  }
  ${UnauthedVideoNotesFields}
  ${UnauthedVideoFieldsWithoutNotes}
`;

export const VideoLabels = gql`
  fragment VideoLabels on Video {
    action
    concept
    status
  }
`;

export const VideoFieldsWithoutVariants = gql`
  fragment VideoFieldsWithoutVariants on Video {
    creatorID
    creatorName
    originalFilename
    ...UnauthedVideoFieldsWithoutNotes
    ...VideoLabels
  }
  ${UnauthedVideoFieldsWithoutNotes}
  ${VideoLabels}
`;

export const VideoVariantVersionsFields = gql`
  fragment VideoVariantVersionsFields on VariantVersion {
    id
    clientVisible
    isOriginalCreator
    uploadedBy
    uploadedDate
    versionOrder
    videoURI
    videoURIHDR
  }
`;

export const VideoFieldsWithoutNotes = gql`
  fragment VideoFieldsWithoutNotes on Video {
    ...VideoFieldsWithoutVariants
    variants(includeHidden: $includeHidden) {
      ...VariantFields
      sequenceID @include(if: $includeHidden)
      versions @include(if: $includeHidden) {
        ...VideoVariantVersionsFields
      }
    }
  }
  ${VariantFields}
  ${VideoFieldsWithoutVariants}
  ${VideoVariantVersionsFields}
`;

export const VideoNotesFields = gql`
  fragment VideoNotesFields on VideoNotes {
    internal
    showreel
  }
`;

export const VideoFields = gql`
  fragment VideoFields on Video {
    ...VideoFieldsWithoutNotes
    notes {
      ...VideoNotesFields
    }
  }
  ${VideoFieldsWithoutNotes}
  ${VideoNotesFields}
`;

export default {
  UnauthedVideoFields,
  UnauthedVideoFieldsWithoutNotes,
  VideoFields,
  VideoFieldsWithoutNotes,
  VideoLabels,
};
