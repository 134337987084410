import textualize from "internal/shared/utils/textualize";
import { useState } from "react";
import { secondaryButtonStyles } from "shared/components/Button";
import styled from "styled-components";

export const Container = styled.div`
  display: block;
  text-align: right;
  width: 100%;

  button {
    ${secondaryButtonStyles}
    margin-right: 0;
    margin-left: 0.5rem;
  }
`;

interface IProps {
  confirmationRequired?: boolean;
  cta: string;
  id?: string;
  onAction: () => void;
}

function UploadsGroupAction({
  confirmationRequired = false,
  cta,
  id,
  onAction,
}: IProps) {
  const [isConfirming, setIsConfirming] = useState(false);

  const initialClick = () => {
    if (isConfirming) {
      return;
    }
    if (confirmationRequired) {
      setIsConfirming(true);
    } else {
      onAction();
    }
  };

  return (
    <Container id={id}>
      <button onClick={initialClick}>
        {cta}
        {isConfirming && "?"}
      </button>
      {isConfirming && (
        <>
          <button onClick={onAction}>
            {textualize("upload.manager.confirmYes")}
          </button>
          <button onClick={() => setIsConfirming(false)}>
            {textualize("upload.manager.confirmNo")}
          </button>
        </>
      )}
    </Container>
  );
}

export default UploadsGroupAction;
