const AD_UNITS = {
  AMAZON: {
    BRAND: "Amazon",
    OTT: {
      IS_SEQUENCE: false,
      MAP_VALUE: "amazon_ott_olv",
      TYPE: "OTT/OLV",
    },
    POSTS: {
      IS_SEQUENCE: false,
      MAP_VALUE: "amazon_posts",
      TYPE: "Posts",
    },
    PRODUCT_DETAIL: {
      IS_SEQUENCE: false,
      MAP_VALUE: "amazon_product_detail_page",
      TYPE: "Product detail page",
    },
    SPONSORED_BRANDS: {
      IS_SEQUENCE: false,
      MAP_VALUE: "amazon_sponsored_brands",
      TYPE: "Sponsored brands",
    },
  },
  DISPLAY: {
    BRAND: "Display",
    DIGITAL_OOH: {
      IS_SEQUENCE: false,
      MAP_VALUE: "digital_ooh",
      TYPE: "Digital OOH",
    },
    SEQUENCE: {
      IS_SEQUENCE: true,
      MAP_VALUE: "display_sequence",
      TYPE: "Display sequence",
    },
    SINGLE: {
      IS_SEQUENCE: false,
      MAP_VALUE: "display",
      TYPE: "Display",
    },
  },
  FACEBOOK: {
    BRAND: "Facebook",
    COLLECTION_AD: {
      IS_SEQUENCE: false,
      MAP_VALUE: "facebook_collection_ad",
      TYPE: "Collection ad",
    },
    INFEED: {
      IS_SEQUENCE: false,
      MAP_VALUE: "facebook_feed",
      TYPE: "Feed",
    },
    INFEED_CAROUSEL: {
      IS_SEQUENCE: true,
      MAP_VALUE: "facebook_feed_carousel",
      TYPE: "Feed carousel",
    },
    STORIES: {
      IS_SEQUENCE: false,
      MAP_VALUE: "facebook_stories",
      TYPE: "Stories",
    },
    STORIES_CAROUSEL: {
      IS_SEQUENCE: true,
      MAP_VALUE: "facebook_stories_carousel",
      TYPE: "Stories carousel",
    },
  },
  GOOGLE: {
    BRAND: "Google",
    APP_CAMPAIGN: {
      IS_SEQUENCE: false,
      MAP_VALUE: "google_app_campaign",
      TYPE: "App campaign",
    },
  },
  INSTAGRAM: {
    BRAND: "Instagram",
    INFEED: {
      IS_SEQUENCE: false,
      MAP_VALUE: "instagram_feed",
      TYPE: "Feed",
    },
    INFEED_CAROUSEL: {
      IS_SEQUENCE: true,
      MAP_VALUE: "instagram_feed_carousel",
      TYPE: "Feed carousel",
    },
    REELS: {
      IS_SEQUENCE: false,
      MAP_VALUE: "instagram_reels",
      TYPE: "Reels",
    },
    STORIES: {
      IS_SEQUENCE: false,
      MAP_VALUE: "instagram_stories",
      TYPE: "Stories",
    },
    STORIES_CAROUSEL: {
      IS_SEQUENCE: true,
      MAP_VALUE: "instagram_stories_carousel",
      TYPE: "Stories carousel",
    },
  },
  LINKED_IN: {
    BRAND: "LinkedIn",
    INFEED: {
      IS_SEQUENCE: false,
      MAP_VALUE: "linkedin_feed",
      TYPE: "Feed",
    },
  },
  PINTEREST: {
    BRAND: "Pinterest",
    MAX_WIDTH: {
      IS_SEQUENCE: false,
      MAP_VALUE: "pinterest_max_width",
      TYPE: "Max width",
    },
    PREMIERE_SPOTLIGHT: {
      IS_SEQUENCE: false,
      MAP_VALUE: "pinterest_premiere_spotlight",
      TYPE: "Premiere Spotlight",
    },
    STANDARD_WIDTH: {
      IS_SEQUENCE: false,
      MAP_VALUE: "pinterest_standard_width",
      TYPE: "Standard width",
    },
  },
  QUIBI: {
    BRAND: "Quibi",
    DUAL: {
      IS_SEQUENCE: false,
      MAP_VALUE: "quibi_dual",
      TYPE: "Dual",
    },
    SINGLE: {
      IS_SEQUENCE: false,
      MAP_VALUE: "quibi_single",
      TYPE: "Single",
    },
  },
  REDDIT: {
    BRAND: "Reddit",
    PROMOTED_POST: {
      IS_SEQUENCE: false,
      MAP_VALUE: "reddit_promoted_post_video",
      TYPE: "Promoted post video",
    },
  },
  SNAP: {
    AD: {
      IS_SEQUENCE: false,
      MAP_VALUE: "snap_ad",
      TYPE: "Ad",
    },
    APP_INSTALL: {
      IS_SEQUENCE: false,
      MAP_VALUE: "snap_app_install",
      TYPE: "Ad - app install",
    },
    BRAND: "Snap",
    COLLECTION_AD: {
      IS_SEQUENCE: true,
      MAP_VALUE: "snap_collection_ad",
      TYPE: "Collection ad",
    },
    STORY: {
      IS_SEQUENCE: false,
      MAP_VALUE: "snap_story",
      TYPE: "Story",
    },
    STORY_APP_INSTALL: {
      IS_SEQUENCE: false,
      MAP_VALUE: "snap_story_app_install",
      TYPE: "Story app install",
    },
  },
  TIKTOK: {
    BRAND: "TikTok",
    INFEED_NATIVE: {
      IS_SEQUENCE: false,
      MAP_VALUE: "tiktok_in_feed_native",
      TYPE: "In-feed native",
    },
    TOPVIEW: {
      IS_SEQUENCE: false,
      MAP_VALUE: "tiktok_topview",
      TYPE: "Topview",
    },
  },
  TWITTER: {
    BRAND: "Twitter",
    INFEED: {
      IS_SEQUENCE: false,
      MAP_VALUE: "twitter_feed",
      TYPE: "Feed",
    },
    POLL: {
      IS_SEQUENCE: false,
      MAP_VALUE: "twitter_feed_poll",
      TYPE: "Feed with poll",
    },
  },
  YOUTUBE: {
    BRAND: "YouTube",
    BUMPER: {
      IS_SEQUENCE: false,
      MAP_VALUE: "youtube_bumper",
      TYPE: "Bumper",
    },
    SHORTS: {
      IS_SEQUENCE: false,
      MAP_VALUE: "youtube_short",
      TYPE: "Shorts",
    },
    TRUEVIEW: {
      IS_SEQUENCE: false,
      MAP_VALUE: "youtube_trueview",
      TYPE: "TrueView",
    },
    TRUEVIEW_FOR_ACTION: {
      IS_SEQUENCE: false,
      MAP_VALUE: "youtube_trueview_for_action",
      TYPE: "TrueView for Action",
    },
  },
};

export default AD_UNITS;
