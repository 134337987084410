export const AMENDS = "client-amends";
export const FORMATTING = "formatting";
export const INTERNAL_EDITS = "internal-edits";
export const ITERATIONS = "iterations";
export const LANGUAGES = "languages";
export const QUALITY_CHECK = "quality-check";

export default {
  AMENDS,
  FORMATTING,
  INTERNAL_EDITS,
  ITERATIONS,
  LANGUAGES,
  QUALITY_CHECK,
};
