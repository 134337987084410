import UPLOAD from "internal/shared/constants/actions/upload";
import { IUpload } from "internal/shared/types/upload";
import { IAction } from "shared/types/actions";

export const addUploadAction = (upload: IUpload): IAction => ({
  payload: upload,
  type: UPLOAD.ADD,
});

export const failureAction = (id: string, reason: string): IAction => ({
  payload: {
    id,
    reason,
  },
  type: UPLOAD.FAILURE,
});

export const processedAction = (
  id: string,
  successfulUploadVariant: any,
): IAction => ({
  payload: {
    id,
    variant: successfulUploadVariant,
  },
  type: UPLOAD.PROCESSED,
});

export const completeAction = (id: string): IAction => ({
  payload: {
    id,
  },
  type: UPLOAD.COMPLETE,
});

export const removeUploadAction = (id: string): IAction => ({
  payload: {
    id,
  },
  type: UPLOAD.REMOVE,
});

export const setPresignedURIAction = (
  id: string,
  presignedURI: string,
): IAction => ({
  payload: {
    id,
    presignedURI,
  },
  type: UPLOAD.SET_PRESIGNED_URI,
});

export const setUploadCompleteAction = (id: string, key: string): IAction => ({
  payload: {
    id,
    key,
  },
  type: UPLOAD.SET_UPLOAD_KEY,
});

export const setUploadProgressAction = (
  id: string,
  uploadProgress: number,
): IAction => ({
  payload: {
    id,
    uploadProgress,
  },
  type: UPLOAD.SET_UPLOAD_PROGRESS,
});

export const setVideoID = (id: string): IAction => ({
  payload: id,
  type: UPLOAD.SET_VIDEO_ID,
});

export const setPlaceholderVideo = (
  id: string,
  placeholderVideo: { id: string; shortHash: string },
): IAction => ({
  payload: {
    id,
    placeholderVideo,
  },
  type: UPLOAD.SET_VIDEO_PLACEHOLDER,
});

export const setIsExistingVariant = (
  id: string,
  isExistingVariant: boolean,
): IAction => ({
  payload: {
    id,
    isExistingVariant,
  },
  type: UPLOAD.SET_IS_EXISTING_VARIANT,
});
