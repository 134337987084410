export const UPLOAD = {
  ADD: "UPLOAD_ADD",
  COMPLETE: "UPLOAD_COMPLETE",
  FAILURE: "UPLOAD_FAILURE",
  PROCESSED: "UPLOAD_PROCESSED",
  REMOVE: "UPLOAD_REMOVE",
  SET_IS_EXISTING_VARIANT: "UPLOAD_SET_IS_EXISTING_VARIANT",
  SET_PRESIGNED_URI: "UPLOAD_SET_PRESIGNED_URI",
  SET_UPLOAD_KEY: "UPLOAD_SET_UPLOAD_KEY",
  SET_UPLOAD_PROGRESS: "UPLOAD_SET_UPLOAD_PROGRESS",
  SET_VIDEO_ID: "UPLOAD_SET_VIDEO_ID",
  SET_VIDEO_PLACEHOLDER: "UPLOAD_SET_VIDEO_PLACEHOLDER",
  SUBMISSION_COMPLETE: "UPLOAD_SUBMISSION_COMPLETE",
};

export default UPLOAD;
