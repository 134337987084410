import { lazy } from "react";

const QualityReport = lazy(() => import("./pages/QualityReport"));
const SelectVideosProjectWrapper = lazy(
  () => import("./pages/SelectVideosProjectWrapper"),
);
const TaskViewProjectWrapper = lazy(
  () => import("./pages/TaskViewProjectWrapper"),
);

export default {
  name: "curation",
  routes: [
    {
      authRequired: true,
      component: TaskViewProjectWrapper,
      fullscreen: true,
      path: "/:briefShortHash/videos/:shortHash",
    },
    {
      authRequired: true,
      component: SelectVideosProjectWrapper,
      path: "/:briefShortHash/videos",
    },
    {
      authRequired: true,
      component: QualityReport,
      path: "/:briefShortHash/quality-report",
    },
  ],
};
