import styled from "styled-components";
import { CarouselCta, StoriesBottomVignette, StoriesBrand } from "./elements";

export const StyledDiv = styled.div<{ withCaption: boolean }>`
  margin-top: ${(props) => (props.withCaption ? undefined : "-15%")};
`;

// Carousel Infeed
export const Carousel = styled.div`
  margin: 4.62% 2.89% 0;
  overflow-x: hidden;

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: transform 0.5s;
    height: 100%;
  }
`;

export const CarouselItem = styled.div`
  border: 1px solid #f0f0f0;
  border-bottom-width: 2px;
  border-radius: 0.29%;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 2.31%;
  overflow: hidden;
  width: 69%;

  video {
    height: auto;
  }
`;

export const StyledCarouselCta = styled(CarouselCta)`
  width: 100%;
`;

// Stories
export const StyledTopBar = styled.div`
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const StyledBottomBar = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
`;

export const StyledBottomVignette = styled(StoriesBottomVignette)`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1;
`;

export const StyledBrand = styled(StoriesBrand)`
  position: relative;
  top: -0.5rem;
  z-index: 2;
`;
