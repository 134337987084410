import { useCallback, useEffect } from "react";

const useBeforeUnload = (condition: boolean) => {
  const callback = useCallback((e: BeforeUnloadEvent) => {
    e.preventDefault();
    // Some browsers require returnValue to be set.
    e.returnValue = "";
  }, []);

  useEffect(() => {
    if (condition) {
      window.addEventListener("beforeunload", callback);
    } else {
      window.removeEventListener("beforeunload", callback);
    }
    return () => {
      window.removeEventListener("beforeunload", callback);
    };
  }, [callback, condition]);
};

export default useBeforeUnload;
