export const CONCEPT_1 = "concept-1";
export const CONCEPT_2 = "concept-2";
export const CONCEPT_3 = "concept-3";
export const CONCEPT_4 = "concept-4";
export const CONCEPT_5 = "concept-5";
export const CONCEPT_6 = "concept-6";
export const CONCEPT_7 = "concept-7";
export const CONCEPT_8 = "concept-8";
export const CONCEPT_9 = "concept-9";
export const CONCEPT_10 = "concept-10";

export default {
  CONCEPT_1,
  CONCEPT_2,
  CONCEPT_3,
  CONCEPT_4,
  CONCEPT_5,
  CONCEPT_6,
  CONCEPT_7,
  CONCEPT_8,
  CONCEPT_9,
  CONCEPT_10,
};
