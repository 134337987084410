import { gql } from "@apollo/client";

export const TaskFields = gql`
  fragment TaskFields on Task {
    completedAt
    dueDate
    id
    kind
    state
    title
  }
`;

export default {
  TaskFields,
};
