export type ImageMIMEType = "image/png" | "image/jpg" | "image/jpeg";
export type ImageUploadType = "jpg" | "png";
export type VideoMIMEType = "video/mp4" | "video/quicktime";
type VideoUploadType = "mp4" | "mov";

export const transformImageFileType = (mime: ImageMIMEType) => {
  const transformedFileType = mime.split("/").pop();

  // Convert jpeg into jpg, otherwise return file type
  return (
    transformedFileType === "jpeg" ? "jpg" : transformedFileType
  ) as ImageUploadType;
};

export const transformVideoFileType = (mime: VideoMIMEType): VideoUploadType =>
  mime.split("/").pop() === "quicktime" ? "mov" : "mp4";
