import { lazy } from "react";

const CreatorSelection = lazy(() => import("./pages/CreatorSelection"));

export default {
  name: "creator-selection",
  routes: [
    {
      authRequired: true,
      component: CreatorSelection,
      exact: true,
      path: "/:briefShortHash",
    },
  ],
};
