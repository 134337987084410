import IDS from "shared/constants/ids";
import { IProps } from "../types";

export const BottomBar = (props: IProps) => (
  <svg className={props.className} viewBox="0 0 375 54">
    <path d="M0 0h375v55H0z" fill="#fff" />
    <path d="M0 0h375v0.5H0z" fill="#a1a1a1" />
    <circle
      cx={187.5}
      cy={14.202}
      fill="none"
      r={9}
      stroke="#a1a1a1"
      strokeMiterlimit={10}
    />
    <path
      d="M187.5 9.882v8.64M183.18 14.202h8.64"
      fill="none"
      stroke="#a1a1a1"
      strokeMiterlimit={10}
    />
    <path
      d="M43.227 10.87l-6.06-4.04a.757.757 0 00-.84 0l-6.062 4.04a.757.757 0 00-.338.63v9.444a.758.758 0 00.758.758h4.546v-3.788h3.03v3.788h4.546a.758.758 0 00.758-.758V11.5a.757.757 0 00-.338-.63z"
      fill="#1384bc"
    />
    <g fill="none" strokeMiterlimit={10}>
      <path
        d="M45.355 12.238l-8.05-5.367a1.006 1.006 0 00-1.117 0l-8.051 5.367"
        stroke="#1384bc"
        strokeWidth={2}
      />
      <path
        d="M110.846 9.779c0 1.7-1.377 5.384-3.077 5.384s-3.077-3.685-3.077-5.384a3.077 3.077 0 016.154 0z"
        stroke="#a1a1a1"
      />
      <path
        d="M106.23 14.161v1.342l-3.103 1.46a3.302 3.302 0 00-1.896 2.988v1.75h13.077v-1.75a3.302 3.302 0 00-1.896-2.987l-3.104-1.461v-1.341M119.929 11.919c0 1.394-1.13 4.418-2.525 4.418s-2.524-3.024-2.524-4.418a2.525 2.525 0 015.049 0z"
        stroke="#a1a1a1"
      />
      <path
        d="M114.308 21.702h8.461v-1.437a2.709 2.709 0 00-1.555-2.451l-2.547-1.199v-1.1M116.142 15.515v1.1l-2.547 1.199M266.884 16.47v-4.884a4.884 4.884 0 00-9.768 0v4.883l-2.093 3.489h13.954zM266.884 16.47h-9.768"
        stroke="#a1a1a1"
      />
    </g>
    <path d="M263.395 20.306a1.395 1.395 0 11-2.79 0z" fill="#a1a1a1" />
    <path
      d="M328.667 10.035h16.666v11.667h-16.666z"
      fill="none"
      stroke="#a1a1a1"
      strokeLinejoin="round"
    />
    <path
      d="M340.333 10.035V8.81a2.107 2.107 0 00-2.106-2.107h-2.454a2.107 2.107 0 00-2.106 2.107v1.226M328.667 14.202h16.666"
      fill="none"
      stroke="#a1a1a1"
      strokeMiterlimit={10}
    />
    <text
      fill="#1384bc"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize={11}
      fontWeight={500}
      letterSpacing=".02em"
      transform="translate(21.853 36.203)"
    >
      Home
    </text>
    <text
      fill="#a1a1a1"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize={11}
      fontWeight={500}
      letterSpacing=".02em"
      transform="translate(82.106 36.203)"
    >
      My Network
    </text>
    <text
      fill="#a1a1a1"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize={11}
      fontWeight={500}
      letterSpacing=".018em"
      transform="translate(176.195 36.203)"
    >
      Post
    </text>
    <text
      fill="#a1a1a1"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize={11}
      fontWeight={500}
      letterSpacing=".02em"
      transform="translate(229.02 36.203)"
    >
      Notifications
    </text>
    <text
      fill="#a1a1a1"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize={11}
      fontWeight={500}
      letterSpacing=".02em"
      transform="translate(324.85 36.203)"
    >
      Jobs
    </text>
  </svg>
);

export const BottomSection = (props: IProps) => (
  <svg className={props.className} viewBox="0 0 375.13 231.801">
    <defs>
      <style>
        {
          ".BottomSection_svg__a{fill:#fff}.BottomSection_svg__e{font-size:14px;fill:#616770;letter-spacing:.031em}.BottomSection_svg__g,.BottomSection_svg__h{fill:none}.BottomSection_svg__e{font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif;font-weight:600}.BottomSection_svg__g,.BottomSection_svg__h{stroke:#616770}.BottomSection_svg__g{stroke-width:2px}.BottomSection_svg__g,.BottomSection_svg__h{stroke-linejoin:round}"
        }
      </style>
    </defs>
    <path className="BottomSection_svg__a" d="M0 0h375v82H0z" />
    <text
      fill="#616770"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize={14}
      letterSpacing=".031em"
      transform="translate(32.064 22.97)"
    >
      {"5.4k"}
    </text>
    <circle cx={20} cy={18} fill="#0083be" r={7} />
    <path
      className="BottomSection_svg__a"
      d="M18.523 16.945a.306.306 0 00-.3-.247h-1.69a.306.306 0 00-.306.306v3.19a.306.306 0 00.306.305h1.69a.306.306 0 00.3-.243zM23.5 17.802a.739.739 0 00-.222-.527.731.731 0 00.156-.45.72.72 0 00-.214-.512.777.777 0 00-.545-.229h-1.588l.102-.663a1.688 1.688 0 00.02-.254c0-.655-.443-1.417-1.013-1.417a.708.708 0 00-.453.164l-.058.049.082 1.088a.432.432 0 01-.01.137.438.438 0 01-.054.127l-.664 1.232v3.638a1.278 1.278 0 00.497.118h2.549a.737.737 0 00.675-.909.736.736 0 00.389-.963.734.734 0 00.351-.63z"
    />
    <path d="M12 39h351" fill="none" stroke="#eaeaea" strokeMiterlimit={10} />
    <text className="BottomSection_svg__e" transform="translate(38.22 62.226)">
      {"Like"}
    </text>
    <text className="BottomSection_svg__e" transform="translate(167.22 62.226)">
      {"Comment"}
    </text>
    <text className="BottomSection_svg__e" transform="translate(324.22 62.226)">
      {"Share"}
    </text>
    <path
      d="M30.05 66.955a5.172 5.172 0 00-2.087.404 6.269 6.269 0 01-2.008.442h-7.391a2.139 2.139 0 01-1.96-2.634 2.135 2.135 0 01-1.126-2.793 2.139 2.139 0 01-.373-3.353 2.12 2.12 0 01-.454-1.302 2.086 2.086 0 01.62-1.486 2.254 2.254 0 011.582-.665h4.604l-.296-1.921a4.857 4.857 0 01-.056-.737c0-1.901 1.283-4.109 2.937-4.109a2.053 2.053 0 011.312.475l.17.141-.24 3.157a1.249 1.249 0 00.032.397 1.266 1.266 0 00.153.368l2.121 3.931a1.44 1.44 0 001.267.756h3.773v8.93z"
      fill="none"
      stroke="#616770"
      strokeMiterlimit={10}
      strokeWidth={2}
    />
    <path
      className="BottomSection_svg__g"
      d="M319.625 58.301l-8.882-9.516v5.598c-13.946 0-13.108 13.434-13.108 13.434s3.106-5.575 13.108-5.575v5.575zM162.13 66.801l-3.844-4H143.13v-13h19v17z"
    />
    <path
      className="BottomSection_svg__h"
      d="M147.13 54.801h11M149.13 57.801h7"
    />
    <path d="M.13 81.801h375v6H.13z" fill="#e8e8e8" />
    <path className="BottomSection_svg__a" d="M.13 87.801h375v144H.13z" />
  </svg>
);

export const BrandAndComment = (props: IProps) => (
  <svg className={props.className} viewBox="0 0 375 123">
    <defs>
      <style>
        {
          ".BrandAndComment_svg__d{font-size:12px;fill:#616770;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif;letter-spacing:.055em}.BrandAndComment_svg__e{fill:none;stroke:#f1f1f1;stroke-linecap:round;stroke-miterlimit:10;stroke-width:12px}"
        }
      </style>
    </defs>
    <path d="M0 0h375v122.5H0z" fill="#fff" />
    <text
      fill="#131822"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize={15}
      fontWeight={600}
      id={IDS.MOBILE_PREVIEW.LINKED_IN.INFEED.BRAND_NAME}
      transform="translate(68.001 23.14)"
    >
      {props.brand || "Brand"}
    </text>
    <text
      className="BrandAndComment_svg__d"
      transform="translate(68.001 37.582)"
    >
      {"86,025 followers"}
    </text>
    <text
      className="BrandAndComment_svg__d"
      transform="translate(68.001 52.582)"
    >
      {"Promoted"}
    </text>
    {props.brand ? (
      <>
        <use stroke="none" xlinkHref="#rect" />
        <image
          height="48"
          id={IDS.MOBILE_PREVIEW.LINKED_IN.INFEED.BRAND_LOGO}
          width="48"
          x="12"
          xlinkHref={props.logo}
          y="10"
        />
      </>
    ) : (
      <path d="M12 10h48v48H12z" />
    )}
    {props.caption ? (
      <text
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize={13}
        id={IDS.MOBILE_PREVIEW.LINKED_IN.INFEED.BRAND_CAPTION}
        transform="translate(18 92)"
      >
        {props.caption}
      </text>
    ) : (
      <path
        className="BrandAndComment_svg__e"
        d="M20 88h333M20 106h333"
        id={IDS.MOBILE_PREVIEW.LINKED_IN.INFEED.BRAND_CAPTION}
      />
    )}
    <path
      d="M8.5 122.5v-48a2 2 0 012-2h355a2 2 0 012 2v48"
      fill="none"
      stroke="#f1f1f1"
      strokeLinecap="round"
      strokeMiterlimit={10}
    />
    <path d="M346 16h4v4h-4zM353 16h4v4h-4zM360 16h4v4h-4z" fill="#616770" />
  </svg>
);

export const Cta = (props: IProps) => (
  <svg className={props.className} viewBox="0 0 374 57">
    <defs>
      <style>
        {
          ".Cta_svg__d{fill:none;stroke-miterlimit:10;stroke:#dddede;stroke-linecap:round;stroke-width:8px}"
        }
      </style>
    </defs>
    <path d="M0 0h374v57H0z" fill="#f1f1f1" />
    <text
      fill="#0083be"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize={13}
      fontWeight={700}
      id={IDS.MOBILE_PREVIEW.LINKED_IN.INFEED.CTA.BUTTON}
      letterSpacing=".031em"
      transform="translate(281.087 23.471)"
    >
      {"Learn More"}
    </text>
    <rect
      fill="none"
      height={19.5}
      id={IDS.MOBILE_PREVIEW.LINKED_IN.INFEED.CTA.CAPTION}
      rx={2}
      stroke="#0083be"
      strokeMiterlimit={10}
      width={99.5}
      x={266.75}
      y={9}
    />
    <path className="Cta_svg__d" d="M11.5 17.5H193M11.5 41H193" />
  </svg>
);

export const TopBar = (props: IProps) => (
  <svg className={props.className} viewBox="0 0 375 44">
    <defs>
      <style>
        {
          ".TopBar_svg__b,.TopBar_svg__c,.TopBar_svg__g,.TopBar_svg__i{fill:none}.TopBar_svg__b,.TopBar_svg__c{stroke:#fff;stroke-linejoin:round}.TopBar_svg__b,.TopBar_svg__g{stroke-width:2px}.TopBar_svg__g,.TopBar_svg__i{stroke-miterlimit:10}.TopBar_svg__g{stroke:#485c6a}.TopBar_svg__i{stroke:#0172b0}"
        }
      </style>
    </defs>
    <path d="M0 0h375v44H0z" fill="#0172b0" />
    <path
      className="TopBar_svg__b"
      d="M354.464 30.217l-2.63-2.736h-10.37v-8.895h13v11.631z"
    />
    <path
      className="TopBar_svg__c"
      d="M344.201 22.007h7.526M345.569 24.059h4.79"
    />
    <path className="TopBar_svg__b" d="M338.362 22.481h-1.898v-8.895h13v2.23" />
    <rect fill="#e0e7ec" height={34} rx={4} width={265} x={59.5} y={4.5} />
    <circle cx={32.5} cy={20.5} r={15} stroke="#fff" strokeMiterlimit={10} />
    <circle cx={42} cy={28} fill="#fff" r={9} />
    <circle className="TopBar_svg__g" cx={72.145} cy={20.145} r={4.645} />
    <path className="TopBar_svg__g" d="M75.722 23.722L79.5 27.5" />
    <text
      fill="#616770"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize={14}
      fontWeight={600}
      transform="translate(93.12 26.476)"
    >
      {"Search"}
    </text>
    <g>
      <path className="TopBar_svg__i" d="M38 28h8M38 25h8M38 31h8" />
    </g>
  </svg>
);
