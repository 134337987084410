import {
  InfeedContainerLight,
  Scrollable,
  ShowIfActive,
  StoriesContainer,
} from "shared/components/MobilePreviewPartners/shared";
import { IProps } from "../types";
import {
  BottomBar,
  BrandCaption,
  CTABar,
  CarouselLikes,
  CollectionAdditionalSlots,
  Likes,
  StoriesBottomBar,
  StoriesTimeBarSingle,
  TopBar,
} from "./elements";
import {
  Carousel,
  CarouselItem,
  StyledBottomBar,
  StyledBottomVignette,
  StyledBrand,
  StyledCarouselCta,
  StyledDiv,
  StyledTopBar,
} from "./styles";

const FacebookInfeed = ({
  brand,
  caption,
  children,
  logo,
  videoId,
}: IProps) => (
  <InfeedContainerLight>
    <TopBar />
    <Scrollable tabIndex={0}>
      <BrandCaption
        brand={brand}
        caption={caption}
        logo={logo}
        videoId={videoId}
      />
      <StyledDiv withCaption={!!caption}>
        {children}
        <CTABar />
        <Likes />
      </StyledDiv>
    </Scrollable>
    <BottomBar />
  </InfeedContainerLight>
);

const FacebookCarouselInfeed = ({
  brand,
  caption,
  children,
  itemIndex,
  logo,
  videoId,
}: IProps) => {
  const getTranslation = () => {
    const totalChildren = children && (children as any[]).length;

    if (itemIndex) {
      const isLastItem = totalChildren && itemIndex === totalChildren - 1;

      return isLastItem
        ? 112 + (itemIndex - 2) * 71
        : 56 + (itemIndex - 1) * 71;
    } else {
      return 0;
    }
  };

  return (
    <InfeedContainerLight>
      <TopBar />
      <Scrollable tabIndex={0}>
        <BrandCaption
          brand={brand}
          caption={caption}
          logo={logo}
          videoId={videoId}
        />
        <StyledDiv withCaption={!!caption}>
          <Carousel>
            <div
              style={{
                transform: `translateX(-${getTranslation()}%)`, // Item width + right margin
              }}
            >
              {Array.isArray(children)
                ? (children as any[]).map((child) => (
                    <CarouselItem key={child.key}>
                      {child}
                      <StyledCarouselCta itemIndex={itemIndex} />
                    </CarouselItem>
                  ))
                : children}
            </div>
          </Carousel>
          <CarouselLikes />
        </StyledDiv>
      </Scrollable>
      <BottomBar />
    </InfeedContainerLight>
  );
};

const FacebookCollection = ({
  brand,
  caption,
  children,
  logo,
  videoId,
}: IProps) => (
  <InfeedContainerLight>
    <TopBar />
    <Scrollable tabIndex={0}>
      <BrandCaption
        brand={brand}
        caption={caption}
        logo={logo}
        videoId={videoId}
      />
      <StyledDiv withCaption={!!caption}>
        {children}
        <CollectionAdditionalSlots />
        <Likes />
      </StyledDiv>
    </Scrollable>
    <BottomBar />
  </InfeedContainerLight>
);

const FacebookStories = ({
  brand,
  caption,
  children,
  logo,
  itemIndex,
  videoId,
  videoTimes,
}: IProps) => (
  <StoriesContainer>
    {Array.isArray(children)
      ? (children as any[]).map((child, index) => (
          <ShowIfActive active={itemIndex === index} key={child.key}>
            {child}
          </ShowIfActive>
        ))
      : children}
    <StyledTopBar>
      <StoriesTimeBarSingle
        currentVideo={itemIndex}
        noOfVideos={(children as any[]).length}
        videoTimes={videoTimes}
      />
      <StyledBrand brand={brand} logo={logo} videoId={videoId} />
    </StyledTopBar>
    <StyledBottomBar>
      <StyledBottomVignette videoId={videoId} />
      <StoriesBottomBar caption={caption} />
    </StyledBottomBar>
  </StoriesContainer>
);

export {
  FacebookCarouselInfeed,
  FacebookCollection,
  FacebookInfeed,
  FacebookStories,
};
