export enum Taxonomy {
  Brand = "brand",
  BriefType = "briefType",
  Duration = "durationRange",
  Framework = "framework",
  Industry = "industry",
  Language = "language",
  Misc = "misc",
  Placement = "placement",
  VisualTechnique = "visualTechnique",
}

export default Taxonomy;
