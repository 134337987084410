import { lazy } from "react";

const Brands = lazy(() => import("./pages/Brands"));
const Briefs = lazy(() => import("./pages/Briefs"));
const CreatorManagement = lazy(() => import("./pages/CreatorManagement"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Downloads = lazy(() => import("./pages/Downloads"));
const Login = lazy(() => import("./pages/Login"));
const LoginCallback = lazy(() => import("./pages/LoginCallback"));

export default {
  name: "",
  routes: [
    {
      authRequired: true,
      component: Brands,
      exact: true,
      path: "brands",
    },
    {
      authRequired: true,
      component: Briefs,
      path: ":brandID/briefs",
    },
    {
      authRequired: true,
      component: CreatorManagement,
      path: "creator-management/:briefShortHash",
    },
    {
      authRequired: true,
      component: Downloads,
      fullscreen: true,
      path: "downloads/:token",
    },
    {
      component: Login,
      exact: true,
      path: "login",
    },
    {
      component: LoginCallback,
      exact: true,
      path: "login/callback",
    },
    {
      authRequired: true,
      component: Dashboard,
      exact: true,
      path: "",
    },
  ],
};
