import { lazy } from "react";

const CreatorBriefs = lazy(() => import("./pages/CreatorBriefs"));
const CreatorIndex = lazy(() => import("./pages/CreatorIndex"));
const CreatorAbout = lazy(() => import("./pages/CreatorAbout"));
const CreatorPayments = lazy(() => import("./pages/CreatorPayments"));
const CreatorVideos = lazy(() => import("./pages/CreatorVideos"));
const UnratedRookies = lazy(() => import("./pages/UnratedRookies"));

export default {
  name: "creators",
  routes: [
    {
      authRequired: true,
      component: CreatorBriefs,
      path: "/:creatorExternalID/briefs",
    },
    {
      authRequired: true,
      component: CreatorAbout,
      path: "/:creatorExternalID/information",
    },
    {
      authRequired: true,
      component: CreatorPayments,
      path: "/:creatorExternalID/payments",
    },
    {
      authRequired: true,
      component: CreatorVideos,
      path: "/:creatorExternalID/videos",
    },
    {
      authRequired: true,
      component: UnratedRookies,
      path: "/unrated-rookies",
    },
    {
      authRequired: true,
      component: CreatorIndex,
      exact: true,
      path: "/",
    },
  ],
};
