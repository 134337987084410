export default {
  BRAND: {
    BREADCRUMB_HEADING: "sharedBrandBreadcrumbHeading",
  },
  BRIEF: {
    TEAM_MEMBERS: "sharedBriefTeamMembers",
    STATUS: "sharedBriefStatus",
  },
  IMAGE_DROPZONE: {
    CURRENT_IMAGE: "sharedImageDropzoneCurrentImage",
    ERROR: {
      EQUAL_DIMENSIONS: "sharedImageDropzoneErrorEqualDimensions",
      FILETYPE: "sharedImageDropzoneErrorFileType",
      ICON: "sharedImageDropzoneErrorIcon",
      MAX_RESOLUTION: "sharedImageDropzoneErrorMaxResolution",
      MAX_SIZE: "sharedImageDropzoneErrorMaxSize",
    },
    UPLOAD_MESSAGE: "sharedImageDropzoneUploadMessage",
  },
  MULTI_BUTTON: {
    ACTION_BUTTON: "sharedMultiButtonActionButton",
  },
  NAV: {
    BACK_TO_TOP: "sharedNavBackToTop",
    BRIEF: {
      ADMIN: "sharedNavAdminLink",
      BRIEF: "sharedNavBriefLink",
      PARENT: "sharedNavBriefParentLink",
      TEASER: "sharedNavTeaserLink",
    },
    CREATORS: {
      MANAGEMENT: "sharedNavCreatorsManagementLink",
      PARENT: "sharedNavCreatorsParentLink",
      SELECTION: "sharedNavCreatorsSelectionLink",
    },
    CURATION: {
      PARENT: "sharedNavCurationParentLink",
      QUALITY_REPORT: "sharedNavCurationQualityReportLink",
      VIDEOS: "sharedNavCurationVideosLink",
    },
    INTEGRATION: {
      CREATIVEX: "sharedNavIntegrationCreativeXLink",
      PARENT: "sharedNavIntegrationParentLink",
    },
    PROJECT_DETAILS: "sharedNavProjectDetailsLink",
    SHOWREELS: "sharedNavShowreelsLink",
  },
  PLAYBACK_VIDEO: {
    VARIANTS: {
      AD_UNIT: "videoManagementVariantsAdUnitDropdown",
      ASPECT_RATIO: "videoManagementVariantsAspectRatioDropdown",
      LANGUAGE: "videoManagementVariantsLanguageDropdown",
    },
  },
  UPLOADS: {
    GLOBAL_DROPZONE: "sharedUploadsProviderGlobalDropzone",
    GROUP_ACTION: "sharedUploadsGroupAction",
    ITEM: {
      CANCEL_CONFIRM: "sharedUploadsUploadItemCancelConfirm",
      CONFIRM: "sharedUploadsUploadItemConfirm",
      CONTAINER: "sharedUploadsUploadItemContainer",
      DELETE: "sharedUploadsUploadItemDelete",
      PLAY: "sharedUploadsUploadItemPlay",
    },
    MANAGER: {
      ADD_VIDEO_BUTTON: "sharedUploadsManagerAddVideoButton",
      CONTAINER: "sharedUploadsManagerContainer",
      HEADER: "sharedUploadsManagerHeader",
      TOGGLE_EXPAND: "sharedUploadsManagerToggleExpand",
      UPLOAD_GROUP: "sharedUploadsManagerUploadGroup",
    },
    VIDEO: {
      CONTAINER: "sharedUploadsVideoUploadContainer",
      INFO: "sharedUploadsVideoUploadInfo",
      NAME: "sharedUploadsVideoUploadName",
      STATUS: "sharedUploadsVideoUploadStatus",
      UPLOAD: "sharedUploadsVideoUpload",
    },
  },
  VIDEOS: {
    ACTIONS_MENU: {
      ADMIN: "sharedTileMenuAdminLink",
      BRIEF: "sharedTileMenuBriefLink",
      CREATOR_MANAGEMENT: "sharedTileMenuCreatorManagementLink",
      CREATOR_SELECTION: "sharedTileMenuCreatorSelectionLink",
      CURATION: "sharedTileMenuCurationLink",
      SHOWREELS: "sharedTileMenuShowreelsLink",
      TEASER: "sharedTileMenuTeaserLink",
    },
    TILE: {
      ACTIONS_BUTTON: "sharedVideoTileActionsButton",
    },
  },
};
