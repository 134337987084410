import { gql } from "@apollo/client";
import { VideoLabels } from "shared/fragments/video";

export const VideoLabelFields = gql`
  fragment VideoLabelFields on Video {
    ...VideoLabels
    id
    internal
    shortHash
  }
  ${VideoLabels}
`;

const GET_VIDEO_LABELS = gql`
  query GetVideoLabels($id: ID!) {
    video(id: $id) {
      ...VideoLabelFields
    }
  }
  ${VideoLabelFields}
`;

export default GET_VIDEO_LABELS;
