import attrAccept from "attr-accept";
import { Accept } from "react-dropzone";

const isValidFileType = (file: File, accept: Accept) => {
  for (const [mimeType, extensions] of Object.entries(accept)) {
    if (attrAccept(file, mimeType)) {
      if (extensions.length === 0) {
        // Accept any extension
        return true;
      }

      if (extensions.some((extension) => file.name?.endsWith(extension))) {
        return true;
      }
    }
  }

  return false;
};

export default isValidFileType;
