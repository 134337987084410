import AUTH from "shared/constants/auth";
import AuthService from "shared/services/Auth/Auth";
import getEnvironment from "shared/utils/getEnvironment";

const { CLIENT_ID, DOMAIN, REALM } = AUTH[getEnvironment()];

export const InternalAuth = new AuthService({
  clientID: CLIENT_ID,
  domain: DOMAIN,
  realm: REALM,
});

export default InternalAuth;
