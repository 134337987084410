import { Fragment } from "react";
import COLORS from "shared/constants/colors";
import styled from "styled-components";

export interface IProps {
  term?: string;
  text: string;
}

export const Highlight = styled.span`
  border-bottom: 1px solid ${COLORS.GREEN};

  @supports (text-decoration: ${COLORS.GREEN} underline) {
    border-bottom: 0;
    text-decoration: ${COLORS.GREEN} underline;
  }
`;

export const HighlightTerm = ({ term, text }: IProps) => {
  if (!term) {
    return <>{text}</>;
  }

  // Strip any accents and special characters from both text and term
  const normalizedText = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  const normalizedTerm = term.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  // Escape special characters from term that may break regex
  // eslint-disable-next-line no-useless-escape
  const escapedTerm = normalizedTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  const regex = new RegExp(`(${escapedTerm})`, "gi");

  // Find all occurances in normalised string
  const positions: number[] = [];
  let match = regex.exec(normalizedText);
  while (match !== null) {
    positions.push(match.index);
    match = regex.exec(normalizedText);
  }

  // Convert to highlighted version
  const highlighted = [];
  if (positions.length === 0) {
    // Nothing to highlight
    highlighted.push(text);
  } else {
    positions.forEach((position, index) => {
      // Add previous unhighlighted
      if (position > 0) {
        if (index > 0) {
          const result = text.substring(
            positions[index - 1] + term.length,
            position,
          );
          highlighted.push(
            <Fragment key={`${index}-${result}`}>{result}</Fragment>,
          );
        } else {
          const result = text.substring(0, position);
          highlighted.push(
            <Fragment key={`${index}-${result}`}>{result}</Fragment>,
          );
        }
      }
      highlighted.push(
        <Highlight
          key={`${index}-${text.substring(position, position + term.length)}`}
        >
          {text.substring(position, position + term.length)}
        </Highlight>,
      );
    });
    // Add final unhighlighted
    const final = text.substring(
      positions[positions.length - 1] + term.length,
      text.length,
    );
    highlighted.push(
      <Fragment key={`${positions.length}-${final}`}>{final}</Fragment>,
    );
  }

  return <>{highlighted}</>;
};

export default HighlightTerm;
