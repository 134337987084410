import brands from "./brands.yml";
import creatorManagement from "./creatorManagement.yml";
import dashboard from "./dashboard.yml";
import loginPage from "./loginPage.yml";
import megaSearch from "./megaSearch.yml";
import modals from "./modals.yml";
import projects from "./projects.yml";

export const copy = {
  brands,
  creatorManagement,
  dashboard,
  loginPage,
  megaSearch,
  modals,
  projects,
};

export default copy;
