import { gql } from "@apollo/client";
import { VariantFields } from "shared/fragments/variant";

export const GET_VIDEO_VARIANTS = gql`
  query GetVideoVariants($id: ID!) {
    video(id: $id) {
      id
      variants {
        ...VariantFields
      }
    }
  }
  ${VariantFields}
`;

export default {
  GET_VIDEO_VARIANTS,
};
