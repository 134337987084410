import textualize from "internal/shared/utils/textualize";
import AD_UNITS from "shared/constants/adUnits";

const SEQUENCE_REGEX = /(.*)_(\d\d)\./;
const ZERO_INDEXED_AD_UNITS = [
  AD_UNITS.SNAP.COLLECTION_AD.MAP_VALUE,
  AD_UNITS.SNAP.STORY.MAP_VALUE,
  AD_UNITS.SNAP.STORY_APP_INSTALL.MAP_VALUE,
];

export type SequencePairTuple = [file: File, sequenceNumber: number];
export type RejectedFileTuple = [file: File, reason: string];

export function sequenceNumberCheck(
  sequencePairs: SequencePairTuple[],
  index = 1,
) {
  if (sequencePairs.length <= 1) {
    return [false, textualize("upload.sequences.insufficientFiles")];
  }

  if (
    new Set(sequencePairs.map((pair: SequencePairTuple) => pair[1])).size !==
    sequencePairs.length
  ) {
    return [false, textualize("upload.sequences.duplicateNumbers")];
  }

  const sortedFiles = sequencePairs.sort(
    (A: SequencePairTuple, B: SequencePairTuple) => A[1] - B[1],
  );

  if (sortedFiles[0][1] !== index) {
    return [
      false,
      textualize("upload.sequences.incorrectIndex", {
        index,
      }),
    ];
  }

  for (let i = 0; i < sequencePairs.length; i++) {
    if (sortedFiles[i][1] !== i + index) {
      return [false, textualize("upload.sequences.sequentialError")];
    }
  }

  return [true, undefined];
}

function sequenceValidation(files: File[]) {
  const sequenceVideos: {
    [prefix: string]: SequencePairTuple[];
  } = {};
  let approvedFiles: File[] = [];
  let rejectedFiles: RejectedFileTuple[] = [];

  //   Collate videos that say they are part of a sequence
  files.forEach((file) => {
    const result = SEQUENCE_REGEX.exec(file.name);

    if (!result) {
      // Any other files with the same stem as this file?
      const othersInSequence = files.some(
        (otherFile: File) =>
          otherFile.name !== file.name &&
          otherFile.name.split(".")[0].startsWith(file.name.split(".")[0]),
      );

      if (othersInSequence) {
        rejectedFiles.push([
          file,
          textualize("upload.sequences.incorrectSequenceFilename") as string,
        ]);
        return;
      }
      // Not trying to be a sequence so just approve and bail
      approvedFiles.push(file);
      return;
    }

    const prefix = result?.[1];
    const sequence = result?.[2];
    if (!sequenceVideos[prefix]) {
      sequenceVideos[prefix] = [];
    }
    sequenceVideos[prefix].push([file, parseInt(sequence, 0)]);
  });

  // Test each sequence video to see if the containing videos form a legitimate sequence
  Object.values(sequenceVideos).forEach(
    (sequencePairs: SequencePairTuple[]) => {
      const index = ZERO_INDEXED_AD_UNITS.some((adUnit) =>
        sequencePairs[0][0].name.includes(adUnit),
      )
        ? 0
        : 1;

      const [pass, error] = sequenceNumberCheck(sequencePairs, index);
      if (pass) {
        approvedFiles = approvedFiles.concat(
          sequencePairs.map((pair: SequencePairTuple) => pair[0]),
        );
      } else {
        rejectedFiles = rejectedFiles.concat(
          sequencePairs.map(
            (pair: SequencePairTuple) => [pair[0], error] as RejectedFileTuple,
          ),
        );
      }
    },
  );

  return { approvedFiles, rejectedFiles };
}

export default sequenceValidation;
