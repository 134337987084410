import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useRouteMatch } from "react-router-dom";
import Environment from "shared/types/environment";
import checkUpdateAvailable from "shared/utils/checkUpdateAvailable";
import getEnvironment from "shared/utils/getEnvironment";

export const CHECK_INTERVAL = 60000; // Check if we need to fetch each version each minute (to combat device sleep, low priority etc)
export const FETCH_INTERVAL = 600000; // Check for a new version every 10 minutes

// Don't check for updates on showreel routes
const IGNORE_ROUTES: string[] = [];

interface IProps {
  children?: ReactNode;
}

export const UpdateAvailableContext = createContext<boolean>(false);

export const UpdateAvailableProvider = ({ children }: IProps) => {
  const ignoreRouteMatched = useRouteMatch(IGNORE_ROUTES);
  const routeIgnored = ignoreRouteMatched?.isExact === true;

  const [updateAvailable, setUpdateAvailable] = useState(false);
  const lastCheckTimestamp = useRef<number>();
  const timeoutRef = useRef<number>();

  // Keep checking until an update is available
  const check = useCallback(async () => {
    if (
      !lastCheckTimestamp.current ||
      lastCheckTimestamp.current + FETCH_INTERVAL <= Date.now()
    ) {
      lastCheckTimestamp.current = Date.now();

      if (await checkUpdateAvailable()) {
        setUpdateAvailable(true);
      } else {
        timeoutRef.current = window.setTimeout(check, CHECK_INTERVAL);
      }
    } else {
      timeoutRef.current = window.setTimeout(check, CHECK_INTERVAL);
    }
  }, []);

  useEffect(() => {
    if (!routeIgnored && getEnvironment() !== Environment.Development) {
      check();

      return () => window.clearTimeout(timeoutRef.current);
    }

    return;
  }, [check, routeIgnored]);

  return (
    <UpdateAvailableContext.Provider value={updateAvailable}>
      {children}
    </UpdateAvailableContext.Provider>
  );
};

export default UpdateAvailableProvider;
