import { gql } from "@apollo/client";

export const BriefAspectRatioAdUnitFields = gql`
  fragment BriefAspectRatioAdUnitFields on BriefAspectRatioAdUnit {
    id
    adUnit
    aspectRatio
  }
`;

export default {
  BriefAspectRatioAdUnitFields,
};
