import IDS from "shared/constants/ids";
import { IProps } from "../types";

export const AppCta = ({ videoId, ...props }: IProps) => (
  <svg className={props.className} viewBox="0 0 1080 290">
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="40"
      id={IDS.MOBILE_PREVIEW.SNAPCHAT.AD.CTA.AD}
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="992" y="193.799">
        Ad
      </tspan>
    </text>
    <rect
      fill="black"
      fillOpacity="0.33"
      height="200"
      rx="40"
      width="880"
      x="30"
    />
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="40"
      id={IDS.MOBILE_PREVIEW.SNAPCHAT.APP_AD.CTA.APP_DESCRIPTION}
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="68" y="124.799">
        Lorem ipsum dolor sit amet
      </tspan>
    </text>
    <text
      fill="white"
      fillOpacity="0.5"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="40"
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="70" y="170.799">
        website.com
      </tspan>
    </text>
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="48"
      fontWeight="bold"
      id={IDS.MOBILE_PREVIEW.SNAPCHAT.APP_AD.CTA.APP_NAME}
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="68" y="69.0586">
        {props.brand || "Brand"}
      </tspan>
    </text>
  </svg>
);

export const Brand = (props: IProps) => (
  <svg className={props.className} viewBox="0 0 1080 150">
    <path
      d="M995.161 85C995.161 90.5278 990.617 95 985 95C979.383 95 974.839 90.5278 974.839 85C974.839 79.4722 979.383 75 985 75C990.617 75 995.161 79.4722 995.161 85ZM1009.84 75C1004.22 75 999.677 79.4722 999.677 85C999.677 90.5278 1004.22 95 1009.84 95C1015.46 95 1020 90.5278 1020 85C1020 79.4722 1015.46 75 1009.84 75ZM960.161 75C954.544 75 950 79.4722 950 85C950 90.5278 954.544 95 960.161 95C965.778 95 970.323 90.5278 970.323 85C970.323 79.4722 965.778 75 960.161 75Z"
      fill="white"
      id={IDS.MOBILE_PREVIEW.SNAPCHAT.AD.MORE}
    />
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="40"
      id={IDS.MOBILE_PREVIEW.SNAPCHAT.AD.BRAND_CAPTION}
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="139" y="134.799">
        Headline
      </tspan>
    </text>
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="48"
      fontWeight="bold"
      id={IDS.MOBILE_PREVIEW.SNAPCHAT.AD.BRAND_NAME}
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="139" y="84.0586">
        {props.brand || "Brand"}
      </tspan>
    </text>
    {props.logo ? (
      <>
        <defs>
          <rect
            fill="#000"
            height="110"
            id={`rect-${props.videoId}`}
            rx="110"
            width="110"
            x="12"
            y="40"
          />
          <clipPath id={`clip-${props.videoId}`}>
            <use xlinkHref={`#rect-${props.videoId}`} />
          </clipPath>
        </defs>
        <use stroke="none" xlinkHref={`#rect-${props.videoId}`} />
        <image
          clipPath={`url(#clip-${props.videoId})`}
          height="110"
          id={IDS.MOBILE_PREVIEW.SNAPCHAT.AD.BRAND_LOGO}
          width="110"
          x="12"
          xlinkHref={props.logo}
          y="40"
        />
      </>
    ) : (
      <circle cx="69" cy="95" fill="white" r="55" />
    )}
  </svg>
);

export const CollectionCta = ({ videoId, ...props }: IProps) => (
  <svg className={props.className} viewBox="0 0 1080 395">
    <rect
      fill="white"
      height="227"
      id={`${IDS.MOBILE_PREVIEW.SNAPCHAT.COLLECTION.SNAP}-1`}
      width="227"
      x="30"
      y="78"
    />
    <rect
      fill="white"
      height="227"
      id={`${IDS.MOBILE_PREVIEW.SNAPCHAT.COLLECTION.SNAP}-2`}
      width="227"
      x="293"
      y="78"
    />
    <rect
      fill="white"
      height="227"
      id={`${IDS.MOBILE_PREVIEW.SNAPCHAT.COLLECTION.SNAP}-3`}
      width="227"
      x="556"
      y="78"
    />
    <rect
      fill="white"
      height="227"
      id={`${IDS.MOBILE_PREVIEW.SNAPCHAT.COLLECTION.SNAP}-4`}
      width="227"
      x="819"
      y="78"
    />
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="40"
      fontWeight="bold"
      id={IDS.MOBILE_PREVIEW.SNAPCHAT.COLLECTION.CTA.HEADER}
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="30.4805" y="36.7988">
        MORE
      </tspan>
    </text>
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="40"
      id={IDS.MOBILE_PREVIEW.SNAPCHAT.COLLECTION.CTA.AD}
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="992" y="38.4448">
        Ad
      </tspan>
    </text>
  </svg>
);

export const SnapAdCta = ({ videoId, ...props }: IProps) => (
  <svg className={props.className} viewBox="0 0 1080 290">
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="40"
      id={IDS.MOBILE_PREVIEW.SNAPCHAT.AD.CTA.AD}
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="992" y="193.799">
        Ad
      </tspan>
    </text>
    <rect
      fill="black"
      fillOpacity="0.33"
      height="200"
      rx="40"
      width="880"
      x="30"
    />
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="40"
      id={IDS.MOBILE_PREVIEW.SNAPCHAT.AD.CTA.HEADER}
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="68" y="124.799">
        Lorem ipsum dolor sit amet
      </tspan>
    </text>
    <text
      fill="white"
      fillOpacity="0.5"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="40"
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="70" y="170.799">
        website.com
      </tspan>
    </text>
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="48"
      fontWeight="bold"
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="68" y="69.0586">
        {props.brand || "Brand"}
      </tspan>
    </text>
  </svg>
);

export const StoryAdCta = ({ videoId, ...props }: IProps) => (
  <svg className={props.className} viewBox="0 0 1080 290">
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="40"
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="992" y="193.799">
        Ad
      </tspan>
    </text>
    <rect
      fill="black"
      fillOpacity="0.33"
      height="200"
      rx="40"
      width="880"
      x="30"
    />
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="40"
      id={IDS.MOBILE_PREVIEW.SNAPCHAT.STORY_AD.CTA.HEADER}
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="68" y="124.799">
        Lorem ipsum dolor sit amet
      </tspan>
    </text>
    <text
      fill="white"
      fillOpacity="0.5"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="40"
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="70" y="170.799">
        website.com
      </tspan>
    </text>
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="48"
      fontWeight="bold"
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="68" y="69.0586">
        {props.brand || "Brand"}
      </tspan>
    </text>
  </svg>
);
