import HorizontalProgress from "internal/shared/components/HorizontalProgress";
import COLORS from "shared/constants/colors";
import styled from "styled-components";

export const Progress = styled(HorizontalProgress)`
  font-size: 1em;
`;

export interface IProps {
  value: number;
}

const UploadItemProgress = ({ value }: IProps) => (
  <Progress color={COLORS.GREEN} max={1} min={0} value={value} />
);

export default UploadItemProgress;
