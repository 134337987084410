import { gql } from "@apollo/client";

const typeDefs = gql`
  type ViewOptions {
    filter: String
    sort: String
  }
  input ViewOptionsInput {
    filter: String
    sort: String
  }
  extend type Brief {
    selectedVideoIds: [ID!]
    viewOptions: ViewOptions
  }
  extend type Project {
    selectedVideoIds: [ID!]
    viewOptions: ViewOptions
  }
  extend type Mutation {
    toggleProjectVideosSelection(
      id: ID!
      ids: [ID!]
      selected: Boolean!
    ): Boolean!
    toggleVideosSelection(
      id: ID!
      shortHash: ID!
      ids: [ID!]
      selected: Boolean!
    ): Boolean!
    updateBriefView(
      id: ID!
      shortHash: ID!
      viewOptions: ViewOptionsInput!
    ): Brief!
    updateProjectView(id: ID!, viewOptions: ViewOptionsInput!): Project!
  }
  extend type Project {
    selectedVideoIds: [ID!]
    viewOptions: ViewOptions
  }
  extend type Video {
    selected: Boolean!
  }
`;

export default typeDefs;
