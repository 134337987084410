import { InMemoryCache } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

const cache = new InMemoryCache({
  typePolicies: {
    Brief: {
      fields: {
        contentGroups: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        creativeConcepts: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        selectedVideoIds: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    CreativeConcept: {
      fields: {
        videos: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    Creator: {
      fields: {
        videos: relayStylePagination(["brandID", "briefType"]),
      },
    },
    Query: {
      fields: {
        briefs: relayStylePagination([
          "dateFilter",
          "sortBy",
          "state",
          "statuses",
        ]),
        briefShowreels: relayStylePagination(["shortHash"]),
        briefsForCreator: relayStylePagination([
          "brandID",
          "externalUserID",
          "lifecycleState",
        ]),
        creatorPayments: relayStylePagination(["id"]),
        paymentBriefsByState: relayStylePagination([
          "state",
          "country",
          "dateRange",
          "issues",
        ]),
        taxonomies: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    SearchResults: {
      fields: {
        videos: relayStylePagination(),
      },
    },
    SearchVideo: {
      fields: {
        placements: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    Showreel: {
      keyFields: ["guid"],
    },
    Video: {
      fields: {
        notes: {
          merge(existing, incoming) {
            if (incoming === null) {
              return null;
            }
            return { ...existing, ...incoming };
          },
        },
      },
    },
  },
});

export default cache;
