import IDS from "shared/constants/ids";
import { IProps } from "../types";

export const BottomBar = (props: IProps) => (
  <svg className={props.className} viewBox="0 0 375 48">
    <path d="M0 0h375v48H0z" />
    <g id={IDS.MOBILE_PREVIEW.TIKTOK.INFEED.TAB.HOME}>
      <path
        d="M48.386 14.778l-10.448-8.514a1.485 1.485 0 00-1.876 0l-10.448 8.514a1.534 1.534 0 00.969 2.722h1.508l.774 8.51a1.637 1.637 0 001.63 1.49H36v-7.453a.547.547 0 01.547-.547h.906a.547.547 0 01.547.547v7.453h5.505a1.637 1.637 0 001.63-1.49l.774-8.51h1.508a1.534 1.534 0 00.969-2.723z"
        fill="#fff"
      />
      <text
        fill="#fff"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize={10}
        fontWeight={500}
        letterSpacing=".02em"
        transform="translate(23.46 40)"
      >
        Home
      </text>
    </g>
    <g fill="#bfbebf">
      <g id={IDS.MOBILE_PREVIEW.TIKTOK.INFEED.TAB.DISCOVER}>
        <path d="M122.707 25.793l-4.682-4.682a9.023 9.023 0 10-1.414 1.414l4.682 4.682zM104 15.5a7 7 0 117 7 7.008 7.008 0 01-7-7z" />
        <text
          fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
          fontSize={10}
          fontWeight={500}
          letterSpacing=".02em"
          transform="translate(92.396 40)"
        >
          Discover
        </text>
      </g>
      <g id={IDS.MOBILE_PREVIEW.TIKTOK.INFEED.TAB.INBOX}>
        <rect height={2} rx={0.405} width={8} x={258.5} y={15.5} />
        <path d="M262.5 28.166a1 1 0 01-.775-.368l-2.688-3.298H253.5a1 1 0 01-1-1v-15a1 1 0 011-1h18a1 1 0 011 1v15a1 1 0 01-1 1h-5.537l-2.688 3.298a1 1 0 01-.775.368zm-8-5.666h5.012a1 1 0 01.775.368l2.213 2.715 2.213-2.715a1 1 0 01.775-.368h5.012v-13h-16z" />
        <text
          fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
          fontSize={10}
          fontWeight={500}
          letterSpacing=".02em"
          transform="translate(249.668 40)"
        >
          Inbox
        </text>
      </g>
      <g id={IDS.MOBILE_PREVIEW.TIKTOK.INFEED.TAB.PROFILE}>
        <path d="M337.5 19a5.5 5.5 0 115.5-5.5 5.507 5.507 0 01-5.5 5.5zm0-9a3.5 3.5 0 103.5 3.5 3.504 3.504 0 00-3.5-3.5zM330.083 28.335l-.93-.144a.533.533 0 01-.444-.629 9.003 9.003 0 0117.48-.413.531.531 0 01-.413.65l-.92.187a.534.534 0 01-.618-.392 7.003 7.003 0 00-13.558.321.533.533 0 01-.597.42z" />
        <text
          fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
          fontSize={10}
          fontWeight={500}
          letterSpacing=".02em"
          transform="translate(330.638 40)"
        >
          Me
        </text>
      </g>
    </g>
    <g id={IDS.MOBILE_PREVIEW.TIKTOK.INFEED.TAB.ADD}>
      <path
        d="M174.5 8.5H187v29h-12.5a8.5 8.5 0 01-8.5-8.5v-12a8.5 8.5 0 018.5-8.5z"
        fill="#1ed4ec"
      />
      <path
        d="M187 8.5h13.5a8.5 8.5 0 018.5 8.5v12a8.5 8.5 0 01-8.5 8.5H187v-29z"
        fill="#fa2e6b"
      />
      <rect fill="#fff" height={29} rx={8} width={35} x={170} y={8.5} />
      <path d="M193.471 22H188.5v-4.971a.528.528 0 00-.529-.529h-.942a.528.528 0 00-.529.529V22h-4.971a.528.528 0 00-.529.529v.942a.528.528 0 00.529.529h4.971v4.971a.528.528 0 00.529.529h.942a.528.528 0 00.529-.529V24h4.971a.528.528 0 00.529-.529v-.942a.528.528 0 00-.529-.529z" />
    </g>
  </svg>
);

export const Brand = ({ videoId, ...props }: IProps) => (
  <svg className={props.className} viewBox="0 0 1080 1150">
    <g clipPath="url(#clip0_703_751)">
      <rect
        fill="#fff"
        height="110"
        opacity="0.5"
        rx="15"
        width="736"
        x="38"
        y="1003"
      />
      <text
        fill="#fff"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize="40"
        id={IDS.MOBILE_PREVIEW.TIKTOK.INFEED.CTA}
        letterSpacing="0em"
        xmlSpace="preserve"
      >
        <tspan x="301" y="1069.6">
          Download
        </tspan>
      </text>
      <text
        fill="#fff"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize="40"
        id={IDS.MOBILE_PREVIEW.TIKTOK.INFEED.PROMOTED_MUSIC}
        letterSpacing="0em"
        xmlSpace="preserve"
      >
        <tspan x="81" y="945.6">
          Promoted Music
        </tspan>
      </text>
      <path
        d="M60.376 929.606C62.4216 929.093 63 928.612 63 926.179V918.032C63 916.353 62.3597 915.634 60.1689 916.18L48.2487 919.197C46.1842 919.709 45.7137 920.183 45.7137 922.665V940.836C45.7137 942.607 45.5651 942.92 43.5995 943.436L39.8768 944.457C36.026 945.455 33 947.789 33 951.82C33 955.409 35.6835 958 39.7985 958C45.6744 958 49.6818 953.737 49.6818 947.677V933.946C49.6818 932.48 50.0047 932.118 50.8508 931.952L60.376 929.606Z"
        fill="#fff"
      />
      <rect
        fill="#fff"
        height="38"
        opacity="0.5"
        rx="15"
        width="171"
        x="38"
        y="851"
      />
      <text
        fill="#fff"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize="32"
        letterSpacing="0em"
        xmlSpace="preserve"
      >
        <tspan x="46" y="878.28">
          Sponsored
        </tspan>
      </text>
      <text
        fill="#fff"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize="40"
        id={IDS.MOBILE_PREVIEW.TIKTOK.INFEED.BRAND_CAPTION}
        letterSpacing="0em"
        xmlSpace="preserve"
      >
        <tspan x="38" y="808.6">
          Lorem ipsum dolor sit amet, consectetur
        </tspan>
      </text>
      <text
        fill="#fff"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize={40}
        fontWeight="bold"
        id={IDS.MOBILE_PREVIEW.TIKTOK.INFEED.BRAND_ACCOUNT}
        xmlSpace="preserve"
      >
        <tspan x="38" y="733.6">
          {`@${props.brand || "brand"}`}
        </tspan>
      </text>
      <g filter="url(#filter0_d_703_751)">
        <circle
          cx="983"
          cy="1048"
          fill="#fff"
          id={IDS.MOBILE_PREVIEW.TIKTOK.INFEED.RECORD}
          r="70"
        />
        <path
          d="M995.194 920C995.194 926.633 989.74 932 983 932C976.26 932 970.806 926.633 970.806 920C970.806 913.367 976.26 908 983 908C989.74 908 995.194 913.367 995.194 920ZM1012.81 908C1006.07 908 1000.61 913.367 1000.61 920C1000.61 926.633 1006.07 932 1012.81 932C1019.55 932 1025 926.633 1025 920C1025 913.367 1019.55 908 1012.81 908ZM953.194 908C946.453 908 941 913.367 941 920C941 926.633 946.453 932 953.194 932C959.934 932 965.387 926.633 965.387 920C965.387 913.367 959.934 908 953.194 908Z"
          fill="#fff"
        />
        <text
          fill="#fff"
          fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
          fontSize="32"
          id={IDS.MOBILE_PREVIEW.TIKTOK.INFEED.SHARES}
          letterSpacing="0em"
          xmlSpace="preserve"
        >
          <tspan x="949.189" y="853.28">
            1.23k
          </tspan>
        </text>
        <path
          d="M992.217 800C994.48 800 996.296 799.094 998.486 797.048L1031.74 765.857C1033.37 764.317 1034 762.528 1034 761C1034 759.408 1033.41 757.69 1031.74 756.116L998.486 725.166C996.071 722.911 994.518 722 992.286 722C988.983 722 986.565 724.586 986.565 727.781V742.825H985.52C956.39 742.825 943 761.517 943 791.995C943 797.119 945.225 799.826 948.128 799.826C950.278 799.826 952.354 799.165 953.983 796.134C960.627 783.742 970.087 779.277 985.52 779.277H986.565V794.399C986.565 797.594 988.983 800 992.217 800Z"
          fill="#fff"
        />
        <text
          fill="#fff"
          fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
          fontSize="32"
          id={IDS.MOBILE_PREVIEW.TIKTOK.INFEED.FAVOURITE}
          letterSpacing="0em"
          xmlSpace="preserve"
        >
          <tspan x="956.49" y="678.28">
            203
          </tspan>
        </text>
        <path
          d="M953 638.853V558.33C953 553.729 956.729 550 961.33 550H1011.31C1015.91 550 1019.64 553.729 1019.64 558.33V638.853L986.32 619.417L953 638.853Z"
          fill="#fff"
        />
        <text
          fill="#fff"
          fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
          fontSize="32"
          id={IDS.MOBILE_PREVIEW.TIKTOK.INFEED.COMMENTS}
          letterSpacing="0em"
          xmlSpace="preserve"
        >
          <tspan x="952.173" y="507.329">
            3209
          </tspan>
        </text>
        <path
          d="M956.793 458.634C962.54 458.634 974.17 453.199 982.916 447.101C1012.17 447.629 1034 431.187 1034 409.621C1034 388.793 1012.66 372 986.004 372C959.337 372 938 388.793 938 409.621C938 422.87 946.491 434.727 959.481 440.872C957.697 444.146 954.513 448.687 952.699 451.013C950.084 454.393 951.896 458.634 956.793 458.634ZM961.744 450.46C961.241 450.647 961.049 450.368 961.383 449.932C963.504 447.378 966.686 443.351 968.2 440.599C969.444 438.353 969.011 436.407 966.343 435.174C953.667 429.393 946.331 420.155 946.331 409.621C946.331 393.342 963.931 380.083 986.004 380.083C1008.1 380.083 1025.7 393.342 1025.7 409.621C1025.7 425.866 1008.1 439.125 986.004 439.125C985.234 439.125 984.083 439.092 982.619 439.059C980.847 439.059 979.513 439.562 977.863 440.839C972.867 444.435 965.442 449.002 961.744 450.46Z"
          fill="#fff"
        />
        <text
          fill="#fff"
          fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
          fontSize="32"
          id={IDS.MOBILE_PREVIEW.TIKTOK.INFEED.LIKES}
          letterSpacing="0em"
          xmlSpace="preserve"
        >
          <tspan x="947.603" y="329.329">
            12.3k
          </tspan>
        </text>
        <path
          d="M937 222.887C937 242.695 953.618 262.254 979.54 278.944C980.922 279.822 982.639 280.721 983.829 280.721C985.011 280.721 986.736 279.822 988.157 278.944C1014.04 262.254 1030.66 242.695 1030.66 222.887C1030.66 205.832 1018.88 194 1003.72 194C994.91 194 987.998 198.055 983.829 204.163C979.737 198.093 972.74 194 963.938 194C948.775 194 937 205.832 937 222.887ZM946.333 222.845C946.333 211.08 954.065 203.183 964.437 203.183C972.811 203.183 977.534 208.257 980.443 212.684C981.72 214.556 982.608 215.157 983.829 215.157C985.051 215.157 985.838 214.509 987.199 212.684C990.339 208.325 994.886 203.183 1003.22 203.183C1013.59 203.183 1021.32 211.08 1021.32 222.845C1021.32 239.257 1004.22 257.38 984.719 270.352C984.306 270.643 984.015 270.85 983.829 270.85C983.644 270.85 983.353 270.643 982.977 270.352C963.435 257.38 946.333 239.257 946.333 222.845Z"
          fill="#fff"
        />
        <circle cx="983" cy="70" r="68" stroke="#fff" strokeWidth={3} />
        {props.logo ? (
          <>
            <defs>
              <circle
                cx="983"
                cy="70"
                fill="#fff"
                id={`rect-${videoId}`}
                r="68"
              />
              <clipPath id={`clip-${videoId}`}>
                <use xlinkHref={`#rect-${videoId}`} />
              </clipPath>
            </defs>
            <image
              clipPath={`url(#clip-${videoId})`}
              id={IDS.MOBILE_PREVIEW.TIKTOK.INFEED.BRAND_LOGO}
              width="136"
              x="916"
              xlinkHref={props.logo}
            />
          </>
        ) : (
          <circle cx="983" cy="70" fill="#fff" id={`rect-${videoId}`} r="68" />
        )}
        <path
          d="M983 169C998.729 169 1012 155.858 1012 139.99C1012 124.133 998.924 111 983 111C967.076 111 954 124.133 954 139.99C954 155.95 967.076 169 983 169Z"
          fill="#818181"
        />
        <path
          d="M983 158.316C980.687 158.316 979.185 156.805 979.185 154.513V143.809H968.499C966.252 143.809 964.694 142.298 964.684 139.985C964.674 137.683 966.222 136.172 968.499 136.172H979.185V125.554C979.185 123.251 980.687 121.704 983 121.684C985.313 121.674 986.815 123.231 986.815 125.554V136.172H997.465C999.768 136.172 1001.32 137.683 1001.32 139.985C1001.32 142.298 999.768 143.809 997.465 143.809H986.815V154.513C986.815 156.805 985.313 158.316 983 158.316Z"
          fill="#fff"
        />
      </g>
    </g>
    <defs>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="1166"
        id="filter0_d_703_751"
        width="148"
        x="909"
        y="-40"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          in2="BackgroundImageFix"
          mode="normal"
          result="effect1_dropShadow_703_751"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_703_751"
          mode="normal"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_703_751">
        <rect fill="#fff" height="1150" width="1080" />
      </clipPath>
    </defs>
  </svg>
);

export const TopBar = (props: IProps) => (
  <svg className={props.className} viewBox="0 0 1080 147">
    <text
      fill="#fff"
      fontFamily="BlinkMacSystemFont"
      fontSize="50"
      fontWeight="bold"
      id={IDS.MOBILE_PREVIEW.TIKTOK.INFEED.FOR_YOU}
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="608" y="129.5">
        For You
      </tspan>
    </text>
    <text
      fill="#fff"
      fontFamily="BlinkMacSystemFont"
      fontSize="50"
      fontWeight="bold"
      id={IDS.MOBILE_PREVIEW.TIKTOK.INFEED.FOLLOWING}
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="287" y="129.5">
        Following
      </tspan>
    </text>
  </svg>
);
