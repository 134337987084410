import { forwardRef } from "react";
import HighlightTerm from "internal/shared/components/HighlightTerm";
import SearchTile, {
  IProps as ISearchTileProps,
} from "internal/system/components/SearchTile/SearchTile";
import IDS from "internal/system/constants/ids";
import { Link } from "react-router-dom";
import COLORS from "shared/constants/colors";
import FONTS from "shared/constants/fonts";
import smallTextStyles from "shared/styling/SmallText";
import styled, { css } from "styled-components";

export interface IProps extends ISearchTileProps {
  brandName: string;
  briefID: string;
  jobReference: string;
  shortHash: string;
  title: string;
  to: string;
}

export const ellipsis = css`
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const BrandName = styled.div`
  ${ellipsis}
  ${smallTextStyles}
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  padding: 0.75rem 1rem;
`;

export const Details = styled.div`
  flex: 1;
  min-width: 0;
`;

export const JobReference = styled.div`
  ${ellipsis}
  font-family: ${FONTS.FAMILY.MONOSPACE};
  font-weight: ${FONTS.WEIGHT.BOLD};
`;

export const ShortHash = styled.div`
  font-family: ${FONTS.FAMILY.MONOSPACE};
  font-weight: ${FONTS.WEIGHT.BOLD};
  margin-left: 1rem;
`;

// Filter out `inactive` prop to avoid passing it to `Link`
export const StyledLink = styled(({ inactive, ...rest }) => (
  <Link {...rest} />
))<{
  inactive?: boolean;
}>`
  border-radius: 1px;
  box-shadow: 0 0 0 2px
    ${(props) => (props.inactive === false ? COLORS.BLUE : "transparent")};
  color: ${COLORS.WHITE};
  display: block;
  outline: none;
  opacity: ${(props) => (props.inactive === true ? 0.5 : 1)};
  text-decoration: none;
  transition: all 0.3s ease;
`;

export const Title = styled.div`
  ${ellipsis}
  ${smallTextStyles}
`;

const BriefSearchTile = forwardRef(
  (
    {
      brandName,
      briefID,
      className,
      id,
      imageURI,
      inactive,
      jobReference,
      onClick,
      onMouseEnter,
      onMouseLeave,
      searchTerm,
      shortHash,
      tabIndex,
      title,
      to,
    }: IProps,
    ref: React.MutableRefObject<HTMLAnchorElement>,
  ) => (
    <StyledLink
      className={className}
      id={id}
      inactive={inactive}
      innerRef={ref}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      tabIndex={tabIndex}
      to={to}
    >
      <SearchTile imageURI={imageURI}>
        <Container>
          <Details>
            <BrandName id={`${IDS.SEARCH_TILE.BRAND_NAME}-${briefID}`}>
              {brandName}
            </BrandName>
            <JobReference id={`${IDS.SEARCH_TILE.JOB_REFERENCE}-${briefID}`}>
              <HighlightTerm term={searchTerm} text={jobReference} />
            </JobReference>
            <Title id={`${IDS.SEARCH_TILE.BRIEF_NAME}-${briefID}`}>
              <HighlightTerm term={searchTerm} text={title} />
            </Title>
          </Details>
          <ShortHash id={`${IDS.SEARCH_TILE.SHORT_HASH}-${briefID}`}>
            <HighlightTerm term={searchTerm} text={shortHash} />
          </ShortHash>
        </Container>
      </SearchTile>
    </StyledLink>
  ),
);

export default BriefSearchTile;
