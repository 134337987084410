import { IContainerProps } from "internal/shared/components/TileContainer";
import UPLOAD_STATUS from "internal/shared/constants/uploadStatuses";
import { IUpload } from "internal/shared/types/upload";
import textualize from "internal/shared/utils/textualize";
import { MouseEvent } from "react";
import { ItemStatus, ItemWrapper, SequenceInfo } from "./UploadItemComponents";

export interface IProps extends IContainerProps {
  getStatusComponent: (status: string) => JSX.Element | null;
  id?: string;
  onMouseEnter?: (e: MouseEvent) => void;
  onMouseLeave?: (e: MouseEvent) => void;
  sequenceNumber?: number;
  upload: IUpload;
}

const UploadItem: React.FC<IProps> = ({
  getStatusComponent,
  id,
  onMouseEnter,
  onMouseLeave,
  sequenceNumber,
  upload,
}: IProps) => {
  // Show different components based on the state within the upload
  let status = UPLOAD_STATUS.UPLOADED;

  if (upload.failure) {
    status = UPLOAD_STATUS.FAILURE;
  } else if (!upload.uploadKey) {
    status = UPLOAD_STATUS.PROGRESS;
  } else if (!upload.processed) {
    status = UPLOAD_STATUS.PROCESSING;
  }

  const additionalInfo = getStatusComponent!(status);

  return (
    <div id={id} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {!!sequenceNumber && sequenceNumber > 0 && (
        <ItemStatus>
          <SequenceInfo>
            {upload.filename} - {textualize("upload.item.sequenceCard")}{" "}
            {sequenceNumber}
          </SequenceInfo>
        </ItemStatus>
      )}
      {!!additionalInfo && <ItemWrapper>{additionalInfo}</ItemWrapper>}
    </div>
  );
};

export default UploadItem;
