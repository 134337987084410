import styled from "styled-components";

export const Container = styled.div`
  background: transparent;
  height: 100%;
  user-select: none;
  width: 100%;
  svg {
    display: block;
  }
`;

export const InfeedContainer = styled(Container)`
  background: black;
  display: flex;
  flex-direction: column;
`;

// For ad units that do not have a dark mode
export const InfeedContainerLight = styled(InfeedContainer)`
  background: white;
`;

export const Scrollable = styled.div`
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  > * {
    flex: 0 0 auto;
  }
`;

export const StoriesContainer = styled(Container)`
  display: block;
  position: relative;
  width: 100%;
`;

export const ShowIfActive = styled.div<{ active: boolean }>`
  display: ${(props) => (props.active ? undefined : "none")};
`;
