import styled from "styled-components";

const StyledContainer = styled.div`
  font-size: 0.75rem;
  white-space: pre-wrap;
`;

export interface IProps {
  reason?: string;
}

const UploadItemFailure = ({ reason }: IProps) => (
  <StyledContainer>{reason}</StyledContainer>
);

export default UploadItemFailure;
