import IDS from "shared/constants/ids";
import { IVideoTimes } from "shared/types/video";
import { IProps } from "../types";

interface IInformation {
  brand?: string;
  caption?: string;
  logo?: string;
  videoId?: string;
}

interface ITimerOverlay {
  videoTimes?: IVideoTimes;
}

export const formatTimes = (videoTimes?: IVideoTimes) => {
  if (videoTimes) {
    const timeLeft = videoTimes.duration - videoTimes.currentTime;

    let minutes = "0";
    if (timeLeft >= 60) {
      minutes = `${Math.floor(timeLeft / 60)}`.padStart(1, "0");
    }
    const seconds = `${Math.floor(timeLeft % 60)}`.padStart(2, "0");

    return minutes + ":" + seconds;
  }

  return "0:00";
};

export const calculateDashoffset = (
  videoTimes?: IVideoTimes,
  width?: number,
) => {
  let max = 375;

  if (width) {
    max = width;
  }

  if (!videoTimes || videoTimes.duration === 0) {
    return max;
  }

  const timeLeft = videoTimes.duration - videoTimes.currentTime;

  return max * (timeLeft / videoTimes.duration);
};

export const SixteenByNineOverlayTop = ({ ...props }: IProps) => (
  <svg className={props.className} viewBox="0 0 1920 183">
    <path
      d="M1818 148.194C1811.37 148.194 1806 142.74 1806 136C1806 129.26 1811.37 123.806 1818 123.806C1824.63 123.806 1830 129.26 1830 136C1830 142.74 1824.63 148.194 1818 148.194ZM1830 165.806C1830 159.066 1824.63 153.613 1818 153.613C1811.37 153.613 1806 159.066 1806 165.806C1806 172.547 1811.37 178 1818 178C1824.63 178 1830 172.547 1830 165.806ZM1830 106.194C1830 99.4532 1824.63 94 1818 94C1811.37 94 1806 99.4532 1806 106.194C1806 112.934 1811.37 118.387 1818 118.387C1824.63 118.387 1830 112.934 1830 106.194Z"
      fill="white"
    />
    <path
      d="M1657.67 90H1565.28C1559.6 90 1555 94.6258 1555 100.341V115.817H1565.28V100.341H1657.67V172.659H1621.76V183H1657.72C1663.4 183 1668 178.374 1668 172.659V100.341C1668 94.6258 1663.35 90 1657.67 90ZM1555 167.403V182.879H1570.38C1570.38 174.33 1563.5 167.403 1555 167.403ZM1555 146.817V157.086C1569.18 157.086 1580.66 168.735 1580.66 183H1590.94C1590.97 163.044 1574.84 146.841 1555 146.817ZM1601.24 183H1611.52C1611.4 151.637 1586.17 126.255 1555 126.183V136.452C1580.52 136.403 1601.22 157.328 1601.24 183Z"
      fill="white"
    />
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="70"
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="1015" y="162.148">
        Visit advertiser
      </tspan>
    </text>
  </svg>
);

export const TrueviewSixteenByNineOverlayBottom = ({
  isForAction,
  videoTimes,
  ...props
}: IProps) => (
  <svg className={props.className} viewBox="0 0 1920 360">
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="30"
      fontWeight="bold"
      id={IDS.MOBILE_PREVIEW.YOUTUBE.TRUEVIEW.AD_INFO_ICON}
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="53" y="322.349">
        Ad &#x2022; {formatTimes(videoTimes)} &#x2117; www.google.com
      </tspan>
    </text>
    <rect
      fill="#0C0C0E"
      height="115"
      opacity="0.33"
      width="280"
      x="1640"
      y="45"
    />
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="50"
      id={IDS.MOBILE_PREVIEW.YOUTUBE.TRUEVIEW.SKIP_AD_BUTTON}
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="1665.16" y="115.749">
        Skip Ad
      </tspan>
    </text>
    <path
      d="M1883.84 122H1889.53C1891.83 122 1893 120.794 1893 118.457V84.5228C1893 82.1478 1891.83 81 1889.53 81H1883.84C1881.56 81 1880.37 82.2065 1880.37 84.5228V118.457C1880.37 120.794 1881.55 122 1883.84 122ZM1849.89 121.313C1850.96 121.313 1851.85 120.992 1852.9 120.364L1877.76 105.53C1879.58 104.44 1880.49 103.117 1880.49 101.483C1880.49 99.8294 1879.59 98.5533 1877.76 97.4544L1852.9 82.6C1851.85 81.972 1850.95 81.6511 1849.88 81.6511C1847.75 81.6511 1846 83.2832 1846 86.2788V116.705C1846 119.697 1847.77 121.313 1849.89 121.313Z"
      fill="white"
    />
    {isForAction && (
      <>
        <rect fill="white" height="160" rx="10" width="900" x="30" />
        <path
          d="M53 36C53 30.3995 53 27.5992 54.0899 25.4601C55.0487 23.5785 56.5785 22.0487 58.4601 21.0899C60.5992 20 63.3995 20 69 20H157C162.601 20 165.401 20 167.54 21.0899C169.422 22.0487 170.951 23.5785 171.91 25.4601C173 27.5992 173 30.3995 173 36V124C173 129.601 173 132.401 171.91 134.54C170.951 136.422 169.422 137.951 167.54 138.91C165.401 140 162.601 140 157 140H69C63.3995 140 60.5992 140 58.4601 138.91C56.5785 137.951 55.0487 136.422 54.0899 134.54C53 132.401 53 129.601 53 124V36Z"
          fill="#303036"
        />
        <path
          d="M716 61C716 55.3995 716 52.5992 717.09 50.4601C718.049 48.5785 719.578 47.0487 721.46 46.0899C723.599 45 726.399 45 732 45H900C905.601 45 908.401 45 910.54 46.0899C912.422 47.0487 913.951 48.5785 914.91 50.4601C916 52.5992 916 55.3995 916 61V99C916 104.601 916 107.401 914.91 109.54C913.951 111.422 912.422 112.951 910.54 113.91C908.401 115 905.601 115 900 115H732C726.399 115 723.599 115 721.46 113.91C719.578 112.951 718.049 111.422 717.09 109.54C716 107.401 716 104.601 716 99V61Z"
          fill="#295DCD"
        />
        <text
          fill="white"
          fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
          fontSize="32"
          letterSpacing="0em"
          xmlSpace="preserve"
        >
          <tspan x="744" y="91.5391">
            Shop now
          </tspan>
        </text>
      </>
    )}
    <path
      d="M0 359 h1920"
      id={IDS.MOBILE_PREVIEW.YOUTUBE.TRUEVIEW.PROGRESS}
      stroke="#fac02d"
      strokeDasharray={1920}
      strokeDashoffset={calculateDashoffset(videoTimes, 1920)}
      strokeWidth={2}
    />
  </svg>
);

export const TrueviewOverlayBottom = ({
  videoTimes,
  ...props
}: IProps & ITimerOverlay) => (
  <svg className={props.className} viewBox="0 0 1080 225">
    <path
      d={props.isNineBySixteen ? "M0 220 h1080" : "M0 224 h1080"}
      id={IDS.MOBILE_PREVIEW.YOUTUBE.TRUEVIEW.PROGRESS}
      stroke="#fac02d"
      strokeDasharray={1080}
      strokeDashoffset={calculateDashoffset(videoTimes, 1080)}
      strokeWidth={2}
    />
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="30"
      fontWeight="bold"
      id={IDS.MOBILE_PREVIEW.YOUTUBE.TRUEVIEW.AD_INFO_ICON}
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="33" y="189.349">
        Ad &#x2022; {formatTimes(videoTimes)} &#x2117; www.google.com
      </tspan>
    </text>
    <rect
      fill="#0C0C0E"
      height="91"
      opacity="0.33"
      width="220"
      x="860"
      y="10"
    />
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="40"
      id={IDS.MOBILE_PREVIEW.YOUTUBE.TRUEVIEW.SKIP_AD_BUTTON}
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="878.557" y="66.582">
        Skip Ad
      </tspan>
    </text>
    <path
      d="M1051.59 70.9298H1056.06C1057.87 70.9298 1058.79 69.9751 1058.79 68.1262V41.2739C1058.79 39.3946 1057.87 38.4863 1056.06 38.4863H1051.59C1049.8 38.4863 1048.86 39.4411 1048.86 41.2739V68.1262C1048.86 69.9751 1049.79 70.9298 1051.59 70.9298ZM1024.91 70.386C1025.75 70.386 1026.45 70.1321 1027.28 69.6353L1046.81 57.8968C1048.24 57.0342 1048.96 55.9874 1048.96 54.6948C1048.96 53.3861 1048.25 52.3763 1046.81 51.5068L1027.28 39.7524C1026.45 39.2555 1025.75 39.0016 1024.91 39.0016C1023.23 39.0016 1021.86 40.293 1021.86 42.6634V66.7401C1021.86 69.1071 1023.24 70.386 1024.91 70.386Z"
      fill="white"
    />
    <rect fill="#0C0C0E" height="100" opacity="0.33" width="350" y="8" />
    {props.isNineBySixteen ? (
      <path
        d="M1033.5 197.298V180.078H1029.2V197.048C1029.2 199.09 1028.14 200.199 1026 200.199H1014.6V204.5H1026.38C1031.07 204.5 1033.5 202.037 1033.5 197.298ZM1033.5 176.868V159.702C1033.5 154.946 1031.07 152.5 1026.38 152.5H1014.6V156.802H1026C1028.14 156.802 1029.2 157.928 1029.2 159.952V176.868H1033.5ZM1011.41 156.802V152.5H999.634C994.943 152.5 992.5 154.946 992.5 159.702V176.868H996.803V159.952C996.803 157.928 997.875 156.802 1000.01 156.802H1011.41ZM1011.41 204.5V200.199H1000.01C997.875 200.199 996.803 199.09 996.803 197.048V180.078H992.5V197.298C992.5 202.037 994.943 204.5 999.634 204.5H1011.41Z"
        fill="white"
        transform="translate(0,-10)"
      />
    ) : (
      <path
        d="M994.202 179H1011.42V174.696H994.452C992.41 174.696 991.301 173.639 991.301 171.505V160.104H987V171.884C987 176.571 989.463 179 994.202 179ZM1014.63 179H1031.8C1036.55 179 1039 176.571 1039 171.884V160.104H1034.7V171.505C1034.7 173.639 1033.57 174.696 1031.55 174.696H1014.63V179ZM1034.7 156.91H1039V145.134C1039 140.443 1036.55 138 1031.8 138H1014.63V142.303H1031.55C1033.57 142.303 1034.7 143.375 1034.7 145.509V156.91ZM987 156.91H991.301V145.509C991.301 143.375 992.41 142.303 994.452 142.303H1011.42V138H994.202C989.463 138 987 140.443 987 145.134V156.91Z"
        fill="white"
        transform="translate(0, 10)"
      />
    )}
    <path
      d="M118.127 91H80.8786V50.04L103.052 27L105.685 29.08C106.269 29.5067 106.655 29.9867 106.841 30.52C107.027 31.0533 107.12 31.6667 107.12 32.36V33.16L103.531 50.04H129.214C130.437 50.04 131.541 50.5333 132.524 51.52C133.508 52.5067 134 53.6133 134 54.84V61.4C134 61.9867 133.934 62.6667 133.801 63.44C133.668 64.2133 133.468 64.8933 133.202 65.48L123.95 86.92C123.471 88.04 122.687 89 121.597 89.8C120.507 90.6 119.35 91 118.127 91ZM76.0929 50.04V91H67V50.04H76.0929Z"
      fill="white"
    />
    <path
      d="M238.862 28H276.085V68.96L253.926 92L251.296 89.92C250.711 89.4933 250.326 89.0133 250.14 88.48C249.954 87.9467 249.861 87.3333 249.861 86.64V85.84L253.448 68.96H227.782C226.56 68.96 225.458 68.4667 224.475 67.48C223.492 66.4933 223 65.3867 223 64.16V57.6C223 57.0133 223.066 56.3333 223.199 55.56C223.332 54.7867 223.531 54.1067 223.797 53.52L233.043 32.08C233.521 30.96 234.305 30 235.394 29.2C236.484 28.4 237.64 28 238.862 28ZM280.867 68.96V28H289.954V68.96H280.867Z"
      fill="white"
    />
  </svg>
);

export const BumperBottomOverlaySixteenByNine = ({
  videoTimes,
  ...props
}: IProps & ITimerOverlay) => (
  <svg className={props.className} viewBox="0 0 1920 350">
    <path
      d="M0 349 h1920"
      id={IDS.MOBILE_PREVIEW.YOUTUBE.TRUEVIEW.PROGRESS}
      stroke="#fac02d"
      strokeDasharray={1920}
      strokeDashoffset={calculateDashoffset(videoTimes, 1920)}
      strokeWidth={2}
    />
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="30"
      fontWeight="bold"
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan
        id={IDS.MOBILE_PREVIEW.YOUTUBE.BUMPER.AD_INFO_ICON}
        x="53"
        y="312.349"
      >
        Ad &#x2022; {formatTimes(videoTimes)} &#x2117; www.google.com
      </tspan>
    </text>
    <rect fill="#0C0C0E" height="150" opacity="0.33" width="540" x="1380" />
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="36"
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="1414.65" y="64.9189">
        Video will play&#10;
      </tspan>
      <tspan x="1467.4" y="105.919">
        after ad
      </tspan>
    </text>
    <rect fill="#303036" height="150" width="239" x="1681" />
  </svg>
);

export const OverlayTop = ({ ...props }: IProps & ITimerOverlay) => (
  <svg className={props.className} viewBox="0 0 1080 107">
    <path
      d="M1021.62 85.6666C1017.55 85.6666 1014.25 82.3243 1014.25 78.1931C1014.25 74.0619 1017.55 70.7196 1021.62 70.7196C1025.7 70.7196 1029 74.0619 1029 78.1931C1029 82.3243 1025.7 85.6666 1021.62 85.6666ZM1029 96.4616C1029 92.3304 1025.7 88.9881 1021.62 88.9881C1017.55 88.9881 1014.25 92.3304 1014.25 96.4616C1014.25 100.593 1017.55 103.935 1021.62 103.935C1025.7 103.935 1029 100.593 1029 96.4616ZM1029 59.9246C1029 55.7935 1025.7 52.4512 1021.62 52.4512C1017.55 52.4512 1014.25 55.7935 1014.25 59.9246C1014.25 64.0558 1017.55 67.3981 1021.62 67.3981C1025.7 67.3981 1029 64.0558 1029 59.9246Z"
      fill="white"
    />
    <path
      d="M943.066 50H886.27C882.777 50 879.951 52.8352 879.951 56.3383V65.8234H886.27V56.3383H943.066V100.662H920.987V107H943.096C946.588 107 949.415 104.165 949.415 100.662V56.3383C949.415 52.8352 946.559 50 943.066 50ZM879.951 97.4406V106.926H889.407C889.407 101.686 885.175 97.4406 879.951 97.4406ZM879.951 84.8234V91.1172C888.667 91.1172 895.726 98.257 895.726 107H902.045C902.06 94.7687 892.145 84.8383 879.951 84.8234ZM908.379 107H914.698C914.624 87.7773 899.115 72.2211 879.951 72.1766V78.4703C895.637 78.4406 908.364 91.2656 908.379 107Z"
      fill="white"
    />
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="36"
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="583" y="90.9189">
        Visit advertiser
      </tspan>
    </text>
    <path
      d="M79.0148 88C80.0129 87.9947 80.916 87.6097 81.6581 86.8262L104.012 63.8216C104.654 63.1769 105 62.3795 105 61.4458C105 59.5194 103.512 58 101.61 58C100.68 58 99.8148 58.3745 99.1519 59.0322L77.5773 81.259H80.4493L58.8535 59.0322C58.2094 58.3852 57.3492 58 56.3903 58C54.4832 58 53 59.5194 53 61.4458C53 62.3742 53.3517 63.1716 53.9829 63.8458L76.3472 86.8262C77.1376 87.6202 77.9977 88 79.0148 88Z"
      fill="white"
    />
  </svg>
);

export const BumperOverlayBottom = ({
  videoTimes,
  ...props
}: IProps & ITimerOverlay) => (
  <svg className={props.className} viewBox="0 0 1080 225">
    <path
      d={props.isNineBySixteen ? "M0 220 h1080" : "M0 224 h1080"}
      id={IDS.MOBILE_PREVIEW.YOUTUBE.BUMPER.PROGRESS}
      stroke="#fac02d"
      strokeDasharray={1080}
      strokeDashoffset={calculateDashoffset(videoTimes, 1080)}
      strokeWidth={2}
    />
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="30"
      fontWeight="bold"
      id={IDS.MOBILE_PREVIEW.YOUTUBE.BUMPER.TIMER}
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="33" y="189.349">
        Ad &#x2022; {formatTimes(videoTimes)} &#x2117; www.google.com
      </tspan>
    </text>
    <rect fill="#0C0C0E" height="116" opacity="0.33" width="420" x="660" />
    <text
      fill="white"
      fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
      fontSize="27"
      letterSpacing="0em"
      xmlSpace="preserve"
    >
      <tspan x="690.11" y="49.5603">
        Video will play&#10;
      </tspan>
      <tspan x="729.674" y="80.5603">
        after ad
      </tspan>
    </text>
    <rect fill="#303036" height="116" width="189" x="891" />
    <rect fill="#0C0C0E" height="100" opacity="0.33" width="350" y="8" />
    {props.isNineBySixteen ? (
      <path
        d="M1033.5 197.298V180.078H1029.2V197.048C1029.2 199.09 1028.14 200.199 1026 200.199H1014.6V204.5H1026.38C1031.07 204.5 1033.5 202.037 1033.5 197.298ZM1033.5 176.868V159.702C1033.5 154.946 1031.07 152.5 1026.38 152.5H1014.6V156.802H1026C1028.14 156.802 1029.2 157.928 1029.2 159.952V176.868H1033.5ZM1011.41 156.802V152.5H999.634C994.943 152.5 992.5 154.946 992.5 159.702V176.868H996.803V159.952C996.803 157.928 997.875 156.802 1000.01 156.802H1011.41ZM1011.41 204.5V200.199H1000.01C997.875 200.199 996.803 199.09 996.803 197.048V180.078H992.5V197.298C992.5 202.037 994.943 204.5 999.634 204.5H1011.41Z"
        fill="white"
        transform="translate(0,-10)"
      />
    ) : (
      <path
        d="M994.202 179H1011.42V174.696H994.452C992.41 174.696 991.301 173.639 991.301 171.505V160.104H987V171.884C987 176.571 989.463 179 994.202 179ZM1014.63 179H1031.8C1036.55 179 1039 176.571 1039 171.884V160.104H1034.7V171.505C1034.7 173.639 1033.57 174.696 1031.55 174.696H1014.63V179ZM1034.7 156.91H1039V145.134C1039 140.443 1036.55 138 1031.8 138H1014.63V142.303H1031.55C1033.57 142.303 1034.7 143.375 1034.7 145.509V156.91ZM987 156.91H991.301V145.509C991.301 143.375 992.41 142.303 994.452 142.303H1011.42V138H994.202C989.463 138 987 140.443 987 145.134V156.91Z"
        fill="white"
        transform="translate(0, 10)"
      />
    )}
    <path
      d="M118.127 91H80.8786V50.04L103.052 27L105.685 29.08C106.269 29.5067 106.655 29.9867 106.841 30.52C107.027 31.0533 107.12 31.6667 107.12 32.36V33.16L103.531 50.04H129.214C130.437 50.04 131.541 50.5333 132.524 51.52C133.508 52.5067 134 53.6133 134 54.84V61.4C134 61.9867 133.934 62.6667 133.801 63.44C133.668 64.2133 133.468 64.8933 133.202 65.48L123.95 86.92C123.471 88.04 122.687 89 121.597 89.8C120.507 90.6 119.35 91 118.127 91ZM76.0929 50.04V91H67V50.04H76.0929Z"
      fill="white"
    />
    <path
      d="M238.862 28H276.085V68.96L253.926 92L251.296 89.92C250.711 89.4933 250.326 89.0133 250.14 88.48C249.954 87.9467 249.861 87.3333 249.861 86.64V85.84L253.448 68.96H227.782C226.56 68.96 225.458 68.4667 224.475 67.48C223.492 66.4933 223 65.3867 223 64.16V57.6C223 57.0133 223.066 56.3333 223.199 55.56C223.332 54.7867 223.531 54.1067 223.797 53.52L233.043 32.08C233.521 30.96 234.305 30 235.394 29.2C236.484 28.4 237.64 28 238.862 28ZM280.867 68.96V28H289.954V68.96H280.867Z"
      fill="white"
    />
  </svg>
);

export const Information = ({ videoId, ...props }: IProps & IInformation) => {
  const brand =
    !props.brand || props.brand.length < 20
      ? props.brand
      : `${props.brand.slice(0, 19)}...`;

  return (
    <svg className={props.className} viewBox="0 0 375 505">
      <path d="M0 60h375v445H0z" fill="#212121" />
      <path d="M0-.5h375v60H0z" fill="#1A1A1A" />
      {props.logo ? (
        <>
          <defs>
            <rect
              height="36"
              id={`rect-${videoId}`}
              rx="18"
              width="36"
              x="16"
              y="12"
            />
            <clipPath id={`clip-${videoId}`}>
              <use xlinkHref={`#rect-${videoId}`} />
            </clipPath>
          </defs>
          <use stroke="none" xlinkHref={`#rect-${videoId}`} />
          <image
            clipPath={`url(#clip-${videoId})`}
            height="36"
            id={IDS.MOBILE_PREVIEW.YOUTUBE.SHARED.BRAND_LOGO}
            width="36"
            x="16"
            xlinkHref={props.logo}
            y="11.5"
          />
        </>
      ) : (
        <circle cx={34} cy={29} r={18} />
      )}
      <text
        fill="#FFFFFF"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize={15}
        id={IDS.MOBILE_PREVIEW.YOUTUBE.SHARED.BRAND_NAME}
        transform="translate(63.5 23.64)"
      >
        {brand || "Brand"}
      </text>
      <g fill="#908e8f" id={IDS.MOBILE_PREVIEW.YOUTUBE.SHARED.CTA.MORE}>
        <circle cx={360} cy={35} r={1.5} />
        <circle cx={360} cy={29.5} r={1.5} />
        <circle cx={360} cy={24} r={1.5} />
      </g>
      <g id={IDS.MOBILE_PREVIEW.YOUTUBE.SHARED.AD_ICON}>
        <rect fill="#f6b932" height={16} rx={3} width={21} x={64} y={31} />
        <text
          fill="#212121"
          fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
          fontSize={13}
          transform="translate(66.4 42.76)"
        >
          Ad
        </text>
      </g>
      <path d="M0 60h375" stroke="#333333" strokeMiterlimit={10} />
      <g
        fill="#333333"
        id={IDS.MOBILE_PREVIEW.YOUTUBE.SHARED.VIDEO_DESCRIPTION}
      >
        <rect height={18} rx={9} width={297} x={16} y={66} />
        <rect height={18} rx={9} width={149} x={16} y={87} />
        <rect height={11} rx={5.5} width={59} x={16} y={114.5} />
        <path d="M358 79l5-5h-10l5 5z" />
      </g>
      <g
        fill="#333333"
        id={IDS.MOBILE_PREVIEW.YOUTUBE.SHARED.VIDEO_INTERACTIONS}
      >
        <rect height={10} rx={5} width={31} x={102} y={170} />
        <circle cx={117.5} cy={153} r={14} />
        <rect height={10} rx={5} width={31} x={172} y={170} />
        <circle cx={187.5} cy={153} r={14} />
        <rect height={10} rx={5} width={31} x={242} y={170} />
        <circle cx={257.5} cy={153} r={14} />
        <rect height={10} rx={5} width={31} x={32} y={170} />
        <circle cx={47.5} cy={153} r={14} />
        <rect height={10} rx={5} width={31} x={312} y={170} />
        <circle cx={327.5} cy={153} r={14} />
      </g>
      <g fill="#333333" id={IDS.MOBILE_PREVIEW.YOUTUBE.SHARED.VIDEO_OWNER}>
        <path d="M0 196h375M0 249h375" stroke="#333333" strokeMiterlimit={10} />
        <circle cx={34} cy={221} r={18} />
        <rect height={18} rx={9} width={121} x={62} y={205} />
        <rect height={10} rx={5} width={97} x={62} y={226} />
        <rect height={18} rx={9} width={85} x={248} y={212} />
        <path d="M245.273 217.561a1.79 1.79 0 00-1.263-1.263 52.33 52.33 0 00-11.163 0 1.79 1.79 0 00-1.263 1.263 19.954 19.954 0 000 6.877 1.79 1.79 0 001.263 1.264 52.33 52.33 0 0011.163 0 1.79 1.79 0 001.263-1.263 19.954 19.954 0 000-6.877zM237 223.143v-4.286l3.711 2.143z" />
        <circle cx={352} cy={221} r={14} />
      </g>
      <rect
        fill="#333333"
        height={11}
        id={IDS.MOBILE_PREVIEW.YOUTUBE.SHARED.UP_NEXT}
        rx={5.5}
        width={59}
        x={16}
        y={270.5}
      />
      <g fill="#333333" id={IDS.MOBILE_PREVIEW.YOUTUBE.SHARED.AUTOPLAY}>
        <rect height={11} rx={5.5} width={59} x={256} y={270.5} />
        <rect height={11} rx={5.5} width={37} x={323} y={270.5} />
        <circle cx={350} cy={276} r={10} />
      </g>
      <path
        d="M16 301h160v90H16zM16 409h160v90H16z"
        fill="#333333"
        id={IDS.MOBILE_PREVIEW.YOUTUBE.SHARED.SUGGESTED_VIDEOS}
      />
      <g
        fill="#333333"
        id={IDS.MOBILE_PREVIEW.YOUTUBE.SHARED.SUGGESTED_VIDEO_DESCRIPTION}
      >
        <rect height={18} rx={9} width={145} x={191} y={301} />
        <rect fill="#333333" height={18} rx={9} width={145} x={191} y={321} />
        <rect fill="#333333" height={18} rx={9} width={145} x={191} y={341} />
        <rect fill="#333333" height={10} rx={5} width={97} x={191} y={362} />
        <rect fill="#333333" height={10} rx={5} width={97} x={191} y={377} />
        <rect fill="#333333" height={18} rx={9} width={145} x={191} y={409} />
        <rect fill="#333333" height={18} rx={9} width={145} x={191} y={429} />
        <rect fill="#333333" height={18} rx={9} width={145} x={191} y={449} />
        <rect fill="#333333" height={10} rx={5} width={97} x={191} y={470} />
        <rect fill="#333333" height={10} rx={5} width={97} x={191} y={485} />
      </g>
      {props.caption ? (
        <text
          fill="#141414"
          fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
          fontSize={13}
          id={IDS.MOBILE_PREVIEW.YOUTUBE.SHARED.CTA.CAPTION}
          transform="translate(91.4 42.76)"
        >
          {props.caption}
        </text>
      ) : (
        <g>
          <path
            d="M96.5 38.5L187 38"
            fill="none"
            stroke="#333333"
            strokeLinecap="round"
            strokeMiterlimit={10}
            strokeWidth={8}
          />
        </g>
      )}
      <g id={IDS.MOBILE_PREVIEW.YOUTUBE.SHARED.CTA.BUTTON}>
        <rect fill="#3ea5fc" height={36} rx={3} width={113} x={224.457} y={8} />
        <text
          dominantBaseline="middle"
          fill="#fff"
          fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
          fontSize={15}
          fontWeight={600}
          letterSpacing=".002em"
          textAnchor="middle"
          x={280.957}
          y={26}
        >
          LEARN MORE
        </text>
      </g>
    </svg>
  );
};

export const ShortsBottomBar = ({
  videoId,
  ...props
}: IProps & IInformation) => {
  return (
    <svg className={props.className} viewBox="0 0 1080 1109">
      <circle cx="1005.96" cy="1011.04" fill="white" r="54.2701" />
      <path
        d="M1052.52 1087.8C1064.71 1087.8 1075 1077.61 1075 1065.31C1075 1053.01 1064.86 1042.83 1052.52 1042.83C1040.17 1042.83 1030.03 1053.01 1030.03 1065.31C1030.03 1077.68 1040.17 1087.8 1052.52 1087.8Z"
        fill="#303036"
      />
      <path
        d="M1052.52 1079.51C1050.72 1079.51 1049.56 1078.34 1049.56 1076.56V1068.27H1041.27C1039.53 1068.27 1038.32 1067.09 1038.32 1065.3C1038.31 1063.52 1039.51 1062.35 1041.27 1062.35H1049.56V1054.11C1049.56 1052.33 1050.72 1051.13 1052.52 1051.11C1054.31 1051.11 1055.47 1052.31 1055.47 1054.11V1062.35H1063.73C1065.52 1062.35 1066.72 1063.52 1066.72 1065.3C1066.72 1067.09 1065.52 1068.27 1063.73 1068.27H1055.47V1076.56C1055.47 1078.34 1054.31 1079.51 1052.52 1079.51Z"
        fill="white"
      />
      <text
        fill="white"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize="32"
        letterSpacing="0em"
        xmlSpace="preserve"
      >
        <tspan x="961" y="918.539">
          Remix
        </tspan>
      </text>
      <path
        clipRule="evenodd"
        d="M1047 843.997C1047 865.909 1028.91 884 1007 884C985.123 884 967 865.909 967 843.997C967 822.123 985.091 804 1006.96 804C1028.88 804 1047 822.123 1047 843.997ZM985.257 847.726C987.186 858.491 996.033 865.923 1007.16 865.923C1012.01 865.923 1016.7 864.313 1020.02 861.643C1022.06 860.112 1022.51 857.937 1021.14 856.39C1019.78 854.789 1017.88 854.77 1016.08 856.054C1013.38 858.08 1010.69 859.155 1007.16 859.155C999.758 859.155 993.636 854.265 991.855 847.726H995.653C997.655 847.726 998.14 845.813 997.035 844.276L990.586 835.449C989.485 833.929 987.536 833.865 986.339 835.449L979.999 844.276C978.888 845.813 979.355 847.726 981.343 847.726H985.257ZM1029 840.147C1027.04 829.383 1018.22 821.95 1007.1 821.95C1002.24 821.95 997.545 823.567 994.238 826.198C992.191 827.761 991.779 829.943 993.117 831.484C994.437 833.084 996.373 833.078 998.176 831.819C1000.87 829.793 1003.56 828.687 1007.1 828.687C1014.49 828.687 1020.61 833.577 1022.39 840.147H1018.37C1016.4 840.147 1015.92 842.061 1017.02 843.598L1023.44 852.393C1024.57 853.913 1026.52 854.015 1027.72 852.393L1034.06 843.598C1035.17 842.061 1034.7 840.147 1032.71 840.147H1029Z"
        fill="white"
        fillRule="evenodd"
      />
      <text
        fill="white"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize="32"
        letterSpacing="0em"
        xmlSpace="preserve"
      >
        <tspan x="969" y="718.539">
          1.23k
        </tspan>
      </text>
      <path
        d="M1012.58 672.571C1014.57 672.571 1016.17 671.775 1018.1 669.976L1047.33 642.556C1048.76 641.202 1049.32 639.629 1049.32 638.286C1049.32 636.886 1048.8 635.376 1047.33 633.992L1018.1 606.784C1015.97 604.801 1014.61 604 1012.65 604C1009.74 604 1007.62 606.273 1007.62 609.082V622.308H1006.7C981.089 622.308 969.317 638.74 969.317 665.534C969.317 670.039 971.273 672.419 973.826 672.419C975.716 672.419 977.541 671.838 978.973 669.173C984.814 658.279 993.13 654.353 1006.7 654.353H1007.62V667.648C1007.62 670.456 1009.74 672.571 1012.58 672.571Z"
        fill="white"
      />
      <text
        fill="white"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize="32"
        letterSpacing="0em"
        xmlSpace="preserve"
      >
        <tspan x="980" y="518.539">
          123
        </tspan>
      </text>
      <text
        fill="white"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize="32"
        letterSpacing="0em"
        xmlSpace="preserve"
      >
        <tspan x="997" y="318.539">
          0
        </tspan>
      </text>
      <text
        fill="white"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize="32"
        letterSpacing="0em"
        xmlSpace="preserve"
      >
        <tspan x="968.683" y="118.539">
          1234
        </tspan>
      </text>
      <text
        fill="white"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize="40"
        letterSpacing="0em"
        xmlSpace="preserve"
      >
        <tspan x="43" y="1012.8">
          Lorem ipsum dolor sit amet, consectetur
        </tspan>
        <tspan x="43" y="1058.8">
          adipiscing elit.
        </tspan>
      </text>
      <text
        fill="white"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize="40"
        fontWeight="bold"
        id={IDS.MOBILE_PREVIEW.YOUTUBE.SHARED.BRAND_NAME}
        letterSpacing="0em"
        xmlSpace="preserve"
      >
        <tspan x="138" y="918.799">
          @{props.brand || "Brand"}
        </tspan>
      </text>
      {props.logo ? (
        <>
          <defs>
            <rect
              height="80"
              id={`rect-${videoId}`}
              rx="80"
              width="80"
              x="43"
              y="865"
            />
            <clipPath id={`clip-${videoId}`}>
              <use xlinkHref={`#rect-${videoId}`} />
            </clipPath>
          </defs>
          <use stroke="none" xlinkHref={`#rect-${videoId}`} />
          <image
            clipPath={`url(#clip-${videoId})`}
            height="80"
            id={IDS.MOBILE_PREVIEW.YOUTUBE.SHARED.BRAND_LOGO}
            width="80"
            x="43"
            xlinkHref={props.logo}
            y="865"
          />
        </>
      ) : (
        <circle cx="83" cy="905" fill="white" r="40" />
      )}
      <path
        d="M986.12 454.3H1029.32V448.9H986.12V454.3ZM986.12 442.6H1029.32V437.2H986.12V442.6ZM986.12 430.9H1029.32V425.5H986.12V430.9ZM1043 484L1028.6 469.6H976.4C975.02 469.6 973.775 469.045 972.665 467.935C971.555 466.825 971 465.58 971 464.2V417.4C971 416.02 971.555 414.775 972.665 413.665C973.775 412.555 975.02 412 976.4 412H1037.6C1039.04 412 1040.3 412.555 1041.38 413.665C1042.46 414.775 1043 416.02 1043 417.4V484Z"
        fill="white"
      />
      <path
        d="M1028.05 76H983.571V27.36L1010.05 0L1013.19 2.47C1013.89 2.97667 1014.35 3.54667 1014.57 4.18C1014.79 4.81333 1014.9 5.54167 1014.9 6.365V7.315L1010.62 27.36H1041.29C1042.75 27.36 1044.06 27.9458 1045.24 29.1175C1046.41 30.2892 1047 31.6033 1047 33.06V40.85C1047 41.5467 1046.92 42.3542 1046.76 43.2725C1046.6 44.1908 1046.37 44.9983 1046.05 45.695L1035 71.155C1034.43 72.485 1033.49 73.625 1032.19 74.575C1030.89 75.525 1029.51 76 1028.05 76ZM977.857 27.36V76H967V27.36H977.857Z"
        fill="white"
      />
      <path
        d="M985.952 199H1030.43V247.941L1003.95 275.471L1000.81 272.985C1000.11 272.475 999.651 271.902 999.429 271.265C999.206 270.627 999.095 269.895 999.095 269.066V268.11L1003.38 247.941H972.714C971.254 247.941 969.937 247.352 968.762 246.173C967.587 244.994 967 243.672 967 242.206V234.368C967 233.667 967.079 232.854 967.238 231.93C967.397 231.006 967.635 230.194 967.952 229.493L979 203.875C979.571 202.537 980.508 201.39 981.81 200.434C983.111 199.478 984.492 199 985.952 199ZM1036.14 247.941V199H1047V247.941H1036.14Z"
        fill="white"
      />
    </svg>
  );
};

export const ShortsTopBar = ({ videoId, ...props }: IProps & IInformation) => {
  return (
    <svg className={props.className} viewBox="0 0 1080 120">
      <path
        d="M1011.62 95.6666C1007.55 95.6666 1004.25 92.3243 1004.25 88.1931C1004.25 84.0619 1007.55 80.7196 1011.62 80.7196C1015.7 80.7196 1019 84.0619 1019 88.1931C1019 92.3243 1015.7 95.6666 1011.62 95.6666ZM1019 106.462C1019 102.33 1015.7 98.9881 1011.62 98.9881C1007.55 98.9881 1004.25 102.33 1004.25 106.462C1004.25 110.593 1007.55 113.935 1011.62 113.935C1015.7 113.935 1019 110.593 1019 106.462ZM1019 69.9246C1019 65.7935 1015.7 62.4512 1011.62 62.4512C1007.55 62.4512 1004.25 65.7935 1004.25 69.9246C1004.25 74.0558 1007.55 77.3981 1011.62 77.3981C1015.7 77.3981 1019 74.0558 1019 69.9246Z"
        fill="white"
      />
      <text
        fill="white"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize="48"
        fontWeight="bold"
        letterSpacing="0em"
        xmlSpace="preserve"
      >
        <tspan x="120" y="109.059">
          Shorts
        </tspan>
      </text>
      <path
        d="M44 83.0148C44.0053 84.0129 44.3903 84.916 45.1738 85.6581L68.1784 108.012C68.8231 108.654 69.6205 109 70.5542 109C72.4806 109 74 107.512 74 105.61C74 104.68 73.6255 103.815 72.9678 103.152L50.741 81.5773L50.741 84.4493L72.9678 62.8535C73.6148 62.2094 74 61.3492 74 60.3903C74 58.4832 72.4806 57 70.5542 57C69.6258 57 68.8284 57.3517 68.1542 57.9829L45.1738 80.3472C44.3798 81.1376 44 81.9977 44 83.0148Z"
        fill="white"
      />
      <path
        d="M772.394 119L750.843 97.4486C749.204 98.869 747.292 99.9752 745.107 100.767C742.922 101.559 740.6 101.956 738.142 101.956C732.242 101.956 727.243 99.9069 723.146 95.8097C719.049 91.7125 717 86.7685 717 80.9778C717 75.187 719.049 70.2431 723.146 66.1458C727.243 62.0486 732.214 60 738.06 60C743.85 60 748.781 62.0486 752.851 66.1458C756.921 70.2431 758.956 75.187 758.956 80.9778C758.956 83.3269 758.573 85.594 757.808 87.7792C757.044 89.9644 755.896 92.013 754.367 93.925L776 115.394L772.394 119ZM738.06 97.0389C742.485 97.0389 746.254 95.4683 749.368 92.3271C752.482 89.1859 754.039 85.4028 754.039 80.9778C754.039 76.5528 752.482 72.7697 749.368 69.6285C746.254 66.4873 742.485 64.9167 738.06 64.9167C733.58 64.9167 729.77 66.4873 726.628 69.6285C723.487 72.7697 721.917 76.5528 721.917 80.9778C721.917 85.4028 723.487 89.1859 726.628 92.3271C729.77 95.4683 733.58 97.0389 738.06 97.0389Z"
        fill="white"
      />
      <path
        d="M877.5 106.833C881.52 106.833 884.898 105.472 887.634 102.75C890.37 100.028 891.737 96.6389 891.737 92.5833C891.737 88.5833 890.37 85.2361 887.634 82.5417C884.898 79.8472 881.52 78.5 877.5 78.5C873.424 78.5 870.032 79.8472 867.324 82.5417C864.616 85.2361 863.262 88.5833 863.262 92.5833C863.262 96.6389 864.616 100.028 867.324 102.75C870.032 105.472 873.424 106.833 877.5 106.833ZM877.5 101.833C874.82 101.833 872.615 100.958 870.884 99.2083C869.153 97.4583 868.287 95.25 868.287 92.5833C868.287 89.9722 869.153 87.8056 870.884 86.0833C872.615 84.3611 874.82 83.5 877.5 83.5C880.124 83.5 882.316 84.3611 884.074 86.0833C885.833 87.8056 886.712 89.9722 886.712 92.5833C886.712 95.25 885.833 97.4583 884.074 99.2083C882.316 100.958 880.124 101.833 877.5 101.833ZM849.025 119C847.685 119 846.513 118.5 845.508 117.5C844.503 116.5 844 115.333 844 114V71.25C844 69.9722 844.503 68.8194 845.508 67.7917C846.513 66.7639 847.685 66.25 849.025 66.25H861.336L867.45 59H887.55L893.664 66.25H905.975C907.259 66.25 908.418 66.7639 909.451 67.7917C910.484 68.8194 911 69.9722 911 71.25V114C911 115.333 910.484 116.5 909.451 117.5C908.418 118.5 907.259 119 905.975 119H849.025ZM905.975 114V71.25H891.319L885.205 64H869.795L863.681 71.25H849.025V114H905.975Z"
        fill="white"
      />
    </svg>
  );
};

export const TrueViewForActionInformation = ({
  videoId,
  ...props
}: IProps & IInformation) => {
  const brand =
    !props.brand || props.brand.length < 20
      ? props.brand
      : `${props.brand.slice(0, 19)}...`;

  return (
    <svg className={props.className} viewBox="0 0 375 450">
      <circle cx={305.5} cy={18.5} fill="white" r={1.5} />
      <circle cx={305.5} cy={24.5} fill="white" r={1.5} />
      <circle cx={305.5} cy={30.5} fill="white" r={1.5} />
      <path d="M360.5 16.5L347.5 29.5" stroke="white" />
      <path d="M347.5 16.5L360.5 29.5" stroke="white" />
      <text
        fill="#FFFFFF"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize={18}
        fontWeight={600}
        id={IDS.MOBILE_PREVIEW.YOUTUBE.SHARED.BRAND_NAME}
        transform="translate(96 64)"
      >
        {brand || "Brand"}
      </text>
      {props.logo ? (
        <>
          <defs>
            <rect
              fill="#C4C4C4"
              height={70}
              id={`rect-${videoId}`}
              rx={14}
              width={70}
              x={15}
              y={46}
            />
            <clipPath id={`clip-${videoId}`}>
              <use xlinkHref={`#rect-${videoId}`} />
            </clipPath>
          </defs>
          <use stroke="none" xlinkHref={`#rect-${videoId}`} />
          <image
            clipPath={`url(#clip-${videoId})`}
            height={60}
            id={IDS.MOBILE_PREVIEW.YOUTUBE.SHARED.BRAND_LOGO}
            width={60}
            x={20}
            xlinkHref={props.logo}
            y={52}
          />
        </>
      ) : (
        <rect fill="#C4C4C4" height={70} rx={14} width={70} x={15} y={46} />
      )}
      <rect fill="#5E6268" height={12} rx={3} width={46} x={96} y={73} />
      <rect fill="#5E6268" height={12} rx={3} width={29} x={150} y={73} />
      <rect fill="#5E6268" height={12} rx={3} width={46} x={96} y={93} />
      <rect fill="#5E6268" height={12} rx={3} width={29} x={150} y={93} />

      <rect
        fill="none"
        height={30}
        rx={1.5}
        stroke="#5CA4F8"
        width={171}
        x={12.5}
        y={131.5}
      />
      <text
        dominantBaseline="middle"
        fill="#5CA4F8"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize={14}
        fontWeight={600}
        id={IDS.MOBILE_PREVIEW.YOUTUBE.TRUEVIEW_FOR_ACTION.LEARN_MORE_BUTTON}
        letterSpacing=".002em"
        textAnchor="middle"
        x={97}
        y={148}
      >
        LEARN MORE
      </text>
      <rect fill="#5CA4F8" height={31} rx={2} width={172} x={191} y={131} />
      <text
        dominantBaseline="middle"
        fill="#030303"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize={14}
        fontWeight={600}
        id={IDS.MOBILE_PREVIEW.YOUTUBE.TRUEVIEW_FOR_ACTION.INSTALL_BUTTON}
        letterSpacing=".002em"
        textAnchor="middle"
        x={277}
        y={148}
      >
        INSTALL
      </text>
      <text
        fill="#FFFFFF"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize={15}
        fontWeight={600}
        transform="translate(55 193)"
      >
        4.7
      </text>
      <path
        d="M84.7622 181.732C84.8371 181.501 85.1629 181.501 85.2378 181.732L86.5155 185.664C86.5489 185.767 86.6449 185.837 86.7532 185.837H90.888C91.1302 185.837 91.2309 186.147 91.0349 186.289L87.6898 188.719C87.6022 188.783 87.5656 188.896 87.599 188.999L88.8767 192.931C88.9516 193.162 88.688 193.353 88.492 193.211L85.1469 190.781C85.0593 190.717 84.9407 190.717 84.8531 190.781L81.508 193.211C81.312 193.353 81.0484 193.162 81.1233 192.931L82.401 188.999C82.4344 188.896 82.3978 188.783 82.3102 188.719L78.9651 186.289C78.7691 186.147 78.8698 185.837 79.112 185.837H83.2468C83.3551 185.837 83.4511 185.767 83.4845 185.664L84.7622 181.732Z"
        fill="white"
      />
      <text
        fill="#AAA"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize={11}
        fontWeight={400}
        transform="translate(39 212)"
      >
        33K reviews
      </text>
      <text
        fill="#FFFFFF"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize={15}
        fontWeight={600}
        transform="translate(143 193)"
      >
        Food & Drink
      </text>
      <text
        fill="#AAA"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize={11}
        fontWeight={400}
        transform="translate(162 212)"
      >
        Category
      </text>
      <text
        fill="#FFFFFF"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize={15}
        fontWeight={600}
        transform="translate(285 193)"
      >
        Price
      </text>
      <text
        fill="#AAA"
        fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif"
        fontSize={11}
        fontWeight={400}
        transform="translate(290 212)"
      >
        FREE
      </text>

      <rect fill="#5E6268" height={218} width={144} x={16} y={232} />
      <rect fill="#5E6268" height={218} width={144} x={168} y={232} />
      <rect fill="#5E6268" height={218} width={55} x={320} y={232} />
    </svg>
  );
};
