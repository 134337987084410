import Environment from "shared/types/environment";
import processEnvWarning from "shared/utils/processEnvWarning";

processEnvWarning(["CLIENT_SIDE_ID"], "LD");

export const LAUNCH_DARKLY = {
  [Environment.Development]:
    process.env.LD_CLIENT_SIDE_ID || "62286e3905ab8b14c8d21cfc",
  [Environment.Live]: "62286f3291c9bc14fadd89ec",
  [Environment.Staging]:
    process.env.LD_CLIENT_SIDE_ID || "62286f1791c9bc14fadd89e2",
};

export default LAUNCH_DARKLY;
