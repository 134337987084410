import {
  InfeedContainer,
  Scrollable,
  ShowIfActive,
  StoriesContainer,
} from "shared/components/MobilePreviewPartners/shared";
import styled from "styled-components";
import { IProps } from "../types";
import {
  BottomBar,
  Brand,
  Caption,
  CarouselLikes,
  Comments,
  Cta,
  Likes,
  NextCard,
  ReelsOverlay,
  ReelsTopBar,
  StoriesBottomBar,
  StoriesBottomVignette,
  StoriesBrand,
  StoriesTimeBarSingle,
  StoriesTopVignette,
  Topbar,
} from "./elements";

// Carousel Infeed
const Carousel = styled.div`
  overflow-x: hidden;
  flex: none;

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: transform 0.5s;

    video {
      /* Makes FF flexbox happy */
      flex: none;
      width: 0;
      min-width: 100%;
      height: 100%;
    }
  }
`;

// Reels
const StyledReelsTopBar = styled(ReelsTopBar)`
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

// Stories
const StyledTopBar = styled.div`
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const StyledTopVignette = styled(StoriesTopVignette)`
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;

const StyledTimeBarSingle = styled(StoriesTimeBarSingle)`
  position: relative;
  z-index: 2;
`;

const StyledBrand = styled(StoriesBrand)`
  position: relative;
  top: -0.5rem;
  z-index: 2;
`;

const StyledBottomBarContainer = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
`;

const StyledBottomVignette = styled(StoriesBottomVignette)`
  left: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const StyledBottomBar = styled(StoriesBottomBar)`
  position: relative;
  z-index: 2;
`;

export const InstagramInfeed = ({
  brand,
  caption,
  children,
  logo,
  videoId,
}: IProps) => (
  <InfeedContainer>
    <Topbar />
    <Scrollable tabIndex={0}>
      <Brand brand={brand} logo={logo} videoId={videoId} />
      {children}
      <Cta />
      <Likes />
      {caption && <Caption />}
      <Comments />
      <NextCard />
    </Scrollable>
    <BottomBar />
  </InfeedContainer>
);

export const InstagramCarouselInfeed = ({
  brand,
  children,
  itemIndex,
  logo,
  videoId,
}: IProps) => (
  <InfeedContainer>
    <Topbar />
    <Scrollable tabIndex={0}>
      <Brand brand={brand} logo={logo} videoId={videoId} />
      <Carousel>
        <div
          style={{
            transform: `translateX(-${(itemIndex ? itemIndex : 0) * 100}%)`, // Perspective fixes sub-pixel issues
          }}
        >
          {children}
        </div>
      </Carousel>
      <Cta />
      <CarouselLikes
        currentVideo={itemIndex}
        noOfVideos={(children as any[]).length}
      />
      <Comments />
      <NextCard />
    </Scrollable>
    <BottomBar />
  </InfeedContainer>
);

export const InstagramReels = ({ brand, children, logo, videoId }: IProps) => (
  <StoriesContainer>
    {children}
    <StyledReelsTopBar />
    <StyledBottomBarContainer>
      <ReelsOverlay brand={brand} logo={logo} videoId={videoId} />
    </StyledBottomBarContainer>
  </StoriesContainer>
);

export const InstagramStories = ({
  brand,
  children,
  itemIndex,
  logo,
  videoTimes,
  videoId,
}: IProps) => (
  <StoriesContainer>
    {Array.isArray(children)
      ? (children as any[]).map((child, index) => (
          <ShowIfActive active={itemIndex === index} key={child.key}>
            {child}
          </ShowIfActive>
        ))
      : children}
    <StyledTopBar>
      <StyledTopVignette videoId={videoId} />
      <StyledTimeBarSingle
        currentVideo={itemIndex}
        noOfVideos={(children as any[]).length}
        videoTimes={videoTimes}
      />
      <StyledBrand brand={brand} logo={logo} videoId={videoId} />
    </StyledTopBar>
    <StyledBottomBarContainer>
      <StyledBottomVignette videoId={videoId} />
      <StyledBottomBar />
    </StyledBottomBarContainer>
  </StoriesContainer>
);
