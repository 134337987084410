/* eslint-disable react-hooks/exhaustive-deps */
/* TODO: Refactor to adhere to react-hooks/exhaustive-deps logic */
import { gql, useMutation } from "@apollo/client";
import { CreateVariantUploadUriMutation as ICreateVariantUploadURI } from "internal/shared/types/graphql";
import { IUpload } from "internal/shared/types/upload";
import {
  transformVideoFileType,
  VideoMIMEType,
} from "internal/shared/utils/transformFileType";
import { useEffect, useRef, useState } from "react";

export const CREATE_VIDEO_UPLOAD_URI = gql`
  mutation createVariantUploadURI($videoFileType: VideoFileType!) {
    details: createVariantUploadURI(videoFileType: $videoFileType) {
      key
      URI
    }
  }
`;

const useSignedUpload = (
  upload: IUpload,
  onProgress: (amount: number) => void,
  onUpload: (key: string) => void,
  onFailure: (reason: string) => void,
) => {
  const [details, setDetails] = useState<{ key: string; URI: string }>();
  const xhrRef = useRef(new XMLHttpRequest());
  const xhr = xhrRef.current;

  const videoFileType = transformVideoFileType(
    upload.file.type as VideoMIMEType,
  );
  const [createVariantUploadURIMutation] = useMutation<ICreateVariantUploadURI>(
    CREATE_VIDEO_UPLOAD_URI,
    {
      variables: { videoFileType },
    },
  );

  // Handle cleanup for when component using this hook is removed
  useEffect(() => {
    const createURI = async () => {
      try {
        const response = await createVariantUploadURIMutation();
        const newDetails = response?.data?.details;

        if (newDetails) {
          setDetails(newDetails);
        }
      } catch (err) {
        onFailure(err.message);
      }
      return {};
    };

    createURI();

    return () => {
      if (xhr && upload.uploadProgress < 1) {
        xhr.abort();
      }
    };
  }, []);

  // Primary upload functionality
  useEffect(() => {
    if (details?.key) {
      const { type } = upload.file;

      const handleError = (e: ErrorEvent) => onFailure(e.message);
      const handleProgress = (e: ProgressEvent) => {
        if (e.lengthComputable) {
          const percentComplete: number = e.loaded / e.total;
          onProgress(percentComplete);
        }
      };

      xhr.upload.addEventListener("progress", handleProgress);
      xhr.addEventListener("error", handleError);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          // Set key to indicate that upload is complete and we're ready to tell BE to process
          onUpload(details.key);
        }
      };

      xhr.open("PUT", details.URI, true);
      xhr.setRequestHeader("Content-Type", type);
      xhr.send(upload.file);
    }
  }, [details]);
};

export default useSignedUpload;
