import FONTS from "shared/constants/fonts";
import styled from "styled-components";

export const ItemStatus = styled.div`
  color: white;
`;

export const ItemWrapper = styled.div`
  position: relative;
  word-break: break-word;
`;

export const SequenceInfo = styled.div`
  font-family: ${FONTS.FAMILY.MONOSPACE};
  font-size: 0.75rem;
  margin: 0.5rem 0rem;
  word-break: break-all;
`;
