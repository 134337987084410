import isNewerVersion from "shared/utils/isNewerVersion";

const currentVersion = process.env.VERSION;

export const checkUpdateAvailable = async () => {
  if (!currentVersion) {
    // Don't have anything to check against
    return false;
  }

  const latestVersion = await fetch("/version.txt")
    .then((response) => response.text())
    .catch(() => null);

  if (latestVersion && isNewerVersion(currentVersion, latestVersion)) {
    return true;
  }

  return false;
};

export default checkUpdateAvailable;
