import { gql, useQuery } from "@apollo/client";
import get from "lodash.get";
import { TaxonFields, VideoTaxonomiesFields } from "shared/fragments/inspo";
import {
  InternalTaxonomiesQuery as IInternalTaxonomies,
  TaxonFieldsFragment as ITaxon,
} from "shared/types/graphql";
import Taxonomy from "shared/types/taxonomy";

export const GET_INTERNAL_TAXONOMIES = gql`
  query InternalTaxonomies($input: SearchInput) {
    taxonomies {
      language {
        ...TaxonomyFields
      }
      ...VideoTaxonomiesFields
    }
  }
  ${TaxonFields}
  ${VideoTaxonomiesFields}
`;

const useTaxonomy = <T extends Record<string, any> = ITaxon>(
  taxonomy: Taxonomy,
  isVideoTaxonomy?: boolean,
  searchInput?: Record<string, any>,
): T[] => {
  const { data, loading, error, previousData } = useQuery<IInternalTaxonomies>(
    GET_INTERNAL_TAXONOMIES,
    searchInput
      ? {
          variables: {
            input: searchInput,
          },
        }
      : undefined,
  );

  const path = isVideoTaxonomy
    ? ["taxonomies", "videos", taxonomy, "values"]
    : ["taxonomies", taxonomy, "values"];

  if (loading || error) {
    return previousData ? (get(previousData, path, []) as T[]) : [];
  }

  return get(data, path, []) as T[];
};

export default useTaxonomy;
