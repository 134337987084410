import { gql } from "@apollo/client";
import FILTER_OPTIONS from "internal/curation/constants/selectVideoFilterOptions";
import SORT_OPTIONS from "internal/curation/constants/selectVideoSortOptions";
import { ViewOptions } from "internal/shared/fragments/viewOptions";
import {
  GetProjectSelectedVideosQuery as IGetSelectedVideos,
  GetProjectViewOptionsQuery as IGetViewOptions,
  ViewOptionsInput as IViewOptions,
} from "internal/shared/types/graphql";
import { IContext } from "internal/web/services/GraphQL/graphql-api/types/shared";

export const GET_VIEW_OPTIONS = gql`
  query GetProjectViewOptions($id: ID!) {
    project(id: $id) {
      id
      briefID
      viewOptions {
        ...ViewOptions
      }
    }
  }
  ${ViewOptions}
`;

export const GET_SELECTED_VIDEOS = gql`
  query GetProjectSelectedVideos($id: ID!) {
    project(id: $id) {
      id
      selectedVideoIds
    }
  }
`;

const Type = {
  Project: {
    selectedVideoIds: (project: NonNullable<IGetSelectedVideos["project"]>) =>
      project.selectedVideoIds || [],
    viewOptions: (
      project: NonNullable<IGetViewOptions["project"]>,
      _: any,
      { cache }: IContext,
    ) => {
      const defaultOptions = {
        __typename: "ViewOptions",
        filter: FILTER_OPTIONS.ALL,
        sort: SORT_OPTIONS.CLIENT_VIEW,
      };

      try {
        const result = cache.readQuery({
          query: GET_VIEW_OPTIONS,
          variables: { id: project.briefID },
        }) as IGetViewOptions;

        return result?.project?.viewOptions || defaultOptions;
      } catch {
        // Project not found
        return defaultOptions;
      }
    },
  },
};

const Mutation = {
  toggleProjectVideosSelection: (
    _: any,
    args: { id: string; ids: string[]; selected: boolean },
    { cache }: IContext,
  ) => {
    const result = cache.readQuery({
      query: GET_SELECTED_VIDEOS,
      variables: { id: args.id },
    }) as IGetSelectedVideos;

    const newSelectedVideos = result?.project?.selectedVideoIds
      ? args.ids.reduce(
          (selectedVideoIds, videoID) => {
            if (args.selected) {
              return [...selectedVideoIds, videoID];
            } else {
              return selectedVideoIds.filter(
                (existingVideoID) => existingVideoID !== videoID,
              );
            }
          },
          [...result.project.selectedVideoIds!],
        )
      : args.ids;

    const data = {
      project: {
        ...result.project,
        selectedVideoIds: newSelectedVideos,
      },
    };

    cache.writeQuery({
      data,
      query: GET_SELECTED_VIDEOS,
      variables: { id: args.id },
    });

    return data.project;
  },
  updateProjectView: (
    _: any,
    args: { id: string; viewOptions: IViewOptions },
    { cache }: IContext,
  ) => {
    try {
      const result = cache.readQuery({
        query: GET_VIEW_OPTIONS,
        variables: { id: args.id },
      });

      const resultViewOptions = result?.project?.viewOptions;

      const data = {
        project: {
          briefID: args.id,
          ...result?.project,
          viewOptions: {
            ...resultViewOptions,
            ...args.viewOptions,
          },
        },
      };

      cache.writeQuery({
        data,
        query: GET_VIEW_OPTIONS,
        variables: { id: args.id },
      });

      return data.project;
    } catch {
      // Cache not populated with local state
    }
  },
};

export { Mutation, Type };
