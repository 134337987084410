import GlobalNav from "internal/system/components/GlobalNav";
import Session from "internal/web/services/Session";
import { Redirect, Route, RouteProps } from "react-router-dom";
import FetchViewer from "shared/components/FetchViewer";
import SessionDetails from "shared/components/SessionDetails";

interface IGuardedRouteProps extends RouteProps {
  children?: React.ReactNode;
  fullscreen?: boolean;
  isAuthenticated: () => boolean;
}

function GuardedRoute({
  children,
  exact,
  fullscreen,
  isAuthenticated,
  location,
  path,
}: IGuardedRouteProps) {
  return (
    <Route exact={exact} path={path}>
      {!isAuthenticated() ? (
        <Redirect
          to={{
            pathname: "/login",
            state: {
              referrerUri: location && location.pathname + location.search,
            },
          }}
        />
      ) : (
        <FetchViewer>
          {!fullscreen && <GlobalNav />}
          <SessionDetails Session={Session} />
          {children}
        </FetchViewer>
      )}
    </Route>
  );
}

export default GuardedRoute;
