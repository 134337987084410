export const APPROVED = "approved";
export const FINAL = "final";
export const SELECTED = "selected";
export const SHORTLIST = "shortlist";

export default {
  APPROVED,
  FINAL,
  SELECTED,
  SHORTLIST,
};
