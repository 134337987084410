import { forwardRef } from "react";
import HighlightTerm from "internal/shared/components/HighlightTerm";
import SearchTile, {
  IProps as ISearchTileProps,
} from "internal/system/components/SearchTile/SearchTile";
import IDS from "internal/system/constants/ids";
import { Link } from "react-router-dom";
import COLORS from "shared/constants/colors";
import FONTS from "shared/constants/fonts";
import styled from "styled-components";

export interface IProps extends ISearchTileProps {
  brandID: string;
  name: string;
  to: string;
}

export const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  padding: 1rem;
`;

export const Name = styled.div`
  font-weight: ${FONTS.WEIGHT.BOLD};
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

// Filter out `inactive` prop to avoid passing it to `Link`
export const StyledLink = styled(({ inactive, ...rest }) => (
  <Link {...rest} />
))<{
  inactive?: boolean;
}>`
  border-radius: 1px;
  box-shadow: 0 0 0 2px
    ${(props) => (props.inactive === false ? COLORS.BLUE : "transparent")};
  color: ${COLORS.WHITE};
  display: block;
  outline: none;
  opacity: ${(props) => (props.inactive === true ? 0.5 : 1)};
  text-decoration: none;
  transition: all 0.3s ease;
`;

const BrandSearchTile = forwardRef(
  (
    {
      brandID,
      className,
      id,
      imageURI,
      inactive,
      name,
      onClick,
      onMouseEnter,
      onMouseLeave,
      searchTerm,
      tabIndex,
      to,
    }: IProps,
    ref: React.MutableRefObject<HTMLAnchorElement>,
  ) => (
    <StyledLink
      className={className}
      id={id}
      inactive={inactive}
      innerRef={ref}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      tabIndex={tabIndex}
      to={to}
    >
      <SearchTile imageURI={imageURI}>
        <Container>
          <Name id={`${IDS.SEARCH_TILE.BRAND_NAME}-${brandID}`}>
            <HighlightTerm term={searchTerm} text={name} />
          </Name>
        </Container>
      </SearchTile>
    </StyledLink>
  ),
);

export default BrandSearchTile;
