import * as Amazon from "./Amazon";
import * as Display from "./Display";
import * as Facebook from "./Facebook";
import * as Instagram from "./Instagram";
import * as LinkedIn from "./LinkedIn";
import * as Pinterest from "./Pinterest";
import * as Reddit from "./Reddit";
import * as Snapchat from "./Snapchat";
import * as TikTok from "./TikTok";
import * as Twitter from "./Twitter";
import * as YouTube from "./YouTube";

export default {
  Amazon,
  Display,
  Facebook,
  Instagram,
  LinkedIn,
  Pinterest,
  Reddit,
  Snapchat,
  TikTok,
  Twitter,
  YouTube,
};
