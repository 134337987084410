import { gql } from "@apollo/client";

export const UnauthedVariantFields = gql`
  fragment UnauthedVariantFields on Variant {
    adUnit
    aspectRatio
    hash
    id
    language
    sequenceOrder
    thumbnailURI
    videoURI
    videoURIHDR
  }
`;

export const VariantFields = gql`
  fragment VariantFields on Variant {
    filename
    hidden
    transcoded
    type
    ...UnauthedVariantFields
  }
  ${UnauthedVariantFields}
`;

export default {
  UnauthedVariantFields,
  VariantFields,
};
