import { gql } from "@apollo/client";

export const ContentGroupFields = gql`
  fragment ContentGroupFields on ContentGroup {
    id
    name
  }
`;

export default {
  ContentGroupFields,
};
