import { Container as UploadGroupAction } from "internal/shared/components/uploads/UploadsGroupAction";
import { secondaryButtonStyles } from "shared/components/Button";
import Icon from "shared/components/Icon";
import COLORS from "shared/constants/colors";
import FONTS from "shared/constants/fonts";
import styled from "styled-components";
import { PER_STATUS_SETTINGS } from "./UploadsManager";

interface IUploadsExpandedProps {
  expanded?: boolean;
}

export const UploadGroup = styled.div`
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const UploadGroupInfo = styled.div`
  text-align: center;
  width: 100%;
`;

export const Groups = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

export const GroupIcon = styled<any>(Icon)`
  color: ${(props) =>
    props.count ? PER_STATUS_SETTINGS[props.status].color : COLORS.MID_GREY};
  display: block;
  height: 2rem;
  transition: all 0.5s ease-in-out;
  width: 2rem;
`;

export const UploadsContainer = styled.div<IUploadsExpandedProps>`
  background: ${(props) =>
    props.expanded ? COLORS.DARK_GREY : COLORS.LIGHT_GREY};
  border-radius: 0.25rem;
  bottom: ${(props) => (props.expanded ? "2rem" : "1rem")};
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.expanded ? "100%" : "auto")};
  padding: ${(props) => (props.expanded ? "2rem" : "1rem")};
  pointer-events: ${(props) => (props.expanded ? "auto" : "none")};
  width: ${(props) => (props.expanded ? "60rem" : "4rem")};

  ${Groups} {
    overflow: ${(props) => (props.expanded ? "auto" : "visible")};
  }

  ${UploadGroupInfo} {
    opacity: ${(props) => (props.expanded ? "0" : "1")};
    position: ${(props) => (props.expanded ? "absolute" : "static")};
    pointer-events: none;
    transform: ${(props) =>
      props.expanded ? "translateY(1rem)" : "translateY(0rem)"};
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    left: -9999rem;
    visibility: ${(props) => (props.expanded ? "hidden" : "visible")};
  }

  ${UploadGroupAction} {
    display: ${(props) => (props.expanded ? "block" : "none")};
  }
`;

export const ToggleButton = styled.button`
  height: 2rem;
  width: 2rem;
`;

export const Header = styled.div`
  display: flex;
  flex-grow: 0;
  font-weight: ${FONTS.WEIGHT.BOLD};
  margin: 0 0 1.5rem;

  > ${ToggleButton} {
    appearance: none;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: auto;
    padding: 0;

    ${Icon} {
      height: 2rem;
      width: 2rem;
    }

    &:hover {
      color: ${COLORS.RED};
    }
  }
`;

export const StyledHeading = styled.span`
  font-size: 1.5rem;
  vertical-align: bottom;
`;

export const DragPrompt = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  justify-content: center;
  pointer-events: none;
`;

export const StyledCloudIcon = styled(Icon)`
  color: ${COLORS.WHITE};
  display: block;
  height: 4rem;
  width: 4rem;
`;

export const AddVideoLinkButton = styled.button`
  ${secondaryButtonStyles}
  margin-left: 1rem;
`;
