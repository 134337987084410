export const isNewerVersion = (a: string, b: string) => {
  const aParts = a.split(".").map((part) => parseInt(part, 10));
  const bParts = b.split(".").map((part) => parseInt(part, 10));

  return (
    aParts[0] < bParts[0] || aParts[1] < bParts[1] || aParts[2] < bParts[2]
  );
};

export default isNewerVersion;
