import {
  ShowIfActive,
  StoriesContainer,
} from "shared/components/MobilePreviewPartners/shared";
import styled from "styled-components";
import { IProps } from "../types";
import {
  AppCta,
  Brand,
  CollectionCta,
  SnapAdCta,
  StoryAdCta,
} from "./elements";

// Stories
const StyledTopBar = styled.div`
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const StyledBottomBar = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
`;

export const BaseAd = ({
  brand,
  children,
  cta,
  logo,
  videoId,
}: {
  brand?: string;
  children: React.ReactNode;
  cta: React.ReactNode;
  logo?: string;
  videoId?: string;
}) => (
  <StoriesContainer>
    {children}
    <StyledTopBar>
      <Brand brand={brand} logo={logo} videoId={videoId} />
    </StyledTopBar>
    <StyledBottomBar>{cta}</StyledBottomBar>
  </StoriesContainer>
);

export const SnapAd = ({ brand, children, logo, videoId }: IProps) => (
  <BaseAd
    brand={brand}
    cta={<SnapAdCta brand={brand} videoId={videoId} />}
    logo={logo}
    videoId={videoId}
  >
    {children}
  </BaseAd>
);

export const StoryAd = ({
  brand,
  children,
  itemIndex,
  logo,
  videoId,
}: IProps) => (
  <BaseAd
    brand={brand}
    cta={<StoryAdCta brand={brand} videoId={videoId} />}
    logo={logo}
  >
    {Array.isArray(children)
      ? (children as any[]).map((child, index) => (
          <ShowIfActive active={itemIndex === index} key={child.key}>
            {child}
          </ShowIfActive>
        ))
      : children}
  </BaseAd>
);

export const AppAd = ({ brand, children, logo, videoId }: IProps) => (
  <BaseAd brand={brand} cta={<AppCta videoId={videoId} />} logo={logo}>
    {children}
  </BaseAd>
);

export const StoryAppAd = ({
  brand,
  children,
  itemIndex,
  logo,
  videoId,
}: IProps) => (
  <BaseAd brand={brand} cta={<AppCta videoId={videoId} />} logo={logo}>
    {Array.isArray(children)
      ? (children as any[]).map((child, index) => (
          <ShowIfActive active={itemIndex === index} key={child.key}>
            {child}
          </ShowIfActive>
        ))
      : children}
  </BaseAd>
);

export const CollectionAd = ({ brand, children, logo, videoId }: IProps) => (
  <BaseAd brand={brand} cta={<CollectionCta videoId={videoId} />} logo={logo}>
    {children}
  </BaseAd>
);
