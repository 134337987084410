import { HTMLAttributes, MouseEvent } from "react";
import FixedAspectRatio from "shared/components/FixedAspectRatio";
import COLORS from "shared/constants/colors";
import styled from "styled-components";

export interface IProps extends HTMLAttributes<HTMLDivElement> {
  imageURI: string;
  inactive?: boolean;
  onClick?: (e: MouseEvent) => void;
  onMouseEnter?: (e: MouseEvent) => void;
  onMouseLeave?: (e: MouseEvent) => void;
  searchTerm?: string;
}

export const Container = styled.div`
  align-items: stretch;
  background-color: ${COLORS.MID_GREY};
  border-radius: 0.25rem;
  display: flex;
`;

export const Content = styled.div`
  flex: 3;
  /* min-width required for truncated child content */
  min-width: 0;
`;

export const StyledFixedAspectRatio = styled(FixedAspectRatio)`
  height: 5rem;
  width: 5rem;
`;

export const SearchTile = ({ className, children, id, imageURI }: IProps) => (
  <Container className={className} id={id}>
    <Content>{children}</Content>
    <StyledFixedAspectRatio imageUri={imageURI} />
  </Container>
);

export default SearchTile;
