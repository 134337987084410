import useSignedUpload from "internal/shared/hooks/useSignedUpload";
import { IUpload } from "internal/shared/types/upload";
import { default as ItemProgressStatus } from "../../statuses/UploadItemProgress";

export interface IProps {
  onFailure: (reason: string) => void;
  onProgress: (amount: number) => void;
  onUpload: (key: string) => void;
  upload: IUpload;
}

function UploadItemProgress({
  onFailure,
  onProgress,
  onUpload,
  upload,
}: IProps) {
  useSignedUpload(upload, onProgress, onUpload, onFailure);
  return <ItemProgressStatus value={upload.uploadProgress} />;
}

export default UploadItemProgress;
