import { ShowIfActive } from "shared/components/MobilePreviewPartners/shared";
import styled from "styled-components";
import { IProps } from "../types";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Display = ({ children, itemIndex }: IProps) => (
  <Container>
    {Array.isArray(children)
      ? (children as any[]).map((child, index) => (
          <ShowIfActive active={itemIndex === index} key={child.key}>
            {child}
          </ShowIfActive>
        ))
      : children}
  </Container>
);
