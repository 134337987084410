import textualize from "internal/shared/utils/textualize";
import GlobalNav from "internal/system/components/GlobalNav";
import IDS from "internal/web/constants/ids";
import Session from "internal/web/services/Session";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import NotFoundContent from "shared/components/errors/NotFound";
import ANALYTICS from "shared/constants/analytics";
import EXTERNAL_LINKS from "shared/constants/externalLinks";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import sharedTextualize from "shared/utils/textualize";

const NotFound = () => {
  const links = Session.isAuthenticated() ? (
    <ul>
      <li>
        <Link
          id={IDS.NOT_FOUND.DASHBOARD}
          onClick={() =>
            fireAnalyticsEvent(
              ANALYTICS.CATEGORIES.DASHBOARD,
              ANALYTICS.EVENTS.NAVIGATED_TO_DASHBOARD,
            )
          }
          to="/"
        >
          {textualize("error.404.links.dashboard")}
        </Link>
      </li>
      <li>
        <Link id={IDS.NOT_FOUND.BRANDS} to={"/brands"}>
          {textualize("error.404.links.brands")}
        </Link>
      </li>
      <li>
        <Link id={IDS.NOT_FOUND.CREATORS} to="/creators">
          {textualize("error.404.links.creators")}
        </Link>
      </li>
      <li>
        <a
          href={EXTERNAL_LINKS.VIKIPEDIA}
          id={IDS.NOT_FOUND.VIKIPEDIA}
          rel="noopener noreferrer"
          target="_blank"
        >
          {textualize("error.404.links.vikipedia")}
        </a>
      </li>
    </ul>
  ) : null;

  return (
    <>
      <GlobalNav />
      <Helmet title={sharedTextualize("error.404.heading") as string} />
      <NotFoundContent links={links} />
    </>
  );
};

export default NotFound;
