import UPLOAD_STATUS from "internal/shared/constants/uploadStatuses";
import { Link } from "react-router-dom";
import Icon from "shared/components/Icon";
import COLORS from "shared/constants/colors";
import FONTS from "shared/constants/fonts";
import CheckIcon from "shared/images/icons/check.svg";
import CloudSmallIcon from "shared/images/icons/cloud-small.svg";
import WarningIcon from "shared/images/icons/warning.svg";
import styled, { css } from "styled-components";

interface IStatusProps {
  status: string;
}

interface IUploadsExpandedProps {
  expanded?: boolean;
}

export const STATUS_STYLES = {
  [UPLOAD_STATUS.FAILURE]: {
    background: COLORS.RED,
    color: COLORS.WHITE,
    icon: WarningIcon,
  },
  [UPLOAD_STATUS.PROCESSING]: {
    background: COLORS.YELLOW,
    color: COLORS.DARK_GREY,
    icon: CloudSmallIcon,
  },
  [UPLOAD_STATUS.UPLOADED]: {
    background: COLORS.YELLOW,
    color: COLORS.DARK_GREY,
    icon: CloudSmallIcon,
  },
  [UPLOAD_STATUS.COMPLETE]: {
    background: COLORS.GREEN,
    color: COLORS.DARK_GREY,
    icon: CheckIcon,
  },
};

export const VideoUploadsContainer = styled.div<IUploadsExpandedProps>`
  background: linear-gradient(135deg, #313134 0%, #242427 100%);
  border-radius: 0.25rem;
  display: flex;
  left: ${(props) => (props.expanded ? "-9999em" : "0")};
  margin-bottom: 0.5rem;
  opacity: ${(props) => (props.expanded ? "1" : "0")};
  overflow: hidden;
  position: ${(props) => (props.expanded ? "static" : "absolute")};
  transform: ${(props) =>
    props.expanded ? "translateY(0rem)" : "translateY(1rem)"};
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  visibility: ${(props) => (props.expanded ? "visible" : "hidden")};
`;

export const Status = styled.div<IStatusProps>`
  align-items: center;
  background: ${(props) => STATUS_STYLES[props.status].background};
  color: ${(props) => STATUS_STYLES[props.status].color};
  display: flex;
  justify-content: center;
  min-width: 3rem;

  ${Icon} {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const VideoUpload = styled.div`
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
`;

export const UploadName = styled.div`
  font-family: ${FONTS.FAMILY.MONOSPACE};
`;

export const UploadInfoWrapper = styled.div`
  font-family: ${FONTS.FAMILY.MONOSPACE};
  font-size: 0.75rem;
  margin-bottom: 0.5rem;

  > span {
    display: inline-block;
    margin-right: 1rem;
  }
`;

export const Controls = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: flex-end;
  width: 12rem;
`;

const ButtonStyles = css`
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  font-family: ${FONTS.FAMILY.BODY};
  font-size: 1rem;
  justify-content: center;
  padding: 0.75rem;
  text-align: center;
  text-transform: uppercase;
  width: 50%;
`;

export const ControlsButton = styled.button`
  ${ButtonStyles}
  &:hover {
    color: ${COLORS.RED};
  }

  ${Icon} {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const CancelButton = styled(ControlsButton)`
  background: ${COLORS.WHITE};
  color: ${COLORS.DARK_GREY};
`;

export const ConfirmButton = styled(ControlsButton)`
  background: ${COLORS.RED};
  color: ${COLORS.WHITE};
  &:hover {
    border: 1px solid ${COLORS.WHITE};
    color: ${COLORS.WHITE};
  }
`;

export const DeleteButton = styled(ControlsButton)`
  &:hover {
    color: ${COLORS.RED};
  }

  ${Icon} {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const PlayLink = styled(Link)`
  ${ButtonStyles}
  &:hover {
    color: ${COLORS.RED};
  }

  ${Icon} {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

interface IReplaceTagProps {
  replace?: boolean;
}

export const ReplaceTag = styled.span<IReplaceTagProps>`
  background: ${(props) => (props.replace ? COLORS.BLUE : COLORS.GREEN)};
  border-radius: 0.125rem;
  color: ${(props) => (props.replace ? COLORS.WHITE : COLORS.DARK_GREY)};
  display: inline-block;
  font-family: ${FONTS.FAMILY.MONOSPACE};
  font-size: 0.625rem;
  line-height: 1rem;
  margin: 0.25rem 0;
  min-width: 3rem;
  padding: 0 0.25rem;
  text-align: center;
  user-select: none;
`;
