import { Accept } from "react-dropzone";

export const ACCEPTED_MIME_TYPES: Accept = {
  "video/mp4": [".mp4"],
  "video/quicktime": [".mov"],
};
export const MAX_SIZE_BYTES = 209715200;
export const MAX_SIZE_MB = parseInt(
  (MAX_SIZE_BYTES / 1024 / 1024).toFixed(4),
  0,
);
