import {
  InfeedContainerLight,
  Scrollable,
} from "shared/components/MobilePreviewPartners/shared";
import ASPECT_RATIOS from "shared/constants/aspectRatios";
import styled from "styled-components";
import { IProps } from "../types";
import {
  BottomBar,
  LikeIcon,
  NextPosts,
  Pagination,
  PhoneTop,
  PostsBottom,
  PostsHeader,
  ProductButton,
  ShareIcon,
  SponsoredBrandBottom,
  SponsoredBrandOverlaySound,
  TitleBar,
} from "./elements";

const Row = styled.div`
  position: relative;
  margin: -0.875rem 0 5rem;
`;

const StyledPostsBottom = styled(PostsBottom)`
  position: relative;
  top: -2.5%;
`;

const AmazonPosts = ({ brand, children, logo, videoId }: IProps) => (
  <InfeedContainerLight>
    <Scrollable tabIndex={0}>
      <PostsHeader brand={brand} logo={logo} videoId={videoId} />
      {children}
      <StyledPostsBottom />
    </Scrollable>
  </InfeedContainerLight>
);

const StyledBottomBar = styled.div`
  bottom: 0;
  position: sticky;
`;

const StyledLikeIcon = styled(LikeIcon)`
  left: 0.875rem;
  position: absolute;
  top: 0;
`;

const StyledPagination = styled.div`
  margin: 0 auto 1.5rem;
`;

const StyledProductButton = styled(ProductButton)`
  position: absolute;
  right: 0;
  top: 0;
`;

const StyledShareIcon = styled(ShareIcon)<{ aspectRatio: string }>`
  position: absolute;
  top: ${({ aspectRatio }) =>
    aspectRatio === ASPECT_RATIOS.SIXTEEN_BY_NINE ? "-1.5rem" : "-0.75rem"};
`;

const StyledVideo = styled.div`
  position: relative;
  margin-top: -2.25rem;
  padding: 0.5rem;
`;

const AmazonProductDetail = ({ aspectRatio, brand, children }: IProps) => (
  <InfeedContainerLight>
    <Scrollable tabIndex={0}>
      <PhoneTop />
      <TitleBar brand={brand} />
      <StyledVideo>
        <StyledShareIcon aspectRatio={aspectRatio!} />
        {children}
      </StyledVideo>
      <Row>
        <StyledLikeIcon />
        <StyledProductButton />
      </Row>
      <StyledPagination>
        <Pagination />
      </StyledPagination>
      <NextPosts />
      <StyledBottomBar>
        <BottomBar />
      </StyledBottomBar>
    </Scrollable>
  </InfeedContainerLight>
);

const SponsoredBrandOverlayContainer = styled.div`
  padding: 0.5rem 0.5rem 0;
  position: relative;
`;

const StyledSponsoredBrandOverlaySound = styled(SponsoredBrandOverlaySound)`
  bottom: 0;
  position: absolute;
  right: 0;
`;

const StyledSponsoredBrandBottom = styled(SponsoredBrandBottom)`
  padding: 0 0.5rem;
`;

const AmazonSponsoredBrands = ({ children, isMuted }: IProps) => (
  <InfeedContainerLight>
    <Scrollable tabIndex={0}>
      <PhoneTop />
      <SponsoredBrandOverlayContainer>
        {children}
        <StyledSponsoredBrandOverlaySound isMuted={isMuted} />
      </SponsoredBrandOverlayContainer>
      <StyledSponsoredBrandBottom />
      <StyledBottomBar>
        <BottomBar />
      </StyledBottomBar>
    </Scrollable>
  </InfeedContainerLight>
);

export { AmazonPosts, AmazonProductDetail, AmazonSponsoredBrands };
