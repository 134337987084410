import {
  cache,
  link,
  resolvers,
  typeDefs,
} from "internal/web/services/GraphQL/graphql-api";
import getClient from "shared/clients/ApolloClient";

export const client = getClient({
  cache,
  devtools: {
    name: "graphql-api",
  },
  link,
  resolvers,
  typeDefs,
});

export default client;
