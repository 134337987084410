export const CLIENT_VIEW = "client-view";
export const CONCEPT = "concept";
export const CREATOR_NAME = "creator-name";
export const RECENT = "recent";
export const SHORT_HASH = "short-hash";

export default {
  CLIENT_VIEW,
  CONCEPT,
  CREATOR_NAME,
  RECENT,
  SHORT_HASH,
};
