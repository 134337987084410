import UPLOAD from "internal/shared/constants/actions/upload";
import { IUpload, IUploadState } from "internal/shared/types/upload";
import { IAction } from "shared/types/actions";

function uploadsReducer(state: IUploadState, action: IAction): IUploadState {
  switch (action.type) {
    case UPLOAD.ADD:
      return {
        ...state,
        uploads: [
          ...state.uploads,
          {
            complete: false,
            processed: false,
            uploadProgress: 0,
            ...action.payload,
          },
        ].sort((a: IUpload, b: IUpload) =>
          a.filename.localeCompare(b.filename),
        ),
      };
    case UPLOAD.COMPLETE:
      return {
        ...state,
        uploads: state.uploads.map((upload) => {
          if (upload.id === action.payload.id) {
            // replace our placeholder upload values with the data from
            // the processed variant, including the temp ID we created
            return {
              ...upload,
              complete: true,
            };
          }
          return upload;
        }),
      };
    case UPLOAD.FAILURE:
      return {
        ...state,
        uploads: state.uploads.map((upload) => {
          if (upload.id === action.payload.id) {
            return { ...upload, failure: action.payload.reason };
          }
          return upload;
        }),
      };
    case UPLOAD.PROCESSED:
      return {
        ...state,
        uploads: state.uploads.map((upload) => {
          if (upload.id === action.payload.id) {
            // replace our placeholder upload values with the data from
            // the processed variant, including the temp ID we created
            return {
              ...upload,
              processed: true,
              ...action.payload.variant,
            };
          }
          return upload;
        }),
      };
    case UPLOAD.REMOVE:
      return {
        ...state,
        uploads: state.uploads.filter(
          (upload) => upload.id !== action.payload.id,
        ),
      };
    case UPLOAD.SET_PRESIGNED_URI:
      return {
        ...state,
        uploads: state.uploads.map((upload) => {
          if (upload.id === action.payload.id) {
            return { ...upload, presignedURI: action.payload.presignedURI };
          }
          return upload;
        }),
      };
    case UPLOAD.SET_UPLOAD_PROGRESS:
      return {
        ...state,
        uploads: state.uploads.map((upload) => {
          if (upload.id === action.payload.id) {
            return { ...upload, uploadProgress: action.payload.uploadProgress };
          }
          return upload;
        }),
      };
    case UPLOAD.SET_UPLOAD_KEY:
      return {
        ...state,
        uploads: state.uploads.map((upload) => {
          if (upload.id === action.payload.id) {
            return { ...upload, uploadKey: action.payload.key };
          }
          return upload;
        }),
      };
    case UPLOAD.SET_VIDEO_ID:
      return {
        ...state,
        videoID: action.payload,
      };
    case UPLOAD.SET_VIDEO_PLACEHOLDER:
      return {
        ...state,
        uploads: state.uploads.map((upload) => {
          if (upload.id === action.payload.id) {
            return {
              ...upload,
              placeholderVideo: action.payload.placeholderVideo,
            };
          }
          return upload;
        }),
      };
    case UPLOAD.SET_IS_EXISTING_VARIANT:
      return {
        ...state,
        uploads: state.uploads.map((upload) => {
          if (upload.id === action.payload.id) {
            return {
              ...upload,
              isExistingVariant: action.payload.isExistingVariant,
            };
          }
          return upload;
        }),
      };
    case UPLOAD.SUBMISSION_COMPLETE:
      return {
        ...state,
        submissionComplete: true,
      };
    default:
      throw new Error();
  }
}

export default uploadsReducer;
