export default {
  CATEGORIES: {
    DASHBOARD: "Dashboard",
    SHOWREEL: "Showreel",
    UPLOADER: "Uploader",
    VIDEO_PLAYER: "VideoPlayer",
  },
  EVENTS: {
    AD_UNIT_CHANGED: "AdUnitChanged",
    AD_UNIT_SELECTED: "AdUnitSelected",
    ASPECT_RATIO_SELECTED: "AspectRatioSelected",
    CHANGE_PIN_ERROR: "ChangePinError",
    CHANGE_PIN_SUCCESS: "ChangePinSuccess",
    LANGUAGE_SELECTED: "LanguageSelected",
    UPLOAD_VIDEO_DELETED: "UploadVideoDeleted",
    UPLOAD_VIDEO_ERROR: "UploadVideoError",
    UPLOAD_VIDEO_SUCCESS: "UploadVideoSuccess",
  },
};
