import { useRouteMatch } from "react-router-dom";
import SharedSqueezedError, {
  IProps,
} from "shared/components/errors/SqueezedError/SqueezedError";

const SqueezedError = ({ children }: IProps) => {
  const match = useRouteMatch({
    exact: true,
    path: "/login/callback",
  });

  if (match) {
    // Login callback window does not show content
    return <>{children}</>;
  }

  return <SharedSqueezedError>{children}</SharedSqueezedError>;
};

export default SqueezedError;
