import {
  Mutation as BriefMutations,
  Type as BriefType,
} from "./resolvers/Brief";
import {
  Mutation as ProjectMutations,
  Type as ProjectType,
} from "./resolvers/Project";
import { Type as VideoType } from "./resolvers/Video";

const resolvers = {
  ...BriefType,
  Mutation: {
    ...BriefMutations,
    ...ProjectMutations,
  },
  ...ProjectType,
  ...VideoType,
};

export default resolvers;
