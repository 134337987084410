import { useQuery } from "@apollo/client";
import {
  BrandFieldsFragment as IBrand,
  GetBrandsOnlyMegaSearchDataQuery as IGetBrandsOnlyMegaSearchData,
  GetMegaSearchDataQuery as IGetMegaSearchData,
  MegaSearchBriefFieldsFragment as IBrief,
} from "internal/shared/types/graphql";
import MegaSearch from "internal/system/components/MegaSearch";
import {
  GET_BRANDS_ONLY_MEGA_SEARCH_DATA,
  GET_MEGA_SEARCH_DATA,
} from "internal/system/queries/GetMegaSearchData";
import debounce from "lodash.debounce";
import { HTMLAttributes, useState } from "react";

export interface IProps extends HTMLAttributes<HTMLDivElement> {
  id: string;
}

export const MegaSearchContainer = ({ className, id }: IProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSetSearchTerm = debounce(setSearchTerm, 300);

  // Debounce searching
  const onSearchTermChange = (term: string) => {
    debouncedSetSearchTerm(term);
  };

  let brands: IBrand[] = [];
  let briefs: IBrief[] = [];

  let hasMoreBrands = false;
  let hasMoreBriefs = false;

  let loading;
  let error;

  const {
    data: brandsOnlyData,
    loading: brandsOnlyLoading,
    error: brandsOnlyError,
  } = useQuery<IGetBrandsOnlyMegaSearchData>(GET_BRANDS_ONLY_MEGA_SEARCH_DATA, {
    skip: searchTerm.length !== 2,
    variables: { searchTerm },
  });

  const {
    data: megaSearchData,
    loading: megaSearchLoading,
    error: megaSearchError,
  } = useQuery<IGetMegaSearchData>(GET_MEGA_SEARCH_DATA, {
    skip: searchTerm.length < 3,
    variables: { searchTerm },
  });

  if (searchTerm.length === 2) {
    // Brands
    loading = brandsOnlyLoading;
    error = brandsOnlyError;

    if (!loading && !error) {
      hasMoreBrands = brandsOnlyData?.brands?.pageInfo?.hasNextPage ?? false;

      const brandsData = brandsOnlyData?.brands.edges;

      brands = brandsData?.map((brandData) => brandData.node) || [];
    }
  } else if (searchTerm.length > 2) {
    // Brands and briefs
    loading = megaSearchLoading;
    error = megaSearchError;

    if (!loading && !error) {
      hasMoreBrands = megaSearchData?.brands?.pageInfo?.hasNextPage ?? false;
      hasMoreBriefs = megaSearchData?.briefs?.pageInfo?.hasNextPage ?? false;

      const brandsData = megaSearchData?.brands.edges;
      const briefsData = megaSearchData?.briefs.edges;

      brands = brandsData?.map((brandData) => brandData.node) || [];
      briefs = briefsData?.map((briefData) => briefData.node) || [];
    }
  }

  return (
    <MegaSearch
      brands={searchTerm.length < 2 ? undefined : brands}
      briefs={searchTerm.length < 3 ? undefined : briefs}
      className={className}
      error={error}
      hasMoreBrands={hasMoreBrands}
      hasMoreBriefs={hasMoreBriefs}
      id={id}
      loading={loading}
      onSearchTermChange={onSearchTermChange}
    />
  );
};

export default MegaSearchContainer;
