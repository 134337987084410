import { useCallback, useLayoutEffect, useRef, useState } from "react";
import COLORS from "shared/constants/colors";
import copyToClipboard from "shared/utils/copyToClipboard";
import styled from "styled-components";

interface IProps {
  children: React.ReactNode;
  className?: string;
  onCopy?: () => void;
}

export const CopyableText = styled.span`
  &:hover {
    color: ${COLORS.RED};
    cursor: pointer;
  }
`;

const CopyableContent = ({ children, className, onCopy }: IProps) => {
  const textRef = useRef<HTMLDivElement>(null);

  const [currentText, setCurrentText] = useState("");

  // As there is a chance that textRef has changed post render
  // of this component inputRef might have a stale value.
  // To ensure we don't use this stale value we listen for a change
  // in children and, after layout, update state to reflect the
  // new innerText value that we now have.
  useLayoutEffect(() => {
    setCurrentText(textRef.current?.textContent || "");
  }, [children]);

  const copyContent = useCallback(async () => {
    await copyToClipboard(currentText);

    if (onCopy) {
      onCopy();
    }
  }, [currentText, onCopy]);

  return (
    <CopyableText
      aria-label={`Copy ${currentText}`}
      className={className}
      onClick={copyContent}
      ref={textRef}
      role="button"
    >
      {children}
    </CopyableText>
  );
};

export default CopyableContent;
