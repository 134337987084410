export const COMPLETE = "COMPLETE";
export const FAILURE = "FAILURE";
export const PROCESSING = "PROCESSING";
export const PROGRESS = "PROGRESS";
export const UPLOADED = "UPLOADED";

export default {
  COMPLETE,
  FAILURE,
  PROCESSING,
  PROGRESS,
  UPLOADED,
};
