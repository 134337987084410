import { InfeedContainerLight } from "shared/components/MobilePreviewPartners/shared";
import { IProps } from "../types";
import { BottomBar, ScrollableContent, TopBar } from "./elements";
import {
  StyledBottomSection,
  StyledBrandAndLogo,
  StyledContainer,
  StyledDiv,
} from "./styles";

const RedditPromotedPost = ({ brand, children, logo, videoId }: IProps) => (
  <InfeedContainerLight>
    <TopBar brand={brand} logo={logo} videoId={videoId} />
    <StyledContainer tabIndex={0}>
      <StyledBrandAndLogo brand={brand} logo={logo} videoId={videoId} />
      <StyledDiv>{children}</StyledDiv>
      <StyledBottomSection />
      <ScrollableContent />
      <StyledBottomSection />
    </StyledContainer>
    <BottomBar />
  </InfeedContainerLight>
);

export { RedditPromotedPost };
