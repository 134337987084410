import { gql } from "@apollo/client";

export const BrandContactFields = gql`
  fragment BrandContactFields on Brand {
    contacts {
      email
      displayName
      id
      jobTitle
    }
  }
`;

export const BrandFields = gql`
  fragment BrandFields on Brand {
    id
    imageURI
    name
  }
`;

export default {
  BrandFields,
};
