import { gql } from "@apollo/client";

export const ViewOptions = gql`
  fragment ViewOptions on ViewOptions {
    filter
    sort
  }
`;

export default {
  ViewOptions,
};
