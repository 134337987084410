import styled from "styled-components";
import { BottomSection, BrandAndLogo } from "./elements";

export const StyledContainer = styled.div`
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  scrollbar-width: none;

  > * {
    flex: 0 0 auto;
  }
`;

export const StyledDiv = styled.div`
  border-radius: 1.25rem;
  margin: 0 4%;
  overflow: hidden;
`;

export const StyledBrandAndLogo = styled(BrandAndLogo)`
  margin: 2% 4%;
`;

export const StyledBottomSection = styled(BottomSection)`
  margin: 1.5% 4%;
`;
