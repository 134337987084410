import { gql } from "@apollo/client";

export const TaxonFields = gql`
  fragment TaxonFields on Taxon {
    id
    count
    label
  }
`;

export const TaxonomyFields = gql`
  fragment TaxonomyFields on Taxonomy {
    id
    values {
      ...TaxonFields
    }
  }
  ${TaxonFields}
`;

export const VideoTaxonomiesFields = gql`
  fragment VideoTaxonomiesFields on Taxonomies {
    videos(input: $input) {
      brand {
        id
        values {
          ...TaxonFields
        }
      }
      briefType {
        id
        values {
          ...TaxonFields
        }
      }
      durationRange {
        id
        values {
          ...TaxonFields
        }
      }
      framework {
        id
        values {
          ...TaxonFields
        }
      }
      industry {
        id
        values {
          ...TaxonFields
        }
      }
      misc {
        id
        values {
          ...TaxonFields
        }
      }
      placement {
        id
        values {
          ...TaxonFields
        }
      }
      visualTechnique {
        id
        values {
          ...TaxonFields
        }
      }
    }
  }
  ${TaxonFields}
  ${TaxonomyFields}
`;

export default {
  TaxonFields,
  VideoTaxonomiesFields,
};
