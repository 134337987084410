import { lazy } from "react";

const BriefingForm = lazy(() => import("./pages/BriefingForm"));

export default {
  name: "briefing",
  routes: [
    {
      authRequired: true,
      component: BriefingForm,
      exact: true,
      path: "/:briefShortHash/:type?",
    },
  ],
};
