export default {
  CATEGORIES: {
    BRANDS: "Brands",
    CREATOR_MANAGEMENT: "CreatorManagement",
    DASHBOARD: "Dashboard",
    DOWNLOADS: "Downloads",
    LOGIN: "Login",
    MEGA_SEARCH: "MegaSearch",
    PROJECTS: "Projects",
  },
  EVENTS: {
    BRAND_CLICKED: "BrandClicked",
    BATCH_DOWNLOAD_ERROR: "BatchDownloadError",
    BATCH_DOWNLOAD_SUCCESS: "BatchDownloadSuccess",
    BRIEF_STATUS_LINK_CLICKED: "BriefStatusLinkClicked",
    COMPLETED_BRIEFS_MORE_LOADED: "CompletedBriefsMoreLoaded",
    COMPLETED_PROJECTS_SORTED: "CompletedProjectsSorted",
    LOGIN_ERROR: "LoginError",
    LOGIN_SUCCESS: "LoginSuccess",
    NEXT_PAGE: "NextPage",
    OPEN_BRIEFS_MORE_LOADED: "OpenBriefsMoreLoaded",
    OPEN_PROJECTS_SORTED: "OpenProjectsSorted",
    PAYMENTS_SAVED: "PaymentsSaved",
    PREVIOUS_PAGE: "PreviousPage",
    SEARCH_PERFORMED: "SearchPerformed",
    SEARCH_RESULT_CLICKED: "SearchResultClicked",
    VIEW_ALL_BRIEFS_TOGGLED: "ViewAllBriefsToggled",
  },
};
