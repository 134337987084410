import {
  InfeedContainer,
  Scrollable,
  StoriesContainer,
} from "shared/components/MobilePreviewPartners/shared";
import ASPECT_RATIOS from "shared/constants/aspectRatios";
import styled from "styled-components";
import { IProps } from "../types";
import {
  BumperBottomOverlaySixteenByNine,
  BumperOverlayBottom,
  Information,
  OverlayTop,
  ShortsBottomBar,
  ShortsTopBar,
  SixteenByNineOverlayTop,
  TrueViewForActionInformation,
  TrueviewOverlayBottom,
  TrueviewSixteenByNineOverlayBottom,
} from "./elements";

const OverlayContainer = styled.div`
  position: relative;
`;

const StyledOverlayTop = styled(OverlayTop)`
  position: absolute;
  top: 0;
`;

const StyledBumperOverlayBottom = styled(BumperOverlayBottom)`
  bottom: 0;
  position: absolute;
`;

const StyledBumperBottomSixteenByNineOverlay = styled(
  BumperBottomOverlaySixteenByNine,
)`
  bottom: 0;
  position: absolute;
`;

const StyledShortsBottomBar = styled(ShortsBottomBar)`
  bottom: 0;
  position: absolute;
`;

const StyledShortsTopBar = styled(ShortsTopBar)`
  position: absolute;
  top: 0;
`;

const StyledSixteenByNineOverlayTop = styled(SixteenByNineOverlayTop)`
  position: absolute;
  top: 0;
`;

const StyledTrueviewSixteenByNineOverlayBottom = styled(
  TrueviewSixteenByNineOverlayBottom,
)`
  bottom: 0;
  position: absolute;
`;

const StyledTrueViewOverlayBottom = styled(TrueviewOverlayBottom)`
  bottom: 0;
  position: absolute;
`;

const StyledTrueViewForActionInformation = styled(TrueViewForActionInformation)`
  background: #212121;
`;

export const YouTubeBumperSixteenByNine = ({
  brand,
  caption,
  children,
  logo,
  videoId,
  videoTimes,
}: IProps) => (
  <InfeedContainer>
    <Scrollable tabIndex={0}>
      <OverlayContainer>
        {children}
        <StyledSixteenByNineOverlayTop />
        <StyledBumperBottomSixteenByNineOverlay videoTimes={videoTimes} />
      </OverlayContainer>
      <Information
        brand={brand}
        caption={caption}
        logo={logo}
        videoId={videoId}
      />
    </Scrollable>
  </InfeedContainer>
);

export const YouTubeBumper = ({
  aspectRatio,
  brand,
  caption,
  children,
  logo,
  videoId,
  videoTimes,
}: IProps) =>
  aspectRatio === ASPECT_RATIOS.SIXTEEN_BY_NINE ? (
    <YouTubeBumperSixteenByNine
      brand={brand}
      caption={caption}
      logo={logo}
      videoId={videoId}
      videoTimes={videoTimes}
    >
      {children}
    </YouTubeBumperSixteenByNine>
  ) : aspectRatio === ASPECT_RATIOS.NINE_BY_SIXTEEN ? (
    <InfeedContainer>
      <Scrollable tabIndex={0}>
        <OverlayContainer>
          {children}
          <StyledOverlayTop />
          <StyledBumperOverlayBottom
            isNineBySixteen={true}
            videoTimes={videoTimes}
          />
        </OverlayContainer>
        <Information
          brand={brand}
          caption={caption}
          logo={logo}
          videoId={videoId}
        />
      </Scrollable>
    </InfeedContainer>
  ) : (
    <InfeedContainer>
      <Scrollable tabIndex={0}>
        <OverlayContainer>
          {children}
          <StyledOverlayTop />
          <StyledBumperOverlayBottom
            isNineBySixteen={false}
            videoTimes={videoTimes}
          />
        </OverlayContainer>
        <Information
          brand={brand}
          caption={caption}
          logo={logo}
          videoId={videoId}
        />
      </Scrollable>
    </InfeedContainer>
  );

export const YouTubeShorts = ({ brand, children, logo, videoId }: IProps) => (
  <StoriesContainer>
    {children}
    <StyledShortsTopBar />
    <StyledShortsBottomBar brand={brand} logo={logo} videoId={videoId} />
  </StoriesContainer>
);

export const YouTubeTrueViewSixteenByNine = ({
  brand,
  caption,
  children,
  isForAction,
  logo,
  videoId,
  videoTimes,
}: IProps) => (
  <InfeedContainer>
    <Scrollable tabIndex={0}>
      <OverlayContainer>
        {children}
        <StyledSixteenByNineOverlayTop />
        <StyledTrueviewSixteenByNineOverlayBottom
          isForAction={isForAction}
          videoTimes={videoTimes}
        />
      </OverlayContainer>
      <Information
        brand={brand}
        caption={caption}
        logo={logo}
        videoId={videoId}
      />
    </Scrollable>
  </InfeedContainer>
);

export const YouTubeTrueView = ({
  aspectRatio,
  brand,
  caption,
  children,
  logo,
  videoId,
  videoTimes,
}: IProps) =>
  aspectRatio === ASPECT_RATIOS.NINE_BY_SIXTEEN ? (
    <InfeedContainer>
      <Scrollable tabIndex={0}>
        <OverlayContainer>
          {children}
          <StyledOverlayTop />
          <StyledTrueViewOverlayBottom
            isNineBySixteen={true}
            videoTimes={videoTimes}
          />
        </OverlayContainer>
        <Information
          brand={brand}
          caption={caption}
          logo={logo}
          videoId={videoId}
        />
      </Scrollable>
    </InfeedContainer>
  ) : aspectRatio === ASPECT_RATIOS.SIXTEEN_BY_NINE ? (
    <YouTubeTrueViewSixteenByNine
      brand={brand}
      caption={caption}
      isForAction={false}
      logo={logo}
      videoId={videoId}
      videoTimes={videoTimes}
    >
      {children}
    </YouTubeTrueViewSixteenByNine>
  ) : (
    <InfeedContainer>
      <Scrollable tabIndex={0}>
        <OverlayContainer>
          {children}
          <StyledOverlayTop />
          <StyledTrueViewOverlayBottom
            isNineBySixteen={false}
            videoTimes={videoTimes}
          />
        </OverlayContainer>
        <Information
          brand={brand}
          caption={caption}
          logo={logo}
          videoId={videoId}
        />
      </Scrollable>
    </InfeedContainer>
  );
export const YouTubeTrueViewForAction = ({
  aspectRatio,
  brand,
  caption,
  children,
  logo,
  videoId,
  videoTimes,
}: IProps) =>
  aspectRatio === ASPECT_RATIOS.SIXTEEN_BY_NINE ? (
    <YouTubeTrueViewSixteenByNine
      brand={brand}
      caption={caption}
      isForAction={true}
      logo={logo}
      videoId={videoId}
      videoTimes={videoTimes}
    >
      {children}
    </YouTubeTrueViewSixteenByNine>
  ) : (
    <InfeedContainer>
      <Scrollable tabIndex={0}>
        <OverlayContainer>
          {children}

          <StyledTrueViewOverlayBottom videoTimes={videoTimes} />
        </OverlayContainer>
        <StyledTrueViewForActionInformation
          brand={brand}
          caption={caption}
          logo={logo}
          videoId={videoId}
        />
      </Scrollable>
    </InfeedContainer>
  );
