import { StoriesContainer } from "shared/components/MobilePreviewPartners/shared";
import styled from "styled-components";
import { IProps } from "../types";
import { Brand, TopBar } from "./elements";

// Stories
const StyledTopBar = styled.div`
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const StyledBottomBar = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
`;

export const TikTokInfeed = ({
  brand,
  caption,
  children,
  logo,
  videoId,
}: IProps) => (
  <StoriesContainer>
    {children}
    <StyledTopBar>
      <TopBar />
    </StyledTopBar>
    <StyledBottomBar>
      <Brand brand={brand} caption={caption} logo={logo} videoId={videoId} />
      {/* <BottomBar /> TODO: Restore when aspect ratio of video can be preserved */}
    </StyledBottomBar>
  </StoriesContainer>
);

export const TikTokTopView = ({
  brand,
  caption,
  children,
  logo,
  videoId,
}: IProps) => {
  return (
    <StoriesContainer>
      {children}
      <StyledTopBar>
        <TopBar />
      </StyledTopBar>
      <StyledBottomBar>
        <Brand brand={brand} caption={caption} logo={logo} videoId={videoId} />
        {/* <BottomBar /> TODO: Restore when aspect ratio of video can be preserved */}
      </StyledBottomBar>
    </StoriesContainer>
  );
};
