import useVideoDropzone from "internal/shared/hooks/useVideoDropzone";
import textualize from "internal/shared/utils/textualize";
import { createContext, useCallback, useEffect, useState } from "react";
import { DropzoneRootProps } from "react-dropzone";
import Icon from "shared/components/Icon";
import COLORS from "shared/constants/colors";
import CloudIcon from "shared/images/icons/cloud.svg";
import styled from "styled-components";

export interface IDropzoneProps {
  addUploads: (files: File[], failure?: string) => void;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  id?: string;
  onUploadStart?: () => void;
}

export interface IDropzoneContainerProps {
  isDragActive?: boolean;
}

const DropzoneContainer = styled.div<IDropzoneContainerProps>`
  display: block;
  outline: none;
`;

const DropzoneArea = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
  position: fixed;
  left: 0;
  height: 100vh;
  right: 0;
  top: 0;
  z-index: 100;
`;

const StyledCloudIcon = styled(Icon)`
  color: ${COLORS.WHITE};
  display: block;
  height: 4rem;
  margin-bottom: 2rem;
  width: 4rem;
`;

interface IVideoUploadsContext {
  pickUploads: (e: React.MouseEvent) => void;
}

export const VideoUploadsContext = createContext<IVideoUploadsContext>({
  pickUploads: () => null,
});

function GlobalVideoDropzone({
  addUploads,
  children,
  className,
  disabled = false,
  id,
  onUploadStart,
}: IDropzoneProps) {
  const { getRootProps, isDragActive, getInputProps } = useVideoDropzone({
    addUploads,
    disabled,
    onUploadStart,
  });

  const isEmpty = !children;

  const allProps = {
    ...getRootProps<DropzoneRootProps>({ className, isDragActive, isEmpty }),
  };

  const outerProps = {
    ...allProps,
    onClick: undefined,
  };

  const pickUploads = useCallback((e: any) => {
    if (allProps.onClick) {
      allProps.onClick(e);
    }
    // TODO: Investigate this hook issue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [videoUploadsContextState, setVideoUploadsContextState] = useState({
    pickUploads,
  });

  useEffect(() => {
    setVideoUploadsContextState({
      pickUploads,
    });
  }, [pickUploads]);

  return (
    <>
      <DropzoneContainer {...outerProps}>
        <VideoUploadsContext.Provider value={videoUploadsContextState}>
          {children}
        </VideoUploadsContext.Provider>
        <input
          {...getInputProps({
            "aria-label": textualize("upload.dropzone.inputLabel") as string,
          })}
        />
      </DropzoneContainer>
      {isDragActive && (
        <DropzoneArea id={id}>
          <StyledCloudIcon component={CloudIcon} />
          {textualize("upload.dropzone.activePrompt")}
        </DropzoneArea>
      )}
    </>
  );
}

export default GlobalVideoDropzone;
