import { ApolloError } from "@apollo/client";
import { useMemo } from "react";
import Button from "shared/components/Button";
import CrashReporterBoundary from "shared/components/CrashReporterBoundary";
import { ErrorThrower } from "shared/components/errors/ModuleError";
import Application from "shared/types/application";
import isApp from "shared/utils/isApp";
import textualize from "shared/utils/textualize";
import styled from "styled-components";

interface IDataProps {
  error: ApolloError;
}

const ModuleError = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  margin: 1rem 0;

  ${Button} {
    margin-top: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }
`;

export const DataError = ({ error }: IDataProps) => {
  const traceID = error.graphQLErrors.find((err) => err.extensions?.traceID)
    ?.extensions?.traceID;

  const DataErrorMessage = useMemo(
    () => () => (
      <ModuleError>
        <p>{textualize("error.module.dataError")}</p>
        {!!traceID && !isApp(Application.Showreel) && (
          <p>{textualize("error.module.slackPrompt", { traceID })}</p>
        )}
        <Button onClick={() => window.location.reload()}>
          {textualize("error.module.reloadPage")}
        </Button>
      </ModuleError>
    ),
    [traceID],
  );

  return (
    <CrashReporterBoundary fallback={DataErrorMessage}>
      <ErrorThrower error={error} />
    </CrashReporterBoundary>
  );
};

export default DataError;
