import COLORS from "shared/constants/colors";
import styled, { css, keyframes } from "styled-components";

export interface IProps {
  backgroundColor?: string;
  className?: string;
  color?: string;
  id?: string;
  indeterminate?: boolean;
  max?: number;
  min?: number;
  showValue?: boolean;
  value: number;
  valueId?: string;
}

const bounce = keyframes`
  0% {
    left: -20%;
  }

  100% {
    left: 100%;
  }
`;

export const Container = styled.div`
  font-size: 1rem;
  position: relative;
`;

export const Track = styled.div`
  height: 0.25rem;
  position: relative;
  overflow: hidden;
  width: 100%;
`;

const indeterminateAnimation = css`
  animation: ${bounce} 1s linear infinite;
`;

export const Progress = styled.div<Partial<IProps>>`
  ${(props) => (props.indeterminate ? indeterminateAnimation : null)}
  height: 100%;
  position: absolute;
  transition: width 0.3s ease;
`;

export const HorizontalProgress = ({
  backgroundColor = COLORS.MID_GREY,
  className,
  color = COLORS.WHITE,
  id,
  indeterminate,
  max = 1,
  min = 0,
  value,
}: IProps) => {
  return (
    <Container
      aria-valuemax={max}
      aria-valuemin={min}
      aria-valuenow={!indeterminate ? value : undefined}
      className={className}
      id={id}
      role="progressbar"
    >
      <Track
        style={{
          backgroundColor,
        }}
      >
        <Progress
          indeterminate={indeterminate}
          style={{
            backgroundColor: color,
            width: !indeterminate ? `${value * 100}%` : "20%",
          }}
        />
      </Track>
    </Container>
  );
};

export default HorizontalProgress;
