import SearchBar from "internal/shared/components/SearchBar";
import LoadingElement from "shared/components/LoadingElement";
import COLORS from "shared/constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  outline: 0;
  position: relative;
  z-index: 3;
`;

export const Error = styled.div`
  font-size: 0.75rem;
  margin: 1rem 0;
  text-align: center;
`;

export const LoadingContainer = styled.div`
  position: relative;
  height: 4rem;

  > span {
    height: 2rem;
    width: 2rem;
  }
`;

export const Loading = () => (
  <LoadingContainer>
    <LoadingElement />
  </LoadingContainer>
);

export const Results = styled.div`
  background: ${COLORS.DARK_GREY};
  border-radius: 0 0 1rem 1rem;
  border: 1px solid ${COLORS.WHITE};
  border-top: 0;
  max-height: 40rem;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 1rem 1rem 0;
  position: absolute;
  top: 1rem;
  white-space: pre-wrap;
  width: 100%;
`;

export const StyledSearchBar = styled(SearchBar)`
  > div {
    z-index: 3;
  }
`;
