import useFeature from "shared/hooks/useFeature";
import { IBetaWorkflowReleaseFlag } from "shared/types/flags";

function useBriefInBetaWorkflowRelease(briefID: string): boolean {
  const betaWorkflowReleaseFlag = useFeature<IBetaWorkflowReleaseFlag>(
    "betaWorkflowRelease",
  );

  return betaWorkflowReleaseFlag?.briefIDs?.includes(briefID) || false;
}

export default useBriefInBetaWorkflowRelease;
