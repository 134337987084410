import textualize from "internal/system/utils/textualize";
import { HTMLAttributes, ReactNode } from "react";
import { h4Styles } from "shared/styling/Heading";
import styled from "styled-components";

export interface IProps extends HTMLAttributes<HTMLDivElement> {
  count: number;
  moreAvailable: boolean;
  name: ReactNode | string;
}

export const Container = styled.section`
  margin: 1rem 0;
`;

export const Title = styled.h2`
  /* Visually show a h4 heading */
  ${h4Styles}
`;

export const Message = styled.div`
  font-size: 0.75rem;
`;

export const List = styled.ul`
  > * {
    margin: 0.5rem 0;
  }
`;

export const SearchTile = ({
  className,
  count,
  children,
  id,
  moreAvailable,
  name,
}: IProps) => (
  <Container className={className} id={id}>
    <Title>
      {name} ({count}
      {moreAvailable && "+"})
    </Title>
    {count === 0 && <Message>{textualize("megaSearch.noResults")}</Message>}
    {moreAvailable && (
      <Message>{textualize("megaSearch.tooManyResults", { count })}</Message>
    )}
    <List>{children}</List>
  </Container>
);

export default SearchTile;
