import { HTMLAttributes } from "react";
import styled from "styled-components";

export interface IProps extends HTMLAttributes<HTMLElement> {
  aspectRatio?: number;
  className?: string;
  imageUri?: string;
}

export const KeepRatio = styled.div<IProps>`
  background-clip: border-box;
  background-image: url(${(props) => props.imageUri});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  &::after {
    content: "";
    display: block;
    padding-bottom: ${(props) =>
      (1 / (props.aspectRatio ? props.aspectRatio : 1)) * 100}%;
  }

  & > div {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const FixedAspectRatio = (props: IProps) => (
  <KeepRatio {...props}>
    {props.children && <div>{props.children}</div>}
  </KeepRatio>
);

export default FixedAspectRatio;
