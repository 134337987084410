import API from "shared/constants/api";
import { default as DATADOG_DEFAULTS } from "shared/constants/datadog";
import getEnvironment from "shared/utils/getEnvironment";

export const CONFIG = {
  ...DATADOG_DEFAULTS.CONFIG,
  ALLOWED_TRACING_URLS: [
    {
      match: API.INTERNAL[getEnvironment()].replace("/query", ""),
      propagatorTypes: ["datadog" as const],
    },
  ],
  APPLICATION_ID: "20c3b56b-7ae0-4f04-b311-b1dfd0cebba5",
  CLIENT_TOKEN: "pubd973dc9c27729ded01d43c616860445e",
  SERVICE: "app.vidsy.co",
};

export const IGNORE_ERRORS = [...DATADOG_DEFAULTS.IGNORE_ERRORS];

export default {
  CONFIG,
  IGNORE_ERRORS,
};
