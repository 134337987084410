import {
  InfeedContainer,
  Scrollable,
} from "shared/components/MobilePreviewPartners/shared";
import COLORS from "shared/constants/colors";
import styled from "styled-components";
import { IProps } from "../types";
import {
  BottomBar,
  BrandTop,
  Brandside,
  Likes,
  NextCard,
  PollResults,
  PollTweet,
  TopBar,
  TopPhoneBar,
} from "./elements";

const StyledInfeedContainer = styled(InfeedContainer)`
  background: ${COLORS.BLACK};
`;

const StyledInfeedPollContainer = styled(InfeedContainer)`
  background: #16202c;
`;

const Tweet = styled.div`
  align-items: top;
  display: flex;
`;

const Side = styled.div`
  width: 19%;
`;

const StyledBottomBar = styled(BottomBar)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Content = styled.div`
  margin-left: 4.04%;
  margin-right: 4.62%;
  width: 81%;
`;

const Video = styled.div`
  border-radius: 3.7%;
  overflow: hidden;
  margin-top: 4.62%;
  max-width: 100%;
  width: 100%;
  video {
    display: block;
  }
`;

const PollVideo = styled(Video)`
  margin: -20% 3% 0 18%;
  width: unset;
  video {
    width: 375px;
  }
`;

const StyledDiv = styled.div<{ withCaption: boolean }>`
  margin-top: ${(props) => (props.withCaption ? undefined : "-20%")};
`;

export const TwitterInfeed = ({
  brand,
  caption,
  children,
  logo,
  videoId,
}: IProps) => (
  <StyledInfeedContainer>
    <Scrollable tabIndex={0}>
      <TopBar />
      <Tweet>
        <Side>
          <Brandside logo={logo} videoId={videoId} />
        </Side>
        <Content>
          <BrandTop brand={brand} caption={caption} />
          <StyledDiv withCaption={!!caption}>
            <Video>{children}</Video>
            <Likes brand={brand} />
          </StyledDiv>
        </Content>
      </Tweet>
      <NextCard />
    </Scrollable>
    <StyledBottomBar />
  </StyledInfeedContainer>
);

export const TwitterPoll = ({ brand, children, logo, videoId }: IProps) => (
  <StyledInfeedPollContainer>
    <Scrollable tabIndex={0}>
      <TopPhoneBar />
      <PollTweet brand={brand} logo={logo} videoId={videoId} />
      <PollVideo>{children}</PollVideo>
      <PollResults />
      <NextCard isPoll />
    </Scrollable>
    <StyledBottomBar />
  </StyledInfeedPollContainer>
);
